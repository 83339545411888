import React from 'react'
import PropTypes from 'prop-types'
import MatchHistoryEntry from './matchHistoryEntry'

const MatchHistoryTable = ({ matches }) => (

    <table className="table lobby-table table-hover match-history">
        <thead>
            <tr>
                <th>Place</th>
                <th>Score</th>
                <th className="desktop-items-table">Type</th>
                <th className="desktop-items-table">ID</th>
                <th>Date played</th>
                <th className="text-right">Actions</th>
            </tr>
        </thead>
        <tbody>

            {typeof matches === 'undefined' ? '' : (matches.map((match, index) => (
                <MatchHistoryEntry key={match.challengeId} {...match} index={index} />
            )))}
        </tbody>
    </table>
)

export default MatchHistoryTable