import React from 'react';
import { connect } from 'react-redux';
import { InfoBar } from './infoBar';
import { TopBar } from './topBar';
import { SideBar } from './sideBar';
import { MobileMenu } from './mobileMenu';
import { Popup } from './popup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ChallengeAnimations } from "./animations/challengeAnimations";

class Layout extends React.Component {
    render() {
        return (
            <div>
                <InfoBar />
                <TopBar />
                <SideBar />
                <MobileMenu/>
                
                <div className="page-content">
                    {this.props.children}
                </div>

                <Popup />
                <ToastContainer />              
                <ChallengeAnimations  />
            </div>
        );
    }

}

function mapStateToProps(state) {
  
    //To refresh layout so that sidebar and topbar is not drawn for login page when redirected to login
    let data = typeof state.router.location.state !== 'undefined' ? state.router.location.state.refresh : 'undefined'
    return {
        data
    };
}


const layout = connect(mapStateToProps)(Layout);
export { layout as Layout }; 