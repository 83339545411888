import css from './yourChallengeCTA.module.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ChallengeStatus, ParticipantStatus } from '../../../../utils/const';
import { formatChallengeStartAndEndTime } from '../../../../utils/translate';

export default function YourChallengeCTA() {
    const [open, toggleOpen] = useState(false);

    const extracurricular = useSelector(state => state.challengeReducer.specialChallenges);
    const isLoggedIn =  useSelector(state =>  state.userReducer.loggedIn);
    const user = useSelector(state =>  state.userReducer.userInfo);
    const lobby = getLobby(isLoggedIn,extracurricular);


    if(lobby===undefined){
        return null;
    }

    return (
        
      <div className={`${css.cta} ${open && css.open}`}>
        <button type="button" onClick={() => toggleOpen(!open)}>
          <span>Your Challenges</span>
          <img src={'/images/extracurricular/pictograms/angle-up.svg'} alt={''} width={16} height={16} />
        </button>
        <div className={css.content}>
          <div className={css.head}>
            EXTRACURRICULAR Challenge
            <br />
            {formatChallengeStartAndEndTime(lobby?.startTime,lobby?.endTime)}
          </div>
          <div className={css.body}>
            <b>{`${lobby.numberOfParticipants} ${lobby.numberOfParticipants === 1 ? 'player' : 'players'} registered`}</b>
            <br />
            <span>{scoreStatus(lobby,user)}</span>
          </div>
          <Link to={`/extracurricular/lobby/${lobby.id}`}>Go to lobby</Link>
        </div>
      </div>
    );
  }

  const getLobby = (isLoggedIn,extracurricular) => {
    if(!isLoggedIn || extracurricular===undefined || extracurricular.length===0){
      return undefined;
    } 
    return  extracurricular.find(challenge => challenge.isParticipating);
  
  }

  const scoreStatus = (activeChallenge, user) =>{
    var enterScore = "";
    if(activeChallenge.participants === undefined){
        return enterScore;
    }

    var participant = activeChallenge.participants.find(participant=> participant.userId === user.id);
    if (activeChallenge.status === ChallengeStatus.RUNNING){
        if(participant?.status === ParticipantStatus.DONE){
            enterScore = "Score entered";
        }
        else {
            enterScore = "No score entered";
        }
    } 
    else if(activeChallenge.status === ChallengeStatus.DONE){
        enterScore = "Ended";
    }
    else {
        enterScore = "Not started";
    }
    return enterScore;
  }