let user = JSON.parse(localStorage.getItem('user'));
let userInfo = JSON.parse(localStorage.getItem('userInfo'));
userInfo = userInfo ? userInfo : {};

const initialState = user ? {
    loggedIn: true,
    user,
    userInfo: userInfo,
    userInfoFresh: false,
    cookies: [],
    transactions: {
        result: []
    },
    matchesPlayed: [],
    fromProUrl: false,
} :
{
    loggedIn: false,
    userInfo: userInfo,
    userInfoFresh: false,
    cookies: [],
    transactions: {
        result: []
    },
    matchesPlayed: [],
    fromProUrl: false,
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case "IS_WORKING":
            return {
                ...state,
                working: true,
               
            }
        case "FINISHED_WORKING":
            return {
                ...state,
                working: false,

            }
        case "FROM_PRO_URL":
            return {
                ...state,
                fromProUrl: true,

            }
        case "USER_LOGGED_IN":
            return {
                working: false,
                loggedIn: true,
                user: action.user,
                type: 'alert-success',
                message: '',
                userInfo: {},
                userInfoFresh: false,
                transactions: {
                    result: []
                }
            }
        case "LOGIN_FAILED":
            return {
                working: false,
                loggedIn: false,
                user: {},
                type: 'alert-danger',
                message: action.payload,
                userInfo: {},
                userInfoFresh: false,
                transactions: {
                    result: []
                }
            }
        case "USER_INFO":
            return {
                ...state, 
                userInfo: action.userInfo,
                userInfoFresh: true,
            }
        case "PICTURE_INFO":
            return {
                ...state,
                userInfo: { ...state.userInfo, ...action.pictureInfo }

            }
        case "REGISTER_USER_STEP_TWO_SUCCESS":
            return {
                ...state,
                userInfo:
                {
                    ...state.userInfo,
                    ...action.userInfo,
                    hcp: action.userInfo.hcp === undefined ? undefined : action.userInfo.hcp
                }
            }
        case "2FA_VERIFICATION": 
            console.log("cookies=" + action.cookies)
            return {
                ...state,
                cookies: action.cookies
            }
        case "VERIFICATION_SUCCESS":
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    epicVerified: action.verificationReply.verified,
                    epicDisplayName: action.verificationReply.displayName,
                    epicAccountId: action.verificationReply.accountId,
                },
                cookies: []
            }
        case "EMAIL_VERIFICATION_SUCCESS":
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    usernameVerified: true,
                   
                },
                cookies: []
            }
        case "TRANSACTION_INFO": 
            return {
                ...state,
                transactions: action.transactions
            }
        case "MATCHES_PLAYED":
            return {
                ...state,
                matchesPlayed: action.matchesPlayed
            }
        case "LOGOUT":
            return {
                loggedIn: false,
                userInfo: {},
                userInfoFresh: false,
                cookies: [],
                transactions: {
                    result: []
                },
                matchesPlayed: []
            };
        case "NEW_AMOUNT":
            return {
                ...state,
                userInfo: { ...state.userInfo, amount: action.newAmount },
            }
        default:
            return state
    }
}