export const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength16 = maxLength(16)

const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minLength8 = minLength(8)
export const minLength10 = minLength(10)

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
        ? 'Not a valid e-mail address'
        : undefined;

export const phoneNumber = value =>
    value && !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/i.test(value)
        ? 'Not a valid phone number'
        : undefined;

export const alphaNumeric = value =>
    value && /[^A-Za-z0-9-åÅäÄöÖ ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined

export const passwordsMustMatch = (value, values) =>
    value !== values.password ?
        'Passwords do not match'
        : undefined

export const numbersOnly = (value) =>
    value && !/^-?\d+\.?\d*$/i.test(value)
        ? 'Not a valid zipcode'
        : undefined

export const isdecimal = (value) => {
    value = (value + '').replace(/,/g, '.');
    return !isNaN(parseFloat(value)) && isFinite(value) ? undefined : 'Not a valid number'
}

export const notNegative = (value) => {
    return (value + '').startsWith('-') ? 'Can not be a negative value' : undefined
}

export const positiveNumber = (value) => {
    value = (value + '').replace(/,/g, '.');
    return value && !/^-?\d+\.?\d*$/i.test(value)
        ? 'Not a valid number'
        : parseFloat(value)>0 ? undefined : 'Must be bigger than 0'
}

export const biggerDate = index => (value,values) => {
    console.log("values=" + JSON.stringify(values.challenges[index]));
    if (values.challenges[index] !== undefined && values.challenges[index].startTime !== undefined) {
        if (values.challenges[index].startTime >= value)
            return 'End date must be bigger'
    }
    return undefined;
}

export const validateSize = imageFile => {
    if (imageFile && imageFile.size) {
        const imageFileKb = imageFile.size / 1024;
        if (imageFileKb > 512) {
            return `Image should be < 512 kb`;
        }
    }
    return undefined;

};

export const validateType = imageFile => {
    console.log("Wha22t:");
    if (imageFile && imageFile.size) {
        console.log("Wha332t:");
        if (!(imageFile.type === 'image/jpeg' || imageFile.type === 'image/jpg'  || imageFile.type === 'image/png')) {
            console.log("What:" + imageFile.type);
            return `Image should be of type jpeg or png`;
        }
    }
    return undefined;
};