import React from 'react'
import PropTypes from 'prop-types'
import Transaction from './transaction'

const TransactionsTable = ({ transactions }) => (

    <table className="table lobby-table table-hover match-history">
        <thead>
            <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            {typeof transactions === 'undefined' ? 'Loading...' : (transactions.map((transaction, index) => (
                <Transaction key={transaction.id} {...transaction} index={index} />
            )))}
        </tbody>
    </table>
)

export default TransactionsTable