import React, { Component, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/layout'
import { LayoutBasic } from './components/layoutBasic'
import { PrivateRoute } from './utils/privateRoute'
import { LandingRoute } from './utils/landingRoute'
import { Home } from './components/home'
import Login from './components/login'
import Logout from './components/logout'
import ForgotPassword from './components/management/forgotPassword'
import ResetPassword from './components/management/resetPassword'
import Signup from './components/signup';
import { Lobby } from './components/lobby'
import { Leaderboard } from './components/leaderboard';
import { Wallet } from './components/wallet';
import { Deposit } from './components/payment/deposit';
import Withdraw from './components/payment/withdraw';
import RedeemCode from './components/payment/redeemCode';
import Settings from './components/settings';
import { Profile } from './components/profile';
import { MatchHistory } from './components/matchHistory';
import NotFound from './components/notFound';

import { VerifyDeposit } from './components/payment/deposit_return';
import { VerifyStripeDeposit } from './components/payment/stripe_deposit_return';
import { DepositWait } from './components/payment/deposit_wait';
import { DepositCancelled } from './components/payment/deposit_cancelled';
import { StripeDepositCancelled } from './components/payment/deposit_cancelled_stripe';
import { DepositDone } from './components/payment/deposit_done';

import Faq from './components/faq';
import { About } from './components/about';
import Terms from './components/terms';
import Privacy from './components/privacy';

import CreatePrivate from './components/private/createPrivate';
import EditPrivate from './components/private/editPrivate';

import { withRouter, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { config } from "./config/config";

import Landingpage from "./components/landingpage/landingpage";

import { TournamentLandingpage } from "./components/tournamentlandingpage/tournamentLandingpage";

import ExtracurricularLandingpage from './components/extracurricular/extracurricularLandingpage';
import  ExtracurricularLayout  from './components/extracurricular/layout';
import ExtracurricularLobby from './components/extracurricular/lobby';
import Rankings from './components/extracurricular/rankings';
import NotFoundPage from './components/extracurricular/404';

function App() {
    useEffect(() => {
        ReactGA.initialize(config.ga.TRACKING_ID);
        ReactGA.pageview(window.location.pathname)
    }, [window.location.pathname]);
    return (
        <Switch>
            <LandingRoute exact path='/' component={Landingpage} />
            <Route exact path='/wgc-tournament' component={TournamentLandingpage} />
            <Route exact path='/login' component={HeadlessContainer} />
            <Route exact path='/logout' component={HeadlessContainer} />
            <Route exact path='/signup' component={HeadlessContainer} />
            <Route exact path='/forgot-password' component={HeadlessContainer} />
            <Route exact path='/reset-password' component={HeadlessContainer} />            
            <PrivateRoute exact path='/awaiting_verification' component={HeadlessContainer} />                
            <Route exact path='/verifyemail' component={HeadlessContainer} /> 
            <Route path='/extracurricular' component={ExtracurricularContainer} />
            <Route component={MainContainer} />
        </Switch>
    );
}

const MainContainer = () => (
    <Layout>
        <Switch>
            <Route exact path='/clubhouse' component={Home} />
            <Route exact path='/pro' component={Home} />
            <Route exact path='/private' component={Home} />
            <Route path='/leaderboard' component={Leaderboard} />
            <PrivateRoute path='/lobby/:challengeId' component={Lobby} />            
            <PrivateRoute path='/wallet' component={Wallet} />
            <PrivateRoute path='/deposit' component={Deposit} />
            <PrivateRoute path='/deposit_return' component={VerifyDeposit} />
            <PrivateRoute path='/stripe_deposit_return' component={VerifyStripeDeposit} />
            <PrivateRoute path='/deposit_wait' component={DepositWait} />
            <PrivateRoute path='/deposit_cancelled' component={DepositCancelled} />
            <PrivateRoute path='/deposit_cancelled_stripe' component={StripeDepositCancelled} />
            <PrivateRoute path='/deposit_done' component={DepositDone} />
            <PrivateRoute path='/withdraw' component={Withdraw} />
            <PrivateRoute path='/redeem' component={RedeemCode} />
            <PrivateRoute path='/settings' component={Settings} />
            <PrivateRoute path='/profile' component={Profile} />
            <PrivateRoute path='/challenge/:challengeId' component={MatchHistory} />
            <PrivateRoute path='/create' component={CreatePrivate} />
            <PrivateRoute path='/edit/:challengeId' component={EditPrivate} />
            <Route path='/faq' component={Faq} />
            <Route path='/about' component={About} />
            <Route path='/terms-of-agreement' component={Terms} />
            <Route path='/privacy-policy' component={Privacy} />
            <Route component={NotFound} />
        </Switch>
    </Layout>
)

const HeadlessContainer = () => (
    <LayoutBasic>
        <Switch>
            <Route exact path='/wgc-tournament' component={TournamentLandingpage} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/logout' component={Logout} />
            <Route exact path='/signup' component={Signup} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/reset-password' component={ResetPassword} />
        </Switch>
    </LayoutBasic>
)

// Extracurricular Container
const ExtracurricularContainer = () => (
    <ExtracurricularLayout>
        <Switch>
            <Route exact path='/extracurricular' component={ExtracurricularLandingpage} />
            <PrivateRoute path='/extracurricular/lobby/:challengeId' component={ExtracurricularLobby} />     
            <Route path='/extracurricular/rankings' component={Rankings} />     
            <Route component={NotFoundPage} />
        </Switch>
    </ExtracurricularLayout>
);


export default withRouter(App);