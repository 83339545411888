import React from 'react'
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import './tournament.css';
import "../../components/fontawesome/css/all.css"
import "bootstrap"

var imgStyle = { marginLeft: '50px' }

var stellaStyle = { marginBottom: '80px' }

class TournamentLandingpage extends React.Component {

    render() {

        const { loggedIn, userInfo } = this.props;

        return (
            <div id="wgcTournament" className="landing-page-body">
                <nav className="landing navbar navbar-expand-lg justify-content-between sticky-top">
                    <div className="container">
                        <Link className="landing navbar-brand" to="/"><img src="./images/landingpage/logo.JPG" alt="WGC" /></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link className="landing nav-link" to="/clubhouse"><i className="fa-solid fa-house"></i> Clubhouse</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="landing nav-link" to="/leaderboard"><i className="fa-solid fa-chart-simple"></i> Leaderboard</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="landing nav-link" to="/about"><i className="fa-solid fa-circle-info"></i> About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="landing nav-link" to="/faq"><i className="fa-solid fa-message"></i> FAQ</Link>
                                </li>
                            </ul>
                            {!loggedIn ?
                                <div className="nav-btns">
                                    <Link to="/signup" className="btn btn-primary">Register</Link>
                                    <Link to="/login" className="btn btn-outline">Login</Link>
                                </div> : null
                            }
                        </div>
                    </div>
                </nav>

                <section id="intro-section" className="hero">
                    <div className="container">
                        <h1>World Golf Challenge</h1>
                        <h3 className="subtitle"><span></span>Tournament<span></span></h3>
                        <p className="on-image">Take part to the largest amateur tournament on World&nbsp;Golf&nbsp;Challenge happening
                            for the 1st time in your home club!</p>


                        <div className="btns-row">
                            <Link to="/clubhouse#wgc" className="btn btn-primary btn-m-100">To the challenges</Link>
                            <a href="#how-to" className="btn btn-secondary btn-m-100">How to play</a>
                        </div>
                    </div>
                </section>

                <section id="concept-section">
                    <div className="container">
                        <h3 className="tournament-header-h3">Play between October 9<sup>th</sup>, 15<sup>th</sup> to 23<sup>rd</sup></h3>

                        <div className="row mt-4">
                            <div className="col col-12 col-sm-6 col-md-4 col-xl-3">
                                <div className="concept-item">
                                    <div className="icon"><i className="fa-solid fa-golf-ball-tee"></i></div>
                                    <p><b>Net Stableford, 18 holes</b></p>
                                    <p>Pick any course in the world & adjust your strokes. Only Net Stableford counts.</p>
                                </div>
                            </div>

                            <div className="col col-12 col-sm-6 col-md-4 col-xl-3">
                                <div className="concept-item">
                                    <div className="icon"><i className="fa-solid fa-mars-and-venus"></i></div>
                                    <p><b>Only HCP matters</b></p>
                                    <p>Gender and age mixed into 3 different categories :<br/>Single / HCP 10 to 18 / HCP +18</p>
                                </div>
                            </div>

                            <div className="col col-12 col-sm-6 col-md-4 col-xl-3">
                                <div className="concept-item">
                                    <div className="icon"><i className="fa-solid fa-gift"></i></div>
                                    <p><b>A. lot. of. prizes.</b></p>
                                    <p>Great prizes to reward you - including PerfectDraft beer kits and WGC Vouchers!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="how-to" className="container">
                    <div className="section-top row">
                        <div className="col-xl-3 col-0">
                            {/*-- spacer */}
                        </div>
                        <div className="col">
                            <h2 className="tournament-header-h2">How to join, step by step</h2>
                            <p className="subtitle">Joining the Tournament requires a golf club and some work on your mobile or desktop.</p>
                        </div>
                        <div className="col-xl-3 col-0">
                            {/*-- spacer */}
                        </div>
                    </div>

                    <div className="section-bottom row">
                        <div className="col-xl-1 col-0">
                            {/*-- spacer */}
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-12"><span>1</span> At your golf club 🏡⛳</div>
                                <div className="col-lg-9 col">✏️ Register to WGC Tournament at your golf club or any 18-hole individual official competition, no EDS</div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-12"><span>2</span> On&nbsp;<a className="green-link" href="/">wgc.gg</a> 📲💻</div>
                                <div className="col-lg-9 col">📝 Create a free account online<br/><br/>✏️ Join for free the Challenge in your
                                    category of the date 📆 you are physically playing the WGC Tournament</div>
                                </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-12"><span>3</span> At your golf club 🏡⛳</div>
                                        <div className="col-lg-9 col">🏌 Have fun playing WGC Tournament 18 holes Net Stableford</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-12"><span>4</span> On&nbsp;<a className="green-link" href="/">wgc.gg</a> 📲💻</div>
                                        <div className="col-lg-9 col">✏️ Enter your score on 📲💻 <a className="green-link" href="/">wgc.gg</a> after your round, the same
                                            day you played before <time dateTime="2022-10-23 22:00">11pm CET</time>🕙</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 col-12"><span>5</span> On&nbsp;<a className="green-link" href="/">wgc.gg</a> 📲💻</div>
                                        <div className="col-lg-9 col">🏆 Check the Leaderboard section and wait until the WGC Tournament is finished
                                            (<time dateTime="2022-10-23 22:00">11pm / Oct 23rd</time>) to see your final position</div>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-0">
                                        {/*-- spacer */}
                                </div>
                            </div>
                </section>

                <section id="challenges" className={loggedIn ? "" : "user-not-logged"}>
                    <div className="section-top">
                        <h2 className="tournament-header-h2">Join the challenges</h2>
                        <p className="subtitle">Choose the category which fits your handicap and the date you are going to play. Join the
                            Challenge and compete against players from around the World.</p>
                    </div>

                    <div className="five-challenges-buttons">
                        <h3 className="tournament-header-h3"><i>You need to be logged in to access the challenges</i></h3>
                        <div className="nav-btns">
                            <Link to="/login" className="btn btn-primary">Login</Link>
                            <Link to="/signup" className="btn btn-secondary">Register</Link>
                        </div>
                    </div>

                    <div className="five-challenges section-bottom">
                        {/* Challenge Component */}
                        <div className="challenge-tournament">
                            <div className="top">
                                <div className="line">
                                    <h4></h4>
                                </div>

                                <div className="line">
                                    <small className="date-period">October 9<sup>th</sup>,15<sup>th</sup> to 23<sup>rd</sup></small>
                                </div>

                                <div className="line">
                                    <small className="game-type">Game type : <b>Net Strableford</b></small>
                                </div>
                            </div>
                            <div className="middle">Free</div>
                            <div className="bottom">
                                <div className="left-tournament">
                                    {/* <span className="price">€10</span> */}
                                    <span className="players"><i className="fa-solid fa-user"></i></span>
                                </div>
                                <div className="right-tournament">
                                    <Link to="/clubhouse#wgc" className="btn btn-primary">Join now</Link>
                                </div>
                            </div>
                        </div>
        
                        
         
                    </div>
                </section>

                <section id="sponsors">
                    <div className="container">
                        <h2 className="tournament-header-h2">Our generous sponsors</h2>
                        <div className="row">
                            <a href="https://www.stellaartois.com/" style={stellaStyle} className="col-lg-4 col-md-6 col-12 sponsor" target="_blank" >
                                <img src="./images/tournament/partners/STELLA.png" alt="Stella Artois" />
                            </a><br /><br /><br />
                            <a href="https://www.taylormadegolf.com/" className="col-lg-4 col-md-6 col-12 sponsor" target="_blank">
                                <img src="./images/tournament/sponsors/taylormade.png" alt="TaylorMade"/>
                            </a>
                          
                            <a href="https://www.teetravel.com/" className="col-lg-4 col-md-6 col-12 sponsor" target="_blank" >
                                <img src="./images/tournament/partners/TOT.png" alt="Tee Of Travel" />
                            </a>

                         
                        </div>
                     
                    </div>
                </section>

                <section id="partners">
                    <div className="container">
                        <h2 className="tournament-header-h2">Our partners</h2>
                        <div className="partners-list">
                            <a href="http://www.bonmont.com/" target="_blank">
                                <img src="./images/tournament/partners/bonmont.png" alt="Golf & Country Club de Bonmont"/>
                             </a>

                            <a href="https://www.golfdedivonne.com/fr/" target="_blank">
                                <img src="./images/tournament/partners/divonne.png" alt="Golf de Divonne"/>
                            </a>

                            <a href="https://www.golfdeneuchatel.ch/" target="_blank">
                                <img src="./images/tournament/partners/gccn.svg" alt="Golf & Country Club de Neuchâtel"/>
                            </a>

                            <a href="https://golflausanne.ch/" target="_blank">
                                <img src="./images/tournament/partners/lausanne.png" alt="Golf de Lausanne"/>
                            </a>

                            <a href="http://www.golfdenantesiledor.com/" target="_blank">
                                <img src="./images/tournament/partners/nantes.png" alt="Golf Ile d'Or - Nantes"/>
                            </a>

                            <a href="https://www.golfpayerne.ch/" target="_blank">
                                <img src="./images/tournament/partners/payerne.svg" alt="Golf de Payerne"/>
                            </a>

                            <a href="https://rgcst.be/" target="_blank">
                                <img src="./images/tournament/partners/rgcst.png" alt="Royal Golf Club du Sart Tilman"/>
                            </a>

                            <a href="https://www.rigenee.be/" target="_blank">
                                <img src="./images/tournament/partners/rigenee.jpeg" alt="Golf de Rigenée"/>
                            </a>

                            <a href="https://www.golf-aixlesbains.com/" target="_blank">
                                <img src="./images/tournament/partners/riviera-des-alpes.png" alt="Golf Club d'Aix-les-Bains"/>
                            </a>

                            <a href="https://www.royalbercuitgolfclub.be/" target="_blank">
                                <img src="./images/tournament/partners/royal-bercuit.png" alt="Royal Bercuit Golf Club"/>
                            </a>

                            <a href="https://www.golfsierre.ch/" target="_blank">
                                <img src="./images/tournament/partners/sierre.jpg" alt="Golf Club de Sierre"/>
                            </a>

                            <a href="https://www.verbiergolfclub.ch/" target="_blank">
                                <img src="./images/tournament/partners/verbier.png" alt="Verbier Golf Club"/>
                            </a>

                            <a href="https://golflesbois.ch/" target="_blank">
                                <img src="./images/tournament/partners/LesBois.png" alt="Golf Les Bois"/>
                            </a>

                            <a href="https://www.marcilly.com/" target="_blank">
                                <img src="./images/tournament/partners/marcilly.webp" alt="Golf de Marcilly"/>
                            </a>

                            <a href="https://www.golfmaisonblanche.fr/" target="_blank">
                                <img src="./images/tournament/partners/maison-blanche.webp" alt="Golf Maison Blanche"/>
                            </a>

                            <a href="http://pravets-golfclub.com" target="_blank">
                                <img src="./images/tournament/partners/pravet.svg" alt="Pravet Golf Club"/>
                            </a>
                            <a href="https://www.golf7fontaines.be/fr/accueil-visiteurs" target="_blank">
                                <img src="./images/tournament/partners/golf7.png" alt="Golf Club 7 Fontaines" />
                            </a>
                            <a href="https://www.golfclub-kitzbuehel.at" target="_blank">
                                <img src="./images/tournament/partners/golfclub-kitzbuehel.png" alt="Golf Club Kitzbuehel" />
                            </a>
                            <a href="https://www.golflavaux.ch" target="_blank">
                                <img src="./images/tournament/partners/GolfLavaux.jpg" alt="Golf Lavaux" />
                            </a>
                            <a href="https://www.riversidegolf.hr/en/" target="_blank" style={imgStyle}>
                                <img src="./images/tournament/partners/Riverside.png" alt="Riverside Golf" />
                            </a>
                          {/*  <a href=" https://kauppa.golfpiste.com/kotojarvigolf/" target="_blank">
                                <img src="./images/tournament/partners/kotojarvigolf_nega.png" alt="Riverside Golf" />
                            </a> */}
                           
                    </div>
                </div>

                </section>

                <section id="private-challenges-section">
                    <div className="two-one-thirds-section">
                        <div className="two-thirds">
                            <div className="container">
                                <h2 className="tournament-header-h2">There is even more!</h2>
                                <p>More Free and Buy-in Challenges available on wgc.gg & your own Challenges with friends, clients,
                                    club members to create!</p>
  
                                <Link to="/" className="btn btn-primary">
                                    Visite wgc.gg
                                </Link>
                            </div>
                        </div>
                        <div className="one-third"></div>
                    </div>
                </section>

                <footer className="landing">
                    <div className="container">
                        <div className="footer-top row">
                            <div className="col-12 col-lg-3">
                                <Link to="/" className="landing footer-logo">
                                    <img src="./images/landingpage/logo.svg" alt="World Golf Challenge" />
                                </Link>
                            </div>
                            <div className="col col-6 col-md-4 col-lg-3">
                                <ul>
                                    <li><Link to="/clubhouse" className="landing">Clubhouse</Link></li>
                                    <li><Link to="/leaderboard" className="landing">Leaderboard</Link></li>
                                </ul>
                            </div>
                            <div className="col col-6 col-md-4 col-lg-3">
                                <ul>
                                    <li><Link to="/about" className="landing">About</Link></li>
                                    <li><Link to="/faq" className="landing">FAQ</Link></li>
                                </ul>
                            </div>
                            <div className="col col-12 col-md-4 col-lg-3">
                                <ul>
                                    <li><Link to="/wallet" className="landing">Wallet</Link></li>
                                    <li><Link to="/settings" className="landing">Settings</Link></li>
                                    <li><Link to="/logout" className="landing color-danger">Sign out</Link></li>
                                </ul>
                            </div>
                        </div>

                        <hr></hr>

                        <img src="./images/landingpage/paypal.png" alt="Paypal" className="paypal-logo" />

                        <ul className="footer-socials">
                            <li>
                                <Link className="landing" title="Instagram" rel="nofollow" to={{ pathname: "https://www.instagram.com/worldgolfchallenge/" }} target="_blank">
                                    <i className="fa-brands fa-instagram"></i>
                                </Link>
                            </li>
                            <li>
                                <Link href="#" className="landing" title="Facebook" target="_blank" rel="nofollow" to={{ pathname: "https://www.facebook.com/worldgolfchallenge" }}>
                                    <i className="fa-brands fa-facebook-square"></i>
                                </Link>
                            </li>
                            <li>
                                <Link href="#" className="landing" title="LinkedIn" target="_blank" rel="nofollow" to={{ pathname: "https://www.linkedin.com/company/worldgolfchallenge/about/" }}>
                                    <i className="fa-brands fa-linkedin"></i>
                                </Link>
                            </li>
                            <li>
                                <Link href="#" className="landing" title="Mail" target="_blank" rel="nofollow" to={{ pathname: "mailto:hello@wgc.gg" }}>
                                    <i className="fa-solid fa-square-envelope"></i>
                                </Link>
                            </li>
                        </ul>

                        <ul className="footer-credits">
                            <li><Link to="/privacy-policy" className="landing">Privacy Policy</Link></li>
                            <li><Link to="/terms-of-agreement" className="landing">Terms of agreements</Link></li>
                            <li>&copy; World Golf Challenge 2022</li>
                        </ul>
                    </div>

                </footer>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //function

    };
}

function mapStateToProps(state) {
    const { loggedIn, userInfo } = state.userReducer;
    return {
        loggedIn,
        userInfo
    };
}

const tournamentLandingpage = connect(mapStateToProps, mapDispatchToProps)(TournamentLandingpage);
export { tournamentLandingpage as TournamentLandingpage };

