import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'query-string';
import { verifyStripeDeposit } from "../../actions/userActions";

class VerifyStripeDeposit extends Component {
    componentDidMount() {

        let url = this.props.location.search;
        let params = queryString.parse(url);
        let refId = params.refId;
        this.props.localVerifyDeposit(refId);
    }

    render() {
        return (
            <div>
                <br />
                <div className="container" >
                    <h2 className="panel-title">Please wait while we verify your deposit...</h2>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        localVerifyDeposit: (refId) => dispatch(verifyStripeDeposit(refId))
    };

}

const stripeDeposit = connect(mapStateToProps, mapDispatchToProps)(VerifyStripeDeposit);
export { stripeDeposit as VerifyStripeDeposit }