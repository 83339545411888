import React from 'react';
import { connect } from 'react-redux';
import SweetAlert2 from './sweetAlert';
import { closeAlert, goToHome} from '../actions/dialogActions'

const customStyles = {
    content: {
      
    }
};

class Popup extends React.Component {

    constructor(props) {
        super(props);

       /* this.state = {
            showModal: false
        };*/

        this.onClose = this.onClose.bind(this);
    }


    onClose() {
        console.log("Closed called" + this.props.alert.onClose);
        if (this.props.alert.onClose != null && this.props.alert.onClose === 'GoToHome')
            this.props.localGoToHome();
            // this.props.alert.onClose(); //Execute onClose action before closing
        if (this.props.alert.onClose != null && this.props.alert.onClose === 'Reload')
            window.location.reload(true);
        this.props.localCloseAlert();
    }


    componentDidMount() {

    }

    render() {
        const { onClose, onConfirm, ...restProps } = this.props.alert;
   
        return (

            <SweetAlert2 onClose={this.onClose} {...restProps} />

            /*<Modal 
                isOpen={showModal}
                contentLabel="Join Challenge"
                style={customStyles}>
                <button onClick={this.handleCloseModal}>Close Modal</button>
            </Modal>*/
        );
    }
}


function mapStateToProps(state) {
    const alert = state.dialogReducer;

    return {
        alert
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localCloseAlert: () => dispatch(closeAlert()),
        localGoToHome: () => dispatch(goToHome()),
    };
}


const popup = connect(mapStateToProps, mapDispatchToProps)(Popup);
export { popup as Popup }; 