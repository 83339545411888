import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { joinDialog, goToRegisterDialog } from "./challengeDialogs";
import { ChallengeType, ChallengeStatus, HcpType } from "../utils/const";
import { datesAreOnSameDay } from "../utils/datetime";
import { getNextPriceTable } from '../utils/price'
import { displayFee, pricesHtml, translateGameType, currencySymbol, displayCurrency } from '../utils/translate'
import PropTypes from 'prop-types'
import Moment from 'react-moment';
import 'moment-timezone';
import './private/private-challenge.css';

const Challenge = ({ index, id, type, name, gameType, hcpType, creatorName, description, status, holes, startTime, endTime, isLoggedIn, goto, signup, deleteChallenge, goToRegister, fee, extraPrizePot, pricePot, pricePotAfterCut, priceType, prices, potShare, numberOfParticipants, minParticipans, maxParticipans, isParticipating, hasInfluenser, influenserEpicDisplayName, color, title, disabled, sponsor, sponsorPictureUrl, bannerImageUrl, logoImageUrl, showLink, challengeUrl, privateChallenge }) => (

    !privateChallenge ? 
    <div id={name.startsWith("WGC Tournament") ? "wgc" : id} className={'challenge' + (disabled ? ' disabledChallenge' : '')} >
        {hasInfluenser ? showTwitch({ influenserEpicDisplayName }) : null}
        <div className="winnings-holder">
            <div className="challenge-info" style={sponsorPictureUrl !== undefined && sponsorPictureUrl !== null ? null : null}>   
            </div>
        </div>
        <div className={"challenge-name"}>
            
            <h3 className="title">
                <h2>{name.startsWith("!Weekend Championship") ? <span className="weekend-championship-challenge">{name}</span> : <span>{name}</span>}</h2> 
                <span className="date">{formatChallengeStartAndEndTime(startTime, endTime)}</span>
                <div className="challenge-type">{name.startsWith("!Weekend Championship") ? <span className="weekend-championship-challenge">Game type: <span className="bold-type">{translateGameType(gameType)}</span></span> : <span>Game type: <span className="bold-type">{translateGameType(gameType)}</span></span>}</div>
                <div className="challenge-type">{creatorName ? "Creator: " + creatorName : null}</div>
                {showLink ? ShowCopyLink(challengeUrl) : null}
                
            </h3>
            {showBgImage({ sponsorPictureUrl, bannerImageUrl })}
        </div>
        <div className="join-container" data-name={title} data-winnings={fee} data-cost="FREE">
                {status === ChallengeStatus.NOT_STARTED || status === ChallengeStatus.READY_TO_START || status === ChallengeStatus.RUNNING ?
                    (isLoggedIn ?
                    (isParticipating ? infoBannerJoin({ fee, goto, color, disabled }) : (type !== ChallengeType.FINAL ? priceBanner({ type: type, title: title, name: name, description: description, startTime, gameType: translateGameType(gameType), holes, fee, pricePot: pricePotAfterCut, prices: getNextPriceTable(type, status, hcpType, minParticipans, numberOfParticipants, fee, extraPrizePot, privateChallenge, potShare, priceType, prices), signup, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled }) : null))
                        :
                        type !== ChallengeType.FINAL ? <div className={'buyin-price ' + color}>
            {buyIn({ fee })}
        </div> : null)
                    :
                     (status === ChallengeStatus.DONE)
                            ? (viewButton({ id, fee }))
                            :
                            null
                }
            </div>
        <div className="challenge-bottom">
            {showPricePot({ type, pricePotAfterCut })}
            <div className="challenge-exp" style={{ marginLeft: "10px", marginRight:"10px" }}>
                <img src="/images/icons/user-icon.svg" alt="Joined users"/>
                {numberOfParticipants}{' '}
			</div>
            {status === ChallengeStatus.NOT_STARTED || status === ChallengeStatus.READY_TO_START || status === ChallengeStatus.RUNNING ?
                    (isLoggedIn ?
                    (isParticipating ? gotoButtonNew({ fee, goto, color, disabled }) : (type !== ChallengeType.FINAL ? joinButtonNoFee({ type: type, title: title, name: name, description: description, startTime, gameType: translateGameType(gameType), holes, fee, pricePot: pricePotAfterCut, prices: getNextPriceTable(type, status, hcpType, minParticipans, numberOfParticipants, fee, extraPrizePot, privateChallenge, potShare, priceType, prices), signup, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled }) : null))
                        :
                    type !== ChallengeType.FINAL ? (registerButtonNoFee({ type: type, title: title, name: name, description: description, startTime, gameType: translateGameType(gameType), holes, fee, pricePot: pricePotAfterCut, prices: getNextPriceTable(type, status, hcpType, minParticipans, numberOfParticipants, fee, extraPrizePot, privateChallenge, potShare, priceType, prices), goToRegister, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled })) : null)
                    :
                     (status === ChallengeStatus.DONE)
                            ? (viewButton({ id, fee }))
                            :
                            null
            }
            {showLink ? <Link className="join-button" style={{ textDecoration: 'none', marginLeft: '10px', minWidth: "22%", width: "22%" }} to={'/edit/' + id}>Edit</Link> : null}
            </div>
    </div >
    :

    <div className="challenge private-challenge">
      <div className="header">
        {logoImageUrl &&
            <div
                className="logo"
                style={logoImageUrl !== undefined ? { backgroundImage: `url(${logoImageUrl})` } : undefined} >
            </div>
        }
        {bannerImageUrl &&
            <div
                className="header-bg"
                style={bannerImageUrl !== undefined ? { backgroundImage: `url(${bannerImageUrl})` } : undefined} >
            </div>
        }
        {showLink ?
            <Link to={'/edit/' + id} className="btn btn-small btn-secondary btn-settings">
                <i className="fa-solid fa-gear"></i>
            </Link> : null}
      </div>
      <div className="top">
        <div className="line">
            <h4>{name}</h4>
        </div>

        <div className="line">
            <small className="date-period">{formatChallengeStartAndEndTime(startTime, endTime)}</small>
        </div>

        <div className="line">
            <small className="game-type">Game type : <b>{translateGameType(gameType)}</b></small>
        </div>

        <div className="line">
            <small className="game-type">{creatorName ? "Creator: " + creatorName : null}</small>
        </div>
        {showLink ?
            <div className="line challenge-url">
                <input
                    type="text"
                    disabled
                    value={challengeUrl}
                />
                <button className="btn btn-primary btn-small" onClick={() => { navigator.clipboard.writeText(challengeUrl) }}>
                    <i className="fa-regular fa-copy"></i>
                </button>
            </div> : null}
      </div>
            <div className="middle">{fee === 0 ? 'Free' : `Buy-in: ${displayCurrency(fee)}`}</div>
      <div className="bottom">
        <div className="left-1">
          <span className="price"> {displayCurrency(pricePotAfterCut)}</span>
          <span className="players"><i className="fa-solid fa-user"></i>{' '}{numberOfParticipants}</span>
        </div>
        <div className="right-1">
            {status === ChallengeStatus.NOT_STARTED || status === ChallengeStatus.READY_TO_START || status === ChallengeStatus.RUNNING ?
                (isLoggedIn ?
                            (isParticipating ? gotoButtonNew({ fee, goto, color, disabled, isPrivate: true }) : (type !== ChallengeType.FINAL ? joinButtonNoFee({ type: type, title: title, name: name, description: description, startTime, gameType: translateGameType(gameType), holes, fee, pricePot: pricePotAfterCut, prices: getNextPriceTable(type, status, hcpType, minParticipans, numberOfParticipants, fee, extraPrizePot, privateChallenge, potShare, priceType, prices), signup, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled, isPrivate: true }) : null))
                    :
                    type !== ChallengeType.FINAL ? (registerButtonNoFee({ type: type, title: title, name: name, description: description, startTime, gameType: translateGameType(gameType), holes, fee, pricePot: pricePotAfterCut, prices: getNextPriceTable(type, status, hcpType, minParticipans, numberOfParticipants, fee, extraPrizePot, privateChallenge, potShare, priceType, prices), goToRegister, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled })) : null)
                :
                (status === ChallengeStatus.DONE)
                    ? (viewButton({ id, fee }))
                    :
                    null
            }
        </div>
      </div>
    </div>

)
  
const showBgImage = ({ sponsorPictureUrl, bannerImageUrl }) => {
    if (sponsorPictureUrl !== undefined && sponsorPictureUrl !== null) {
        return <div><img src="" /></div>;
    }
    else if (bannerImageUrl !== undefined && bannerImageUrl !== null) {
        return <div className="dummy"><img style={{ maxWidth: '200px', marginTop: '-100px', width:'100%' }} src={bannerImageUrl} /></div>;
    } else {
        return null;
    }
}

const showPricePot = ({ type, pricePotAfterCut }) => {
    if (type === ChallengeType.FINAL || type === ChallengeType.SPONSORED) {
        return (
            <div className="challenge-winnings challenge-winnings-merch">
                Merchandise
            </div>
        )
    } else {
        return (
            <div className="challenge-winnings">
                {displayCurrency(pricePotAfterCut)}
            </div>
        )
    }
}

const formatChallengeStartAndEndTime = (startTime, endTime) => {
    if (endTime !== undefined) {
        if (datesAreOnSameDay(startTime, endTime)) {
            return <Moment locale="en" date={startTime} format="MMM. Do" />;
        }
        else {
            return <div><Moment locale="en" date={startTime} format="MMM. Do" /> to <Moment locale="en" date={endTime} format="MMM. Do" /></div>;
        }
    }
    else {
        return <Moment locale="en" date={startTime} format="MMM. Do" />; {/* dddd MMMM Do */ }
    }
}

const showTwitch = ({ influenserEpicDisplayName }) => {
    return (
            <div className="twitch-partner-lobby">
                <img src="/images/twitch-icon.svg" alt="Twitch Partner"/> {influenserEpicDisplayName}
	        </div>
    );
}

const privateCreator = ({creatorName}) => {
    return (
        <div className="challenge-type">Creator: {creatorName}</div>
    );
}

const buyIn = ({ fee }) => {
    return fee === 0 ? 'Free' : <div>Buy-in: {displayCurrency(fee)}</div>;
}

const ShowCopyLink = ( challengeUrl ) => {
    const [glow, setGlow] = useState(false);
    return (
      
    <div className="line challenge-url copyLink"><span>{challengeUrl}</span><button className={glow ? "copyLinkButton glow" : "copyLinkButton"} 
    onClick={() => {setGlow(true); navigator.clipboard.writeText(challengeUrl)}}
    onAnimationEnd={() => setGlow(false)}
        >Copy link</button>
            
            </div>
           
  
    )
}




const showSponsor = ({ sponsor, sponsorPictureUrl }) => {

    if (sponsor !== null && sponsor !== undefined) {

        return <img className="profile-picture" src={sponsorPictureUrl} alt="Profile" />;
    }
    else {
        return null;
    }
}


const priceBanner = ({ type, title, name, description, startTime, gameType, holes, fee, pricePot, prices, signup, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled }) => {
    var additionalText = "";
    var additionalText2 = "";
    if (type === ChallengeType.SPONSORED) {
        additionalText = description;
    }
    else {
        if (fee !== 0) {
            additionalText = "<small><strong>Starts with a minimum of " + minParticipans + " players</strong><br />Number of rewards is adjusted to the number of players.<br /></small>";

            if (name.startsWith("Weekend Championship")) {
                prices = [];
                additionalText = "<small>Enter all your scores, 2 best scores count for the final ranking.<br/>No gimmies, Maximum quadruple bogey.<br/><br/>Fees cumulate for the final prize pool.<br/>Check the Leaderboard section for your current position.</small>";
            }


            if (hcpType !== HcpType.PRO && !privateChallenge) {
                additionalText2 = "<small>Rewards are divided between tied winners.<br />Winners can be asked a proof of result by email.</small>";
            } else {
                additionalText2 = "<small>Rewards are divided between tied winners.</small>";
            }
        }
        else {

            if (name.startsWith("HS Championship")) {
                prices = [];
                additionalText = "<small>Register to the Challenge every time you go for a round of golf.<br/>After your round, enter your score.<br/>3 best scores count for the leaderboard.</small>";
            }
            else {
                additionalText = "<small>Ranking:</small>";
            }
        }
    }
    return <div onClick={() => joinDialog({ challengeName: title, challengeNumber: name, startTime, gameType, holes, additionalText: additionalText, additionalText2: additionalText2, fee: displayFee(fee), pricePot, prices: pricesHtml({ prices }), signup })} disabled={disabled || (numberOfParticipants >= maxParticipans)}>
        <div className={'buyin-price ' + color}>
            {buyIn({ fee })}
        </div>
    </div>;
}

const joinButtonNoFee = ({ type, title, name, description, startTime, gameType, holes, fee, pricePot, prices, signup, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled, isPrivate }) => {
    var additionalText = "";
    var additionalText2 = "";
    if (type === ChallengeType.SPONSORED) {
        additionalText = description;
    }
    else {
        if (fee !== 0) {
            additionalText = "<small><strong>Starts with a minimum of " + minParticipans + " players</strong><br />Number of rewards is adjusted to the number of players.<br /></small>";

            if (name.startsWith("Weekend Championship")) {
                prices = [];
                additionalText = "<small>Enter all your scores, 2 best scores count for the final ranking.<br/>No gimmies, Maximum quadruple bogey.<br/><br/>Fees cumulate for the final prize pool.<br/>Check the Leaderboard section for your current position.</small>";
            }


            if (hcpType !== HcpType.PRO && !privateChallenge) {
                additionalText2 = "<small>Rewards are divided between tied winners.<br />Winners can be asked a proof of result by email.</small>";
            } else {
                additionalText2 = "<small>Rewards are divided between tied winners.</small>";
            }
        }
        else {

            if (name.startsWith("HS Championship")) {
                prices = [];
                additionalText = "<small>Register to the Challenge every time you go for a round of golf.<br/>After your round, enter your score.<br/>3 best scores count for the leaderboard.</small>";
            }
            else {
                additionalText = "<small>Ranking:</small>";
            }
        }
    }
    return isPrivate ?
        <a className="btn btn-primary" onClick={() => joinDialog({ challengeName: title, challengeNumber: name, startTime, gameType, holes, additionalText: additionalText, additionalText2: additionalText2, fee: displayFee(fee), pricePot, prices: pricesHtml({ prices }), signup })} disabled={disabled || (numberOfParticipants >= maxParticipans)} >
            {disabled ? 'Coming Soon' : (numberOfParticipants >= maxParticipans ? 'FULL' : 'Join Now')}
        </a >
            :
     <button type = "button" name = "button" className = "join-button" onClick = {() => joinDialog({ challengeName: title, challengeNumber: name, startTime, gameType, holes, additionalText: additionalText, additionalText2: additionalText2, fee: displayFee(fee), pricePot, prices: pricesHtml({ prices }), signup })} disabled = { disabled || (numberOfParticipants >= maxParticipans)}>
        {disabled ? 'Coming Soon' : (numberOfParticipants >= maxParticipans ? 'FULL' : 'Join Now')}
    </button>;
}


const registerButton = ({ type, title, name, description, startTime, gameType, holes, fee, pricePot, prices, goToRegister, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled }) => {
    var additionalText = "";
    var additionalText2 = "";
    if (type === ChallengeType.SPONSORED) {
        additionalText = description;
    }
    else {
        if (fee !== 0) {
            additionalText = "<small><strong>Starts with a minimum of " + minParticipans + " players</strong><br />Number of rewards is adjusted to the number of players.<br /></small>";

            if (name.startsWith("Weekend Championship")) {
                prices = [];
                additionalText = "<small>Enter all your scores, 2 best scores count for the final ranking.<br/>No gimmies, Maximum quadruple bogey.<br/><br/>Fees cumulate for the final prize pool.<br/>Check the Leaderboard section for your current position.</small>";
            }
            if (hcpType !== HcpType.PRO && !privateChallenge) {
                additionalText2 = "<small>Rewards are divided between tied winners.<br />Winners can be asked a proof of result by email.</small>";
            } else {
                additionalText2 = "<small>Rewards are divided between tied winners.</small>";
            }
        } else {
            if (name.startsWith("HS Championship")) {
                prices = [];
                additionalText = "<small>Register to the Challenge every time you go for a round of golf.<br/>After your round, enter your score.<br/>3 best scores count for the leaderboard.</small>";
            }
            else {
                additionalText = "<small>Ranking:</small>";
            }
        }
    }

    return <button type="button" name="button" onClick={() => goToRegisterDialog({ challengeName: title, challengeNumber: name, startTime, gameType, holes, additionalText: additionalText, additionalText2: additionalText2, fee: displayFee(fee), pricePot, prices: pricesHtml({ prices }), goToRegister })} disabled={ disabled || (numberOfParticipants >= maxParticipans)}>
        <div className={'buyin-price ' + color}>
            {buyIn({ fee })}
        </div>
        {disabled ? 'Coming Soon' : (numberOfParticipants >= maxParticipans ? 'FULL' : 'Join Now')}
    </button>;
}

const registerButtonNoFee = ({ type, title, name, description, startTime, gameType, holes, fee, pricePot, prices, goToRegister, numberOfParticipants, maxParticipans, minParticipans, hcpType, privateChallenge, color, disabled }) => {
    var additionalText = "";
    var additionalText2 = "";
    if (type === ChallengeType.SPONSORED) {
        additionalText = description;
    }
    else {
        if (fee !== 0) {
            additionalText = "<small><strong>Starts with a minimum of " + minParticipans + " players</strong><br />Number of rewards is adjusted to the number of players.<br /></small>";

            if (name.startsWith("Weekend Championship")) {
                prices = [];
                additionalText = "<small>Enter all your scores (if par 71, ad 1 shot to your score),<br/>2 best scores count for final ranking.<br/>Gimme: one putter grip length allowed.<br/>Maximum quadruple bogey.<br/><br/>Fees cumulate for the final prize pool.<br/>Check the Leaderboard for your position.</small>";
            }
            if (hcpType !== HcpType.PRO && !privateChallenge) {
                additionalText2 = "<small>Rewards are divided between tied winners.<br />Winners can be asked a proof of result by email.</small>";
            } else {
                additionalText2 = "<small>Rewards are divided between tied winners.</small>";
            }
        } else {
            if (name.startsWith("HS Championship")) {
                prices = [];
                additionalText = "<small>Register to the Challenge every time you go for a round of golf.<br/>After your round, enter your score.<br/>3 best scores count for the leaderboard.</small>";
            }
            else {
                additionalText = "<small>Ranking:</small>";
            }
        }
    }

    return <button type="button" name="button" className="join-button" onClick={() => goToRegisterDialog({ challengeName: title, challengeNumber: name, startTime, gameType, holes, additionalText: additionalText, additionalText2: additionalText2, fee: displayFee(fee), pricePot, prices: pricesHtml({ prices }), goToRegister })} disabled={ disabled || (numberOfParticipants >= maxParticipans)}>
        {disabled ? 'Coming Soon' : (numberOfParticipants >= maxParticipans ? 'FULL' : 'Join Now')}
    </button>;
}

const infoBannerJoin = ({ fee, goto, color, disabled }) => {
    return (
        <div className={'buyin-price ' + color}>
            {buyIn({ fee })}
        </div>
        )
}

const gotoButtonNew = ({ fee, goto, color, disabled, isPrivate }) => {
    return isPrivate ? 
        <a className="btn btn-primary" onClick={goto} disabled={disabled}>{disabled ? 'Coming Soon' : 'Go To'}</a>
        :
    <button type="button" name="button" className="join-button" onClick={goto} disabled={disabled} >
        {disabled ? 'Coming Soon' : 'Go To'}
    </button>  
        ;
}

const runningButton = ({ fee }) => {
    return <button type="button" name="button" disabled={true} >
        <div className={'buyin-price '}>
            {buyIn({ fee })}
        </div>
        Running
     </button>;
}

const viewButton = ({ id, fee }) => {
    return <Link to={'/challenge/' + id}>
        <button type="button" name="button" disabled={false} >
        <div className={'buyin-price red'}>
            {buyIn({ fee })}
        </div>
        View
             </button>
     </Link>;
}


Challenge.propTypes = {
    signup: PropTypes.func.isRequired,
    goto: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
}

export default Challenge


//const challenge = connect()(Challenge);
//export { challenge as Challenge };
