import { PlayerStatus } from '../utils/const'
export const showPro = (user) => {

    return user.playerStatus === PlayerStatus.PRO ? true : false;
}

export const showSingle = (user) => {

    return user.playerStatus === PlayerStatus.AMATURE && user.hcp < 10 ? true : false;
}

export const showUpTo18 = (user) => {

    return user.playerStatus === PlayerStatus.AMATURE && user.hcp <= 18 ? true : false;
}

export const showUpTo54 = (user) => {

    return user.playerStatus === PlayerStatus.AMATURE && user.hcp <= 54 ? true : false;
}


export const showHcp18 = (user) => {

    return user.playerStatus === PlayerStatus.AMATURE && user.hcp >= 10 && user.hcp <= 18 ? true : false;
}

export const showHcp54 = (user) => {

    return user.playerStatus === PlayerStatus.AMATURE && user.hcp > 18 && user.hcp <= 54 ? true : false;
}