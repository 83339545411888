import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { createPrivateChallenge } from "../../actions/challengeActions";
import { required, positiveNumber, validateSize, validateType } from "../validations";
import { renderFieldWithShake, renderTextareaField, renderStatusCreateSelectField, renderGameTypeSelectField, renderTypeSelectField, renderCountrySelectField, renderStatusSelectField, renderDatePicker, renderDates, renderFileInput } from '../../utils/renderFields';
import { currencySymbol, currencyText } from '../../utils/translate';

var panelStyle = { padding: '20px' }

class CreatePrivate extends React.Component {

    handleFormSubmit(values) {
        console.log("handeling create private")
        console.log(values);

        if (this.props.valid) {
            values.fee = values.fee !== undefined ? (values.fee + '').replace(/,/g, '.') : undefined;
            this.props.createChallenge(values);
        }
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, valid, working, challengeTypeValue} = this.props;

        return (<div>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-7">
                        <div className="panel " style={panelStyle}>
                            <form id="login-form" className="createChallenge-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="name"
                                            type="text"
                                            component={renderFieldWithShake}
                                            label="Name"
                                            placeholder="Choose a name for your challenge"
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="description"
                                            type="text"
                                            component={renderTextareaField}
                                            label="Description (optional)"
                                            placeholder="Enter a description for your challenge"
                                            validate={[]}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                        label="Banner (900x600)"
                                        name="bannerImage"
                                        type="file"
                                        validate={[validateSize]}
                                        component={renderFileInput}
                                        />

                                    </div>
                                </div>                               
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            label="Logo (900x600)"
                                            name="logoImage"
                                            type="file"
                                            validate={[validateSize]}
                                            component={renderFileInput}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            label="Leaderboard prize (900x450)"
                                            name="leaderbordPrizeImage"
                                            type="file"
                                            validate={[validateSize]}
                                            component={renderFileInput}
                                        />
                                    </div>
                                </div>
                                {/*
                                <div className="row">
                                    <div className="col-md-6">
                                        <Field
                                            name="startTime"
                                            type="text"
                                            component={renderDatePicker}
                                            label="Start date"
                                            placeholder="Select a start date"
                                            validate={[required]}
                                        />
                                    </div>
                                
                                
                                    <div className="col-md-6">
                                        <Field
                                            name="endTime"
                                            type="text"
                                            component={renderDatePicker}
                                            label="End date"
                                            placeholder="Select a end date"
                                            validate={[required]}
                                        />
                                    </div>
                                </div>

                               */}
                               <FieldArray name="challenges" component={renderDates} />

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="bestOf"
                                            type="text"
                                            component={renderFieldWithShake}
                                            label="Challenges counted for leaderboard"
                                            placeholder=""
                                            validate={[required, positiveNumber]}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="hcpType"
                                            type="text"
                                            component={renderStatusCreateSelectField}
                                            label="Status"
                                            placeholder=""
                                            validate={[required]}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="gameType"
                                            type="text"
                                            component={renderGameTypeSelectField}
                                            label="Game type"
                                            placeholder=""
                                            validate={[required]}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="type"
                                            type="text"
                                            component={renderTypeSelectField}
                                            label="Fee type"
                                            placeholder=""
                                            validate={[required]}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        {challengeTypeValue !== undefined && challengeTypeValue === 'PAYED' ?
                                            <Field
                                                name="fee"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label={`Cost to join ( ${currencyText()})`}
                                                placeholder={`Choose amount (in ${currencySymbol()})`}
                                                validate={[required, positiveNumber]}
                                            /> : null}
                                    </div>
                                </div>

                                <button type="submit" id="submit-button" name="button" className={'submit' + (!valid || pristine || submitting || working ? ' disabledBtn' : '')} disabled={!valid || pristine || submitting || working}>
                                    <span>{working ? 'Working...' : 'Create'}</span>
                                </button>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

function mapStateToProps(state) {

    const initialValues = {
        hcpType: 'ANY', gameType: 'STABLEFORD_NET', type: 'PAYED', challenges: [{editable:true}] , bestOf: 1};
    const selector = formValueSelector('CreatePrivateForm');
    const challengeTypeValue = selector(state, 'type');
    const { working } = state.userReducer;
    return {
        initialValues, challengeTypeValue, working
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createChallenge:(values) => dispatch(createPrivateChallenge(values)),
    };
}


CreatePrivate = reduxForm({
    form: 'CreatePrivateForm', enableReinitialize: true
})(CreatePrivate);

CreatePrivate = connect(mapStateToProps, mapDispatchToProps)(CreatePrivate);

export default CreatePrivate;