import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'query-string';
import { cancelPayPalDeposit } from "../../actions/userActions";

class DepositCancelled extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

        let url = this.props.location.search;
        let params = queryString.parse(url);
        console.log(params);
        let orderId = params.token;
        this.props.localCancelDeposit(orderId);
    }

    render() {

        return (
            <div>
                <br />
                <div className="container" >
                    <h2 className="panel-title">Please wait while we cancel your deposit...</h2>

                </div>
            </div>
        );
    }


}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        localCancelDeposit: (orderId) => dispatch(cancelPayPalDeposit(orderId))
    };

}

const depositCancelled = connect(mapStateToProps, mapDispatchToProps)(DepositCancelled);
export { depositCancelled as DepositCancelled }