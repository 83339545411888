import React from 'react';
import { connect } from 'react-redux';
import { updateUser } from "../actions/userActions";
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { required, alphaNumeric, numbersOnly, isdecimal, notNegative } from "./validations";
import { renderFieldWithShake, renderCountrySelectField, renderStatusSelectField, renderPhoneField, renderRegionSelectField} from '../utils/renderFields';
import { Region } from "../utils/const";

//import './additional.css';

var panelStyle = { padding: '20px' }


class Settings extends React.Component {

    componentDidMount() {
        // calling the new action creator

    }

    handleFormSubmit(values) {
        console.log("handeling settings")
        console.log(values);
        if (this.props.valid) {

            var hcp = values.hcp !== undefined ? (values.hcp + '').replace(/,/g, '.') : undefined; 

            this.props.localUpdateUser(
                values.firstName, values.lastName, values.address1, values.address2,
                values.zip, values.city, values.state, values.countryIso, values.phoneNumber, values.playerStatus, hcp, values.nationalGolfId, values.region  //values.region Region.EUROPE
            );
        }
    }

    render() {
        const { userInfo, handleSubmit, pristine, reset, submitting, valid, working, playerStatusValue } = this.props;

        return (
            <div>
                {/* <br/> */}
                <div className="container-fluid">
                    {/* <h2 className="panel-title">Settings</h2> */}
                    <div className="row">
                        <div className="col-md-7">
                            <div className="panel settings-form" style={panelStyle}>
                                <form id="login-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field
                                                name="firstName"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="First name"
                                                placeholder="Enter your first name"
                                                validate={[required]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <Field
                                                name="lastName"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="Last name"
                                                placeholder="Enter your last name"
                                                validate={[required]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field
                                                name="address1"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="Address 1"
                                                placeholder="Enter your address of residence"
                                                validate={[]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <Field
                                                name="address2"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="Address 2"
                                                placeholder="Enter your address of residence"
                                                validate={[]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field
                                                name="zip"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="Postal/zip code"
                                                placeholder="Confirm your postal/zip code"
                                                validate={[numbersOnly]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <Field
                                                name="city"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="City"
                                                placeholder="Enter your city of residence"
                                                validate={[]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field
                                                name="state"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="State"
                                                placeholder="Enter your state of residence"
                                                validate={[]}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <Field
                                                name="countryIso"
                                                component={renderCountrySelectField}
                                                label="Country"
                                                placeholder="Enter your country of residence"
                                                validate={[required]}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field
                                                name="phoneNumber"
                                                component={renderPhoneField}
                                                label="Phone"
                                                placeholder="Enter your phone number"
                                                validate={[required]}
                                            />
                                        </div>
                                       
                                        <div className="col-md-6">

                                            <Field
                                                name="region"
                                                label="Display Challenges from region"
                                                type="text"
                                                component={renderRegionSelectField}
                                                placeholder="Select challenge region"
                                                validate={[required]} />
                                        </div>
                                      
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">

                                            <Field
                                                name="playerStatus"
                                                label="Status"
                                                type="text"
                                                component={renderStatusSelectField}
                                                placeholder="Select status"
                                                validate={[required]} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {playerStatusValue !== undefined && playerStatusValue === 'AMATURE' ?

                                                <Field
                                                    name="hcp"
                                                    type="text"
                                                    component={renderFieldWithShake}
                                                    label="Handicap"
                                                    placeholder="Enter your handicap"
                                                    format={(val) => val !== undefined ? (val+'').replace('-','+') : ''}
                                                    validate={[required, isdecimal]}
                                                />

                                                : null

                                            }
                                        </div>
                                        <div className="col-md-6">
                                            {playerStatusValue !== undefined && playerStatusValue === 'AMATURE' ?
                                                <Field
                                                    name="nationalGolfId"
                                                    type="text"
                                                    component={renderFieldWithShake}
                                                    label="National  Golf Id"
                                                    placeholder="Enter your golf id"
                                                    validate={[]}
                                                />
                                                : null

                                            }
                                        </div>
                                    </div>

                                    <button type="submit" className={'button success' + (!valid || pristine || submitting ? ' disabledBtn' : '')} name="button" disabled={!valid || pristine || submitting}>
                                        {working ? 'Working...' : 'Save'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const selector = formValueSelector('SettingsForm');
    const playerStatusValue = selector(state, 'playerStatus');


    const { working, type, message } = state.userReducer;
    const initialValues  = state.userReducer.userInfo;

    return {
        initialValues, working, type, message, playerStatusValue
    };
}

function mapDispatchToProps(dispatch) {
    return {
        localUpdateUser: (firstName, lastName, address1, address2, zip, city, state, countryIso, phoneNumber, playerStatus, hcp, nationalGolfId, region) => dispatch(updateUser(firstName, lastName, address1, address2, zip, city, state, countryIso, phoneNumber, playerStatus, hcp, nationalGolfId, region)),
    };
}


Settings = reduxForm({
    form: 'SettingsForm', enableReinitialize : true
})(Settings);

Settings = connect(mapStateToProps, mapDispatchToProps)(Settings);

export default Settings; 
