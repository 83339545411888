import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { editPrivateChallenge, getPrivateChallenge, deletePrivateChallenge } from "../../actions/challengeActions";
import { required, positiveNumber, validateSize } from "../validations";
import { renderFieldWithShake, renderTextareaField, renderStatusCreateSelectField, renderGameTypeSelectField, renderTypeSelectField, renderFileInput, renderStatusSelectField, renderDatePicker, renderDates } from '../../utils/renderFields';
import { currencySymbol, currencyText } from '../../utils/translate';
import { deleteDialog } from '../challengeDialogs'

var panelStyle = { padding: '20px' }

class EditPrivate extends React.Component {


    componentDidMount() {

        if (this.props.match.params.challengeId) {
            console.log("ChallengeId:" + this.props.match.params.challengeId);
            this.props.localGetPrivateChallenge(this.props.match.params.challengeId);
        }

    }


    handleFormSubmit(values) {

        return new Promise((resolve, reject) => {

            console.log("handling edit private for id=" + this.props.match.params.challengeId)
            console.log(values);
            if (this.props.valid) {
                values.fee = values.fee !== undefined ? (values.fee + '').replace(/,/g, '.') : undefined;
                this.props.editChallenge(this.props.match.params.challengeId, values);
            }
            resolve('');
           
        });

    }

    render() {
        const { handleSubmit, pristine, reset, submitting, valid, working, challengeTypeValue, disableField, bannerImageUrl, logoImageUrl, leaderbordPrizeImageUrl } = this.props;

        return (<div>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-7">
                        <div className="panel " style={panelStyle}>
                            <div style={{ textAlign: 'right' } }>
                                <button type="button" className="join-button challengeDelete" style={{ textDecoration: 'none', minWidth: "22%", width: "22%" }} onClick={() => deleteDialog({ deleteAction: ()=>this.props.deleteChallenge(this.props.match.params.challengeId) })}>Delete</button>
                            </div>
                            <form id="login-form" className="createChallenge-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="name"
                                            type="text"
                                            component={renderFieldWithShake}
                                            label="Name"
                                            placeholder="Choose a name for your challenge"
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="description"
                                            type="text"
                                            component={renderTextareaField}
                                            label="Description (optional)"
                                            placeholder="Enter a description for your challenge"
                                            validate={[]}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            label="Banner (900x600)"
                                            name="bannerImage"
                                            type="file"
                                            validate={[validateSize]}
                                            component={renderFileInput}
                                        />
                                        <label>Current banner</label>
                                        {bannerImageUrl !== undefined ? <img alt="Challenge box" style={{ width: "100%", marginBottom:"20px" }} src={bannerImageUrl} /> : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            label="Logo (900x600)"
                                            name="logoImage"
                                            type="file"
                                            validate={[validateSize]}
                                            component={renderFileInput}
                                        />
                                        <label>Current logo</label>
                                        {logoImageUrl !== undefined ? <img alt="Challenge box" style={{ width: "100%", marginBottom: "20px" }} src={logoImageUrl} /> : null}

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            label="Leaderboard prize (900x450)"
                                            name="leaderbordPrizeImage"
                                            type="file"
                                            validate={[validateSize]}
                                            component={renderFileInput}
                                        />
                                        <label>Current Leaderboard Prize</label>
                                        {leaderbordPrizeImageUrl !== undefined ? <img alt="Challenge box" style={{ width: "100%", marginBottom: "20px" }} src={leaderbordPrizeImageUrl} /> : null}

                                    </div>
                                </div>
                                {/*
                                <div className="row">
                                    <div className="col-md-6">
                                        <Field
                                            name="startTime"
                                            type="text"
                                            component={renderDatePicker}
                                            label="Start date"
                                            placeholder="Select a start date"
                                            validate={[required]}
                                        />
                                    </div>


                                    <div className="col-md-6">
                                        <Field
                                            name="endTime"
                                            type="text"
                                            component={renderDatePicker}
                                            label="End date"
                                            placeholder="Select a end date"
                                            validate={[required]}
                                        />
                                    </div>
                                </div> */}

                                <FieldArray name="challenges" component={renderDates} />

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="bestOf"
                                            type="text"
                                            component={renderFieldWithShake}
                                            label="Challenges counted for leaderboard"
                                            placeholder=""
                                            validate={[required, positiveNumber]}
                                            disabled={disableField}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="hcpType"
                                            type="text"
                                            component={renderStatusCreateSelectField}
                                            label="Status"
                                            placeholder=""
                                            validate={[required]}
                                            disabled={disableField}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="gameType"
                                            type="text"
                                            component={renderGameTypeSelectField}
                                            label="Game type"
                                            placeholder=""
                                            validate={[required]}
                                            disabled={disableField}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            name="type"
                                            type="text"
                                            component={renderTypeSelectField}
                                            label="Fee type"
                                            placeholder=""
                                            validate={[required]}
                                            disabled={disableField}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        {challengeTypeValue !== undefined && challengeTypeValue === 'PAYED' ?
                                            <Field
                                                name="fee"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label={`Cost to join ( ${currencyText()})`}
                                                placeholder={`Choose amount (in ${currencySymbol()})`}
                                                validate={[required, positiveNumber]}
                                                disabled={disableField}
                                            /> : null}
                                    </div>
                                </div>

                                <button type="submit" id="submit-button" name="button" className={'submit' + (!valid || pristine || submitting || working ? ' disabledBtn' : '')} disabled={!valid || pristine || submitting || working}>
                                    <span>{working ? 'Working...' : 'Edit'}</span>
                                </button>
                              
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

function mapStateToProps(state, props) {

    const challengeId = parseInt(props.match.params.challengeId, 10)
    console.log("Edit challenge with id=" + challengeId);

    const initialValues= state.challengeReducer.yourChallenges.find(x => x.id === challengeId);

    const selector = formValueSelector('EditPrivateForm');
    const challengeTypeValue = selector(state, 'type');

    const disableField = selector(state, 'anyParticipants'); //If we have participants some field can not be modified

    const bannerImageUrl = selector(state, 'bannerImageUrl');
    const lobbyHeaderImageUrl = selector(state, 'lobbyHeaderImageUrl');
    const logoImageUrl = selector(state, 'logoImageUrl');
    const leaderbordPrizeImageUrl = selector(state, 'leaderbordPrizeImageUrl');

    const { working } = state.userReducer;

    return {
        initialValues, challengeTypeValue, disableField, bannerImageUrl, lobbyHeaderImageUrl, logoImageUrl, leaderbordPrizeImageUrl, working
    };
}

function mapDispatchToProps(dispatch) {
    return {
        editChallenge: (id, values) => dispatch(editPrivateChallenge(id, values)),
        deleteChallenge: (id) => dispatch(deletePrivateChallenge(id)),
        localGetPrivateChallenge: (challengeId) => dispatch(getPrivateChallenge(challengeId)),
    };
}


EditPrivate = reduxForm({
    form: 'EditPrivateForm', enableReinitialize: true
})(EditPrivate);

EditPrivate = connect(mapStateToProps, mapDispatchToProps)(EditPrivate);

export default EditPrivate;