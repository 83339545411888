import React, { useState } from 'react';
import { connect,useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import { translateChallengeType } from '../utils/translate';
import { getCurrentActiveChallenge } from "../selectors/activeChallengeSelector";
import { ChallengeType } from '../utils/const';
import Moment from 'react-moment';
import 'moment-timezone';

export default function ActiveChallenges({}) {

    const [isActive, setIsActive] = useState(false);
    const [lobbyLeft, setLobbyLeft] = useState(0);

    const handleToggle = () => {
        setIsActive(!isActive);
    };
   
    const flipLeft = () => {
        setLobbyLeft((prevLobbyLeft) => prevLobbyLeft + 100);
    };
    
    const flipRight = () => {
        setLobbyLeft((prevLobbyLeft) => prevLobbyLeft - 100);
    };

    const user = useSelector((state) => state.userReducer.user);
    const loggedIn = useSelector((state) => state.userReducer.loggedIn);
    const activeChallengeStatus = useSelector(
        (state) => state.challengeReducer.activeChallengeStatus
    );
    const activeChallenges = useSelector(
        (state) => state.challengeReducer.activeChallenges
    );
    const currentActiveChallenge = useSelector((state) => getCurrentActiveChallenge(state));
    const location = useLocation();


    function renderCurrentChallenge(activeChallenge, index, activeChallenges, user) {
        var color = "";
        var enterScore = "";
        {activeChallenge.participants.map((participant,index) => {
            if (participant.userId === user.id){
                if (activeChallenge.status === "RUNNING"){
                    if(participant.status === "DONE"){
                        color = "#488F4B";
                        enterScore = "Score entered";
                    }
                    else {
                        color = "#FFF";
                        enterScore = "No score entered";
                    }
                } else {
                    color = "#000";
                    enterScore = "Not started";
                }
            }
            else {}
            }
        )}
        return (
            // style={{backgroundColor: color}}
            <div key={index} className={"current-lobby-holder "}  id="current-lobby-holder">
                <h3 id="current-lobby-head" onClick={handleToggle}>
                    <small>Your Challenges</small>
                </h3>
                <h3>{activeChallenge.name}<br/>  <Moment locale="en" date={activeChallenge.startTime} format="MMMM Do" /></h3>
                <div className="player-count">
                {activeChallenge.numberOfParticipants} {(activeChallenge.numberOfParticipants === 1 ? 'player' : 'players')} registered
                </div>
                <div style={{color: color}}>{enterScore}</div>
                <Link to={activeChallenge.name.startsWith('EXTRACURRICULAR') ? `/extracurricular/lobby/${activeChallenge.id}`: `/lobby/${activeChallenge.id}`}><p>Go to lobby</p></Link>
                {activeChallenges.length > (index + 1) ? <div className="arrow-right" onClick={flipRight}></div> : null}
                {(index + 1) > 1 ? <div className="arrow-left" onClick={flipLeft}></div> : null}
            </div>
        );
    }

    function renderChallengeContainer(activeChallenges, user){      
        return (
            <div className={isActive ? 'display-full currentLobbyHolder-container': 'display-compressed currentLobbyHolder-container'}>
                <div className="currentLobbyHolder-inner" style={{width:(activeChallenges.length * 100) + "%", left:lobbyLeft + "%"}}>
                    {activeChallenges.map((challenge,index) => {
                        return renderCurrentChallenge(challenge, index, activeChallenges, user);
                    })}
                </div>
            </div>
        );
    }

    

    return (
        <>              
                    
            {
                currentActiveChallenge !== undefined && !location.pathname.startsWith('/lobby') ? renderChallengeContainer(activeChallenges, user) : null
            }              
        </>
    );   

}


