import css from './hero.module.scss';
import {HashButton} from './navigation/button';


const Hero = (props) => {
    const { title, subtitle, source, home, opacity } = props;

    return (
        <>
         <header
            className={`${css.hero} ${home ? css.hero__home : ''}`}
            style={{ backgroundImage: `url(${source})` }}
            >
                <div className={css.hero__overlay} style={{ opacity: opacity || 0.5 }} />
                <div className={css.hero__content}>
                <h1 className={css.title}>{title}</h1>

                <p className={css.subtitle}>{subtitle}</p>
                {home ? (
                    <div className={css.btns}>
                    <HashButton to="/extracurricular#challenges">
                        The Challenges
                    </HashButton>
                    <HashButton to="/extracurricular#howtoplay" white>
                        How To Play
                    </HashButton>
                    </div>
                ) : null}
                </div>
                <a className={css.badge} href="https://wgc.gg" target="_blank" rel="noopener noreferrer">
                <p>Powered by</p>
                <img src="/images/extracurricular/logos/logo-wgc.svg" alt="" width={200} height={200} />
                </a>

                </header>
                <span id="intro">{/* Anchor */}</span>
        </>
    );
}

export default Hero;