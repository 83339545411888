import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'query-string';
import { verifyDeposit } from "../../actions/userActions";

class VerifyDeposit extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

        let url = this.props.location.search;
        let params = queryString.parse(url);
        console.log(params);
        let status = params.status;
        let orderId = params.token;
        let payerId = params.PayerID;
        this.props.localVerifyDeposit(orderId,payerId);
    }

    render() {

        return (
            <div>
                <br />
                <div className="container" >
                    <h2 className="panel-title">Please wait while we verify your deposit...</h2>

                </div>
            </div>
        );
    }


}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        localVerifyDeposit: (orderId, payerId) => dispatch(verifyDeposit(orderId, payerId))
    };

}

const verifyDepositPage = connect(mapStateToProps, mapDispatchToProps)(VerifyDeposit);
export { verifyDepositPage as VerifyDeposit }