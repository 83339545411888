import React from 'react'
import { showHcp } from '../utils/translate'
import { lookup } from "country-data"
import 'flag-icon-css/css/flag-icon.min.css';

var flagStyle = { marginLeft: '5px', width: '1.0em' }

const TopListParticipant = ({ numericType, firstName, lastName, countryIso, hcp, playerStatus, profilePictureUrl, formattedDecimalValue, longValue, index }) => {

    var pictureUrl = typeof profilePictureUrl === 'undefined' ? '/images/profile-picture.png' : profilePictureUrl;
    var numericValue = numericType === 'INTEGER' ? longValue : formattedDecimalValue;
    var country = lookup.countries({ alpha3: countryIso })[0];

    return(
        <tr>
            <td className="count">
                #{index + 1}
            </td>
            <td className="challenger">
                <img className="profile-picture" src={pictureUrl} alt="Profile" />
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <span>
                    {firstName + ' ' + lastName}
                </span>  
                {country !== undefined ?
                        <span style={flagStyle} className={"flag-icon flag-icon-" + country.alpha2.toLowerCase()}></span> 
                    : null
                    }
                </div>
     
                {showHcp({ playerStatus, hcp })}
            </td>
            <td className="points">
                {numericValue}
            </td>
        </tr>
    )
}

export default TopListParticipant