import { ChallengeType, LeaderboardWinnerType, HcpType, GameType, PlayerStatus, PayoutType } from '../utils/const';
import { round } from '../utils/math';
import Moment from 'react-moment';
import { datesAreOnSameDay } from '../utils/datetime';

export function translateChallengeType(type) {
    switch (type) {
        case ChallengeType.FREE:
            return "Free";
        case ChallengeType.PAYED:
            return "Buy in";
        case ChallengeType.SPONSORED:
            return "Sponsored";
        case ChallengeType.QUALIFIER:
            return "Fridayplay Challenge Qualifier";
        case ChallengeType.FINAL:
            return "Fridayplay Challenge Final";
        default:
            return "Unknown";
    }
}

export function translateGameType(type) {
    switch (type) {
        case GameType.STROKE_PLAY_NET:
            return "Net Stroke play";
        case GameType.STABLEFORD_NET:
            return "Net Stableford";
        case GameType.STABLEFORD:
            return "Stableford";
        case GameType.STROKE_PLAY:
            return "Stroke play";
        default:
            return "Unknown";
    }
}

export function translateLeaderboardWinnerType(type) {
    switch (type) {
        case LeaderboardWinnerType.DAILY_SCORE:
            return "Daily";
        case LeaderboardWinnerType.WEEKLY_SCORE:
            return "Weekly";
        case LeaderboardWinnerType.MONTHLY_SCORE:
            return "Monthly";
        default:
            return "Unknown";
    }
}

export function translateHcpType(hcpType) {
    switch (hcpType) {
        case HcpType.SINGLE:
            return "HCP Single";
        case HcpType.HCP_1_18:
            return "HCP up to 18";
        case HcpType.HCP_19_36:
            return "HCP up to 54";
        case HcpType.ANY:
            return "Any";
        case HcpType.PRO:
            return "Pro";
        default:
            return "Unknown";
    }
}


export function translateDepositSuccededResult(amount, totalAmount) {
    return `You have deposited $${amount} to your account. Your new total is $${totalAmount}`;
}

export function translateWithdrawRequestSuccededResult(amount, totalAmount) {
    return `You have requested a withdrawal of $${amount} from your account. Your new total is $${totalAmount}. We will complete your request shortly.`;
}

export function translateRedeemCodeSuccededResult(amount, totalAmount) {
    return `$${amount} has been added to your account. Your new total is $${totalAmount}`;
}

export function displayFee(fee) {
    return fee > 0 ? currencySymbol() + fee : 'FREE';
}

export function displayCurrency(amount) {
    return '$' + amount;
}

export function currencySymbol() {
    return '$';
}
export function currencyText() {
    return 'USD';
}

export const pricesHtml = ({ prices }) => {

    let htmlString = "";
    if (prices.length >= 1) {
        htmlString = htmlString + '<div class="reward-container"><img src="/images/WGC_gold_ball.png">1st: ' + (prices[0].payoutType === PayoutType.CASH ? '$'+prices[0].payout : prices[0].text) + '</div>';
    }
    if (prices.length >= 2) {
        htmlString = htmlString + '<div class="reward-container"><img src="/images/WGC_silver_ball.png">2nd: ' + (prices[1].payoutType === PayoutType.CASH ? '$' + prices[1].payout : prices[1].text) +  '</div>';
    }
    if (prices.length >= 3) {
        htmlString = htmlString + '<div class="reward-container"><img src="/images/WGC_bronze_ball.png">3rd: ' + (prices[2].payoutType === PayoutType.CASH ? '$' + prices[2].payout : prices[2].text) +  '</div>';
    }

    if (prices.length > 3) {
       /* prices.splice(0, 3);

        htmlString = prices.map((item, index) => {
            return htmlString + '<div class="reward-container"><div></div>' + (index + 4) + 'th: $' + item.payout + '</div>';
        });*/
        htmlString = htmlString + '<small>Full breakdown in your lobby</small>';

    }

    return htmlString;
}


export const showScore = ({ strokes, points }) => {

    if (typeof strokes !== 'undefined' && strokes !== null) {
        return ' ' + strokes + ' strokes';
    }
    else if (typeof points !== 'undefined' && points !== null) {
        return ' ' + points + ' points';
    }
    else {
        return '';
    }
}

export const showHcp = ({ playerStatus, hcp }) => {
    return playerStatus === PlayerStatus.PRO ? 'PRO' : (hcp !== undefined ? round(hcp, 1).toFixed(1).replace('-','+') : null);
}

export const formatChallengeStartAndEndTime = (startTime, endTime) => {
    if(startTime === undefined) {
        return '';
    }

    if (endTime !== undefined) {
        if (datesAreOnSameDay(startTime, endTime)) {
            return <Moment locale="en" date={startTime} format="MMM. Do" />;
        }
        else {
            return <><Moment locale="en" date={startTime} format="MMM. Do" /> to <Moment locale="en" date={endTime} format="MMM. Do" /></>;
        }
    }
    else {
        return <Moment locale="en" date={startTime} format="MMM. Do" />; {/* dddd MMMM Do */ }
    }
}