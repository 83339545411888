import React from "react";
import Countries from 'react-select-country';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form'
import { required, biggerDate } from "../components/validations";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import DatePicker from "react-widgets/DatePicker"
import { Region } from "./const";

import "react-widgets/styles.css";

var buttonStyle = { marginTop: '20px' }

export const renderField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            <input {...input} placeholder={placeholder} type={type} />
            {touched &&
                ((error && <label className="errorLabel">{error}</label>) ||
                (warning && <label className="errorLabel">{warning}</label>))}
        </div>
    )

    export const renderTextareaField = ({
        input,
        label,
        placeholder,
        type,
        meta: { touched, error, warning }
    }) => (
            <div>
                <label>{label}</label>
                <textarea {...input} placeholder={placeholder} type={type} />
                {touched &&
                    ((error && <label className="errorLabel">{error}</label>) ||
                    (warning && <label className="errorLabel">{warning}</label>))}
            </div>
        )


export const renderFieldWithShake = ({
    input,
    label,
    placeholder,
    className,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            {(typeof className !== 'undefined') ? 
            <input {...input} style={disabled ? { cursor: 'not-allowed', background: '#e9ecef' } : {}}  className={touched && error ? 'error animated shake ' + className : className} placeholder={placeholder} type={type} disabled={disabled ? true : false} /> :
            <input {...input} style={disabled ? { cursor: 'not-allowed', background: '#e9ecef' } : {}} className={touched && error ? 'error animated shake' : ''} placeholder={placeholder} type={type} disabled={disabled ? true : false} />
            }
            {touched &&
                ((error && error!=='Required' && <label className="errorLabel">{error}</label>) ||
                (warning && <label className="errorLabel">{warning}</label>))}
        </div>
    )


export const renderFieldCheckbox = ({
    id,
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning, valid }
}) => (
        <div className="checkbox-input-container" >
            <div onClick={() => document.getElementById(id).click()} className={valid ? "checkbox-active" : "checkbox"}></div>

            <span>{label}</span>
            <input {...input} type={type} id={id} />
            {touched &&
                ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                    (warning && <label className="errorLabel">{warning}</label>))}
        </div>
    )

export const renderFieldCheckboxWithLink = ({
    id,
    input,
    label,
    link,
    linkText,
    placeholder,
    type,
    meta: { touched, error, warning, valid }
    }) => (
        <div className="checkbox-input-container">
            <div onClick={() => document.getElementById(id).click()} className={valid ? "checkbox-active" : "checkbox"}></div>

            <span>{label} <Link to={link} target="_blank">{linkText}</Link></span>
            <input {...input} type={type} id={id} />
            {touched &&
                ((error && <label className="errorLabel">{error}</label>) ||
                    (warning && <label className="errorLabel">{warning}</label>))}  {/* && error !== 'Required' */}
        </div>
    )

export const renderCountrySelectField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            <Countries  {...input} className={touched && error ? 'error animated shake' : ''} empty={placeholder}>
            </Countries >
            {touched &&
                ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                (warning && <label classname="errorLabel">{warning}</label>))}
        </div>
    )

export const renderPhoneField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
    <div>
        <label>{label}</label>
            <PhoneInput {...input} autoFormat={true} masks={{ se: '... ... ...' }} country={'ch'} preferredCountries={['se', 'ch']} inputProps={{ name: input.name }} value={input.value} inputClass={touched && error ? 'error animated shake' : ''} placeholder={placeholder}>
        </PhoneInput>
        {touched &&
            ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                (warning && <label classname="errorLabel">{warning}</label>))}
    </div>
)
   

export const renderStatusSelectField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            <Field component="select" validate={[required]} {...input} className={touched && error ? 'error animated shake' : ''}>
                <option value="">{placeholder}</option>
                <option value="AMATURE">Amateur</option>
                <option value="PRO">Pro</option>
            </Field >
            {touched &&
                ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                    (warning && <label classname="errorLabel">{warning}</label>))}
        </div>
)

export const renderRegionSelectField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
    <div>
        <label>{label}</label>
        <Field component="select" validate={[required]} {...input} className={touched && error ? 'error animated shake' : ''}>
            <option value="">{placeholder}</option>
            <option value={Region.EUROPE}>{Region.EUROPE}</option>
            <option value={Region.AMERICAN}>AMERICAS</option>
            <option value={Region.ASIA}>{Region.ASIA}</option>
        </Field >
        {touched &&
            ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                (warning && <label classname="errorLabel">{warning}</label>))}
    </div>
)

export const renderStatusCreateSelectField = ({
    input,
    label,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
        <div>
        <label>{label}</label>
        <Field component="select" validate={[required]} {...input} style={disabled ? { cursor: 'not-allowed' } : {}} className={touched && error ? 'error animated shake' : ''} disabled={disabled ? true : false}>
                <option value="ANY">Amateur and Pro</option>
                <option value="SINGLE">HCP Single</option>
                <option value="HCP_1_18">HCP up to 18</option>
                <option value="HCP_19_36">HCP up to 54</option>
                <option value="PRO">Pro</option>
            </Field >
            {touched &&
                ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                    (warning && <label classname="errorLabel">{warning}</label>))}
        </div>
)


export const renderGameTypeSelectField = ({
    input,
    label,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
        <div>
        <label>{label}</label>
        <Field component="select" validate={[required]} {...input} style={disabled ? { cursor: 'not-allowed' } : {}}  className={touched && error ? 'error animated shake' : ''}  disabled = {disabled ? true : false} >
                <option value="STABLEFORD_NET">Stableford Net</option>
                <option value="STABLEFORD">Stableford</option>
                <option value="STROKE_PLAY_NET">Stroke Play Net</option>
                <option value="STROKE_PLAY">Stroke Play</option>
            </Field >
            {touched &&
                ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                    (warning && <label classname="errorLabel">{warning}</label>))}
        </div>
    )

export const renderTypeSelectField = ({
    input,
    label,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
        <div>
        <label>{label}</label>
        <Field component="select" validate={[required]} {...input} style={disabled ? { cursor:'not-allowed' } : {}} className={touched && error ? 'error animated shake' : ''} disabled={disabled ? true : false}>
                <option value="PAYED">Buy-in</option>
                <option value="FREE">Free</option>
            </Field >
            {touched &&
                ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                    (warning && <label classname="errorLabel">{warning}</label>))}
        </div>
    )

export const radiobuttonFieldWithShake = ({
    input,
    label,
    className,
    type,
    value,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            {(typeof className !== 'undefined') ?
                <input {...input} className={touched && error ? 'error animated shake ' + className : className} type={type} /> :
                <input {...input} className={touched && error ? 'error animated shake' : ''} type={type} />
            }
            {touched &&
                ((error  && <label className="errorLabel">{error}</label>) ||
                    (warning && <label className="errorLabel">{warning}</label>))} {/* && error !== 'Required' */}
        </div>
)

export const renderDatePicker = ({ input: { onChange, value }, label, disabled, meta: { touched, error, warning } }) => (
    <div>
        <label>{label}</label>
        <DatePicker
        className={touched && error ? 'error animated shake' : ''}
        disabled={disabled}
        onChange={onChange}
        min={new Date(Date.now() + (3600 * 1000 * 24))}
        format="DD MMM YYYY"
        value={!value ? null : new Date(value)}
        />
    </div>
)


export const renderDates = ({ fields }) => (
    <>
        <div className="row">
            <div className="col-md-12">
                
                <button className="button warning" type="button" onClick={() => fields.push({index: fields.length, editable:true})}>Add challenge</button><br /><br />
            </div>
        </div>
       

        {fields.map((challenge, index) =>
            <div key={index} className="row">
                <div className="col-md-5">
                   
                    <Field
                        name={`${challenge}.startTime`}
                        type="text"
                        component={renderDatePicker}
                        label="Start date"
                        placeholder="Select a start date"
                        disabled={!fields.get(index).editable}
                        validate={[required]}
                    />
                </div>
                <div className="col-md-5">
                    <Field
                        name={`${challenge}.endTime`}
                        type="text"
                        component={renderDatePicker}
                        label="End date"
                        placeholder="Select a end date"
                        disabled={!fields.get(index).editable}
                        validate={[required]}
                    />
                </div>

                <div className="col-md-2">
                    {index !== 0 ? <button className={"button warning " + (!fields.get(index).editable ? "disabledBtn" :"")} style={buttonStyle} disabled={!fields.get(index).editable}
                        type="button"
                        title="Remove Member"
                        onClick={() => fields.remove(index)}>Remove</button> : <></>}
                </div>
            </div>
        )}
    </>
)

export const renderFileInput = ({ input, label, type, meta: { touched, error, warning } }) => {
    return (
        <div>
            <div style={{ "display": "flex", "alignItems": "center" }}><span><label>{label} </label></span><span ><label style={{ fontSize: '11px', marginBottom:'-4px' }}>&nbsp;Allowed file types jpg and png, size &lt; 500kb</label></span></div>
            <input style={{ "paddingTop": "8px" }}
                name={input.name}
                type={type}
                accept="image/png, image/jpeg"
                onChange={event => handleChange(event, input)}
            />
            {
                ((error && <label className="errorLabel">{error}</label>) ||
                    (warning && <label className="errorLabel">{warning}</label>))}
        </div>
    );
};

const handleChange = (event, input) => {
    event.preventDefault();
    let imageFile = event.target.files[0];
    if (imageFile) {
        const localImageUrl = URL.createObjectURL(imageFile);
        const imageObject = new window.Image();

        imageObject.onload = () => {
            imageFile.width = imageObject.naturalWidth;
            imageFile.height = imageObject.naturalHeight;
            input.onChange(imageFile);
            URL.revokeObjectURL(imageFile);
        };
        imageObject.src = localImageUrl;
    }
};