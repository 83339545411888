import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { getUser, fromProUrl } from "../actions/userActions";
import { subscribeToChallenges, signup, goto, deletePrivateChallenge } from "../actions/challengeActions";
import { goToRegister } from "../actions/dialogActions";
import { infoDialog, infoDialogPro, specChallengeDialog } from "./challengeDialogs";
import ChallengeList from './challengeList'  
import { SpecialChallenges } from './specialChallenges';
import { showPro, showSingle, showUpTo18, showUpTo54 } from '../utils/hcp';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet-async';
// import { makeStyles } from '@material-ui/core/styles';
// import {Tabs, Tab, AppBar} from '@material-ui/core';
//import { appendScript } from '../utils/jsLoader';

class Home extends React.Component {

    constructor() {
        super();
        this.scrollPro1 = React.createRef();
        this.scrollPro2 = React.createRef();
        this.scrollPro3 = React.createRef();
        this.scrollPro4 = React.createRef();
        this.scrollSingle = React.createRef();
        this.scroll18 = React.createRef();
        this.scroll54 = React.createRef();
        this.scrollInvitations = React.createRef();
        this.scrollYour = React.createRef();
    }

    componentDidMount() {
        console.log("Home componentDidMount:" + this.props.location.pathname);
        // calling the new action creator
        this.props.localGetUser();
        this.props.localSubscribeToChallenges();
       // appendScript('/js/chatScript.js');
        if (this.props.location.pathname === "/pro") {
            this.props.localFromProUrl();
            this.setState({ tabIndex: 1 });
        }
        else if (this.props.fromProUrl) {
            this.setState({ tabIndex: 1 });
        }
        else if (this.props.user !== undefined && this.props.isLoggedIn && this.props.user.playerStatus === "PRO") {
            this.setState({ tabIndex: 1 });
        }
        else if (this.props.location.pathname === "/private") {
            this.setState({ tabIndex: 2 });
        }
    }
    componentWillReceiveProps(nextProps) {
        //Need this to handle login since getUSer is called in componentDidMount so we do not know pro then
        if (nextProps.user !== undefined && nextProps.isLoggedIn && nextProps.user.playerStatus === "PRO") {
            this.setState({ tabIndex: 1 });
        }
      
    }

    state = {
        heightFullPro1: false,
        heightFullPro2: false,
        heightFullPro3: false,
        heightFullPro4: false,
        heightFullSingle: false,
        heightFull18: false,
        heightFull54: false,
        heightFullInvitations: false,
        heightFullYour: false,
        tabIndex: 0,
        userStatus: "",
      }

    heightToggle = (stateName, stateValue ) => {
        this.setState({ [stateName]: !stateValue });
    }
    heightToggle2 = (stateName, stateValue, scroll ) => {
        console.log('xxx', stateName, stateValue);
        this.setState({ [stateName]: !stateValue });
        console.log('scroll', scroll);
        // this.scroll.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
        // if (scroll){
        this.scroll18.current.scrollIntoView({block: 'start',  behavior: 'smooth'})
        // } else {};
    }


    heightTogglePro1 = () => {
        this.setState({ heightFullPro1: !this.state.heightFullPro1 });
    };
    heightTogglePro1Scroll = () => {
        this.setState({ heightFullPro1: !this.state.heightFullPro1 });
        this.scrollPro1.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightTogglePro1 = () => {
        this.setState({ heightFullPro1: !this.state.heightFullPro1 });
    };
    heightTogglePro1Scroll = () => {
        this.setState({ heightFullPro1: !this.state.heightFullPro1 });
        this.scrollPro1.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightTogglePro2 = () => {
        this.setState({ heightFullPro2: !this.state.heightFullPro2 });
    };
    heightTogglePro2Scroll = () => {
        this.setState({ heightFullPro2: !this.state.heightFullPro2 });
        this.scrollPro2.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightTogglePro3 = () => {
        this.setState({ heightFullPro3: !this.state.heightFullPro3 });
    };
    heightTogglePro3Scroll = () => {
        this.setState({ heightFullPro3: !this.state.heightFullPro3 });
        this.scrollPro3.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightTogglePro4 = () => {
        this.setState({ heightFullPro4: !this.state.heightFullPro4 });
    };
    heightTogglePro4Scroll = () => {
        this.setState({ heightFullPro4: !this.state.heightFullPro4 });
        this.scrollPro4.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightToggleSingle = () => {
        this.setState({ heightFullSingle: !this.state.heightFullSingle });
    };
    heightToggleSingleScroll = () => {
        this.setState({ heightFullSingle: !this.state.heightFullSingle });
        this.scrollSingle.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightToggle18 = () => {
        this.setState({ heightFull18: !this.state.heightFull18 });
    };
    heightToggle18Scroll = () => {
        this.setState({ heightFull18: !this.state.heightFull18 });
        this.scroll18.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightToggle54 = () => {
        this.setState({ heightFull54: !this.state.heightFull54 });
    };
    heightToggle54Scroll = () => {
        this.setState({ heightFull54: !this.state.heightFull54 });
        this.scroll54.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightToggleInvitations = () => {
        this.setState({ heightFullInvitations: !this.state.heightFullInvitations });
    };
    heightToggleInvitationsScroll = () => {
        this.setState({ heightFullInvitations: !this.state.heightFullInvitations });
        this.scrollInvitations.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    heightToggleYour = () => {
        this.setState({ heightFullYour: !this.state.heightFullYour });
    };
    heightToggleYourScroll = () => {
        this.setState({ heightFullYour: !this.state.heightFullYour });
        this.scrollYour.current.scrollIntoView({block: 'start',  behavior: 'smooth'});
    };
    render() {
        const { heightFullSingle, user, isLoggedIn, isProChallengesDisabled, euroProChallenges, letAccessChallenges, alpsChallenges, proGolfChallenges, isFreeChallengesDisabled, freeChallenges, isBigBucksChallengesDisabled, bigBucksChallenges, isMajorMayhemChallengesDisabled, majorMayhemChallenges, invitations, isInvitationsDisabled, yourChallenges, isYourChallengesDisabled, localGoTo, localSignup, localDelete, localGoToRegister } = this.props;
        {/*
        if (user.registrationCompleted === false) {
            return <Redirect to={{ pathname: '/signup_step2', state: { from: this.props.location, refresh: true } }} />;
        }*/}

        {/*
        if (isLoggedIn && user.usernameVerified===false) {
            return <Redirect to={{ pathname: '/awaiting_verification', state: { from: this.props.location, refresh: true } }} />;
        } */}
        return (
            <>
            <Helmet>
            <title>World Golf Challenge</title>
            </Helmet>           
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
               
                            <div className="panel">
                                <div className="header-image-container">
                                    <div className="container">
                                        <img src="/images/hero-image-2.jpg" className="logo" alt="logo"/>
                                        <div className="header-image-text">
                                            <h1> Playing golf physically while competing digitally</h1>
                                            <Link to="#" onClick={() => this.state.tabIndex===1 ? infoDialogPro() : infoDialog()} className="header-link">How to play</Link>
                                        </div>
						            </div>
                                 </div>

                            </div>

                        </div>
                        <div className="col-md-4">
                            <SpecialChallenges isLoggedIn={isLoggedIn} user={user} signup={localSignup} goToRegister={localGoToRegister}/>
                        </div>
                    </div>
                </div>
                <div className="container-fluid challenge-list">
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
                        <TabList>
                            <Tab>Amateur</Tab>
                            <Tab>Pro</Tab>
                            <Tab>Private</Tab>
                        </TabList>

                        {/* AMATEUR CHALLENGES */}
                        <TabPanel>
                            <div className="row row-challenges">

                                <div className="col-md-4">
                                    <div className={'panel challenge' + (isFreeChallengesDisabled || (isLoggedIn && !showSingle(user)) ? ' disabledPanel' : '')}>
                                        <div ref={this.scrollSingle} className="anchor-name"></div>
                                        <div className="title-container">
                                            {/*   <img className="chest" src="./images/small-badge.png" alt="Twitch Partner"/>
                                            <img src="./images/green-title-bg.svg" alt="Mini Mash" />  */}
                                            <h4>HCP Single</h4>
                                        </div>
                                        <div className={this.state.heightFullSingle ? 'challenge-holder-full challenge-holder': 'challenge-holder-compressed challenge-holder'}>
                                            {/* @include('components.challengeBoxList', ['amount' => 3, 'value' => [1,15,25], 'color' => 'green', 'title' => 'Mini Mash'])
                                            */}
                                            <ChallengeList title='HCP Single' color='green' challenges={freeChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                                            {/* <div className="show-more-button" onClick={() => this.heightToggle('heightFullSingle', this.state.heightFullSingle )}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={() => this.heightToggle2('heightFullSingle', this.state.heightFullSingle, 'scrollSingle')}><span>Show less -</span></div> */}
                                            <div className="show-more-button" onClick={this.heightToggleSingle}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightToggleSingleScroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className={'panel challenge' + (isBigBucksChallengesDisabled || (isLoggedIn && !showUpTo18(user)) ? ' disabledPanel' : '')}>
                                        <div ref={this.scroll18} className="anchor-name"></div>
                                        <div className="title-container">
                                            {/* <img className="chest" src="./images/medium-badge.png" alt="Twitch Partner" />
                                            <img src="./images/red-title-bg.svg" alt="Big bucks"/>  */}
                                            <h4>HCP up to 18</h4>
                                        </div>
                                        <div className={this.state.heightFull18 ? 'challenge-holder-full challenge-holder': 'challenge-holder-compressed challenge-holder'}>
                                            <ChallengeList title='Hcp up to 18' color='red' challenges={bigBucksChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                                            <div className="show-more-button" onClick={this.heightToggle18}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightToggle18Scroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className={'panel challenge' + (isMajorMayhemChallengesDisabled || (isLoggedIn && !showUpTo54(user)) ? ' disabledPanel' : '')}>
                                        <div ref={this.scroll54} className="anchor-name"></div>
                                        <div className="title-container">
                                            {/* <img className="chest" src="./images/large-badge.png" alt="Twitch Partner" />
                                            <img src="./images/purple-title-bg.svg" alt="Major Mayhem" />*/}
                                            <h4>HCP up to 54</h4>
                                        </div>
                                        <div className={this.state.heightFull54 ? 'challenge-holder-full challenge-holder': 'challenge-holder-compressed challenge-holder'}>
                                            <ChallengeList title='Hcp up to 54' color='purple' challenges={majorMayhemChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                                            <div className="show-more-button" onClick={this.heightToggle54}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightToggle54Scroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        {/* PRO CHALLENGES */}
                        <TabPanel>
                            <div className="row row-challenges">
                                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className={'panel challenge' + (isProChallengesDisabled || (isLoggedIn && !showPro(user)) ? ' disabledPanel' : '')}>
                                        <div ref={this.scrollPro1} className="anchor-name"></div>
                                        <div className="title-container">
                                          
                                            <h4>Let Access Tour</h4>
                                        </div>
                                        <div className={this.state.heightFullPro1 ? 'challenge-holder-full challenge-holder' : 'challenge-holder-compressed challenge-holder'}>
                                           
                                            <ChallengeList title='Let Access Tour' color='green' challenges={letAccessChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                                            <div className="show-more-button" onClick={this.heightTogglePro1}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightTogglePro1Scroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className={'panel challenge' + (isProChallengesDisabled || (isLoggedIn && !showPro(user)) ? ' disabledPanel' : '')}>
                                        <div ref={this.scrollPro2} className="anchor-name"></div>
                                        <div className="title-container">
                                            
                                            <h4>Alps Tour</h4>
                                        </div>
                                        <div className={this.state.heightFullPro2 ? 'challenge-holder-full challenge-holder' : 'challenge-holder-compressed challenge-holder'}>
                                           
                                            <ChallengeList title='Alps Tour' color='green' challenges={alpsChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                                            <div className="show-more-button" onClick={this.heightTogglePro2}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightTogglePro2Scroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>
                                {/*
                                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className={'panel challenge' + (isProChallengesDisabled || (isLoggedIn && !showPro(user)) ? ' disabledPanel' : '')}>
                                        <div ref={this.scrollPro3} className="anchor-name"></div>
                                        <div className="title-container">
                                            <h4>Pro Golf Tour</h4>
                                        </div>
                                        <div className={this.state.heightFullPro3 ? 'challenge-holder-full challenge-holder' : 'challenge-holder-compressed challenge-holder'}>                                          
                                            <ChallengeList title='Pro Golf Tour' color='green' challenges={proGolfChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                                            <div className="show-more-button" onClick={this.heightTogglePro3}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightTogglePro3Scroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className={'panel challenge' + (isProChallengesDisabled || (isLoggedIn && !showPro(user)) ? ' disabledPanel' : '')}>
                                        <div ref={this.scrollPro4} className="anchor-name"></div>
                                        <div className="title-container">
                                            <h4>Nordic League</h4>
                                        </div>
                                        <div className={this.state.heightFullPro4 ? 'challenge-holder-full challenge-holder' : 'challenge-holder-compressed challenge-holder'}>
                                          <ChallengeList title='Nordic League' color='green' challenges={euroProChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                                            <div className="show-more-button" onClick={this.heightTogglePro4}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightTogglePro4Scroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>
                               
                                */}
                                
                                
                            </div>
                        </TabPanel>

                        {/* PRIVATE CHALLENGES */}
                        <TabPanel>
                            <div className="row row-challenges">
                                <div className="col-md-6">
                                    <div className={'panel challenge' + (isYourChallengesDisabled ? ' disabledPanel' : '')}>
                                        <div ref={this.scrollYour} className="anchor-name"></div>
                                        <div className="title-container">
                                            <h4>Your Private Challenges</h4>
                                        </div>
                                        <Link to="/create" className="createChallenge-button">+ Create new Challenge/Championship</Link>
                                        <div className={this.state.heightFullYour ? 'challenge-holder-full challenge-holder' : 'challenge-holder-compressed challenge-holder'}>
                                            {yourChallengesText(yourChallenges, isLoggedIn, localGoTo, localSignup, localDelete, localGoToRegister)}
                                            <div className="show-more-button" onClick={this.heightToggleYour}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightToggleYourScroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className={'panel challenge' + (isInvitationsDisabled ? ' disabledPanel' : '')}>
                                        <div ref={this.scrollInvitations} className="anchor-name"></div>
                                        <div className="title-container">
                                            <h4>Invitations</h4>
                                        </div>
                                        <div className={this.state.heightFullInvitations ? 'challenge-holder-full challenge-holder': 'challenge-holder-compressed challenge-holder'}>
                                            {invitationsText(invitations, isLoggedIn, localGoTo, localSignup, localGoToRegister)}
                                            <div className="show-more-button" onClick={this.heightToggleInvitations}><span>Show more +</span></div>
                                            <div className="show-less-button" onClick={this.heightToggleInvitationsScroll}><span>Show less -</span></div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </TabPanel>

                    </Tabs>
                </div>
            </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.userInfo,
        isLoggedIn: state.userReducer.loggedIn,
        fromProUrl: state.userReducer.fromProUrl,

        letAccessChallenges: sortChallenges(filterProChallenges(state.challengeReducer.proChallenges, "Let Access Tour")),
        proGolfChallenges: sortChallenges(filterProChallenges(state.challengeReducer.proChallenges, "Pro Golf Tour")),
        alpsChallenges: sortChallenges(filterProChallenges(state.challengeReducer.proChallenges, "Alps Tour")),
        euroProChallenges: sortChallenges(filterProChallenges(state.challengeReducer.proChallenges, "Nordic League")),

        isProChallengesDisabled: state.challengeReducer.isProChallengesDisabled,

        freeChallenges: sortChallenges(state.challengeReducer.freeChallenges),
        isFreeChallengesDisabled: state.challengeReducer.isFreeChallengesDisabled,

        bigBucksChallenges: sortChallenges(state.challengeReducer.bigBucksChallenges),
        isBigBucksChallengesDisabled: state.challengeReducer.isBigBucksChallengesDisabled,

        majorMayhemChallenges: sortChallenges(state.challengeReducer.majorMayhemChallenges),
        isMajorMayhemChallengesDisabled: state.challengeReducer.isMajorMayhemChallengesDisabled,

        invitations: state.challengeReducer.invitations !== undefined ? sortChallenges(state.challengeReducer.invitations) : undefined,

        isInvitationsDisabled: state.challengeReducer.isInvitationsDisabled,

        yourChallenges: state.challengeReducer.yourChallenges !== undefined ? sortChallenges(state.challengeReducer.yourChallenges) : undefined,

        isYourChallengesDisabled: state.challengeReducer.isYourChallengesDisabled,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localGetUser: () => dispatch(getUser()),
        localSubscribeToChallenges: () => dispatch(subscribeToChallenges()),
        localSignup: (id,special) => dispatch(signup(id,special)),
        localGoTo: id => dispatch(goto(id)),
        localGoToRegister: (id) => dispatch(goToRegister(id)),
        localFromProUrl: () => dispatch(fromProUrl()),
        localDelete: (id) => dispatch(deletePrivateChallenge(id))
    };
}

function invitationsText(invitations, isLoggedIn, localGoTo, localSignup, localGoToRegister){
    if(invitations.length > 0){
        return <ChallengeList title='Invitations' color='green' challenges={invitations} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
    } else {
        return <div className="noChallenges-text">You have no active invitations.</div>
    }
}

function yourChallengesText(yourChallenges, isLoggedIn, localGoTo, localSignup, localDelete, localGoToRegister){
    if(yourChallenges.length > 0){
        return <ChallengeList title='Your Private Challenges' color='red' challenges={yourChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} deleteChallenge={localDelete} showLink={true} />
    } else {
        return <div className="noChallenges-text">You have no active challenges.<br />Create one and invite your friends to start playing!</div>
    }
}
const sortChallenges = (challenges) => {

    if (challenges === null)
        return challenges;

    return challenges.sort((a, b) => {
        if (a.startTime < b.startTime) { return -1; }
        else if (a.startTime > b.startTime) { return 1; }
        else {
            if (a.fee < b.fee) { return -1; }
            else if (a.fee > b.fee) { return 1; }
            return 0;
        }
    });


}

const filterProChallenges = (challenges, proTour) => {
    return challenges.filter(x => x.description === proTour);
}

const home = connect(mapStateToProps, mapDispatchToProps)(Home);
export { home as Home };
