import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { translateChallengeType } from '../utils/translate';
import { getCurrentActiveChallenge } from "../selectors/activeChallengeSelector";
import { ChallengeType } from '../utils/const';
import Moment from 'react-moment';
import 'moment-timezone';
import ActiveChallenges from './activeChallenges';

class SideBar extends React.Component {
   
    render() {
     
        const {  loggedIn, location} = this.props;

        return (
            <div className="sidebar-menu">
                <Link to="/"><img src="/images/wgc-logo.png" className="logo" alt="logo"/></Link>
                <div className="navigation-holder">
                    <Link to="/clubhouse" className={location.pathname === "/" ? "active" : ""}>
                        <div className="menu-item">
                            <div className="img-holder">
                                <img src="/images/icons/clubhouse.svg" alt="Clubhouse"/>
				            </div>
                                Clubhouse
			             </div>
                    </Link>

                    <Link to="/leaderboard" className={location.pathname === "/leaderboard" ? "active" : ""}>
                        <div className="menu-item">
                            <div className="img-holder">
                                <img src="/images/icons/leaderboard.svg" alt="Leaderboard" />
		                    </div>
                                Leaderboard
	                        </div>
                    </Link>

                    {loggedIn ?
                        <Link to="/wallet" className={location.pathname === "/wallet" ? "active" : ""}>
                            <div className="menu-item">
                                <div className="img-holder">
                                    <img src="/images/icons/wallet.svg" alt="Wallet"/>
                                </div>
                                Wallet
			                </div>
                        </Link>
                        : null
                    }
                    {loggedIn ?
                        <Link to="/settings" className={location.pathname === "/settings" ? "active" : ""}>
                            <div className="menu-item">
                                <div className="img-holder">
                                    <img src="/images/icons/settings.svg" alt="Settings"/>
                            </div>
                                Settings
			                </div>
                        </Link>
                        : null
                    }
                    <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>
                        <div className="menu-item">
                            <div className="img-holder">
                                <img src="/images/icons/about.svg" alt="About"/>
				            </div>
				                About
			            </div>
                    </Link>
                    <Link to="/faq" className={location.pathname === "/faq" ? "active" : ""}>
                        <div className="menu-item">
                            <div className="img-holder">
                                <img src="/images/icons/faq.svg" alt="Faq"/>
				            </div>
				                FAQ
			            </div>
                    </Link>
                    {loggedIn ?
                        <Link to="/logout" className={location.pathname === "/logout" ? "active" : ""}>
                        <div className="menu-item">
                            <div className="img-holder">
                                <img src="/images/icons/logout.svg" alt="Logout"/>
				            </div>
				            Sign out
			            </div>
                        </Link>
                        : null
                    }

	            </div>

                {/*
                    activeChallenges.map((challenge,index) => {
                        if (challenge.isParticipating) {
                            if (!location.pathname.startsWith('/fridayplays') && (challenge.type === ChallengeType.QUALIFIER || challenge.type === ChallengeType.FINAL))
                            {
                                return null; //renderCurrentChallenge(index, challenge);
                            }
                            else if (!location.pathname.startsWith('/lobby') && (challenge.type === ChallengeType.FREE || challenge.type === ChallengeType.PAYED || challenge.type === ChallengeType.SPONSORED)) {
                                return renderCurrentChallenge(index, challenge);
                            }
                            else {
                                return null;
                            }
                        }
                        else {
                            return null;
                        }
                    })
                */}{
                    // currentActiveChallenge !== undefined && !location.pathname.startsWith('/lobby') && (currentActiveChallenge.type === ChallengeType.FREE || currentActiveChallenge.type === ChallengeType.PAYED || currentActiveChallenge.type === ChallengeType.SPONSORED)  ? this.renderCurrentChallenges(activeChallenges) : null
                }
                {
                   <ActiveChallenges />
                }
                {/* <div className={this.state.isActive ? 'display-full currentLobbyHolder-container': 'display-compressed currentLobbyHolder-container'}>
                    <div className="currentLobbyHolder-inner" style={{width:(activeChallenges.length * 210) + "px"}, {left:lobbyLeft + "px"}}>
                        {activeChallenges.map((challenge,index) => {
                            if (currentActiveChallenge !== undefined && !location.pathname.startsWith('/lobby') && (currentActiveChallenge.type === ChallengeType.FREE || currentActiveChallenge.type === ChallengeType.PAYED || currentActiveChallenge.type === ChallengeType.SPONSORED)){
                                return this.renderCurrentChallenge(challenge, index, activeChallenges);
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div> */}
                <div className="social-links">
                    <Link to={{pathname: "https://www.instagram.com/worldgolfchallenge/" }} target="_blank">
                        <img src="/images/icons/instagram-icon.svg" alt="WGC Instagram" />
                    </Link>
                    <Link to={{pathname: "https://www.facebook.com/worldgolfchallenge"}} target="_blank">
                        <img src="/images/icons/facebook-icon.svg" alt="WGC Facebook" />
                    </Link>
                    <Link to={{ pathname: "https://www.linkedin.com/company/worldgolfchallenge/about/"}} target="_blank">
                        <img src="/images/icons/linkedin-icon.svg" alt="WGC LinkedIn" />
                    </Link>
                    <Link to={{ pathname: "mailto:hello@wgc.gg"}} target="_blank">
                        <img src="/images/icons/email-icon.svg" alt="WGC Mail" />
                    </Link>
                </div>
                <div className="important-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-of-agreement">Terms of agreement</Link>
                </div>

           </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        loggedIn: state.userReducer.loggedIn,
    };
}

const sideBar = withRouter(connect(mapStateToProps)(SideBar));
export { sideBar as SideBar };
