import React from 'react';
import { connect } from "react-redux";
import { logout } from "../actions/userActions";

class LogoutPage extends React.Component {

    componentDidMount() {
        this.props.dispatch(logout())
        
    }

    render() {
        return null;
    }
}

export default connect()(LogoutPage);