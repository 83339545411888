// ScrollToTop.js
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { pathname, hash } = useLocation();
  
    useLayoutEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          // Scroll to the element without animation
          element.scrollIntoView();
        }
      } else {
        // Scroll to the top without animation
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
          });
      }
    }, [pathname, hash]);
  
    return null;
  }

  export default ScrollToTop;