import React from 'react'
import { TransactionType, WithdrawalStatus } from '../utils/const'
import { translateChallengeType, translateLeaderboardWinnerType, translateHcpType, translateGameType, currencyText } from '../utils/translate'

const Transaction = ({ type, challengeType, hcpType, gameType, description, challengeId, amount, created, withdrawalStatus, leaderboardWinnerType, index }) => (
    <>
    <tr className="table-spacing"></tr>
    <tr>
        <td>
                {descriptionText(type, challengeType, hcpType, gameType, description, challengeId, withdrawalStatus, leaderboardWinnerType)}
        </td>
        <td>
            {dateFormat(created)}
        </td>
        <td className="points text-left">
                {amount} <small>{currencyText()}</small>
        </td>
    </tr>
    </>
)

function dateFormat(date) {
    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(played)

    return formated;
}

function descriptionText(type, challengeType, hcpType, gameType, description, challengeId, withdrawalStatus, leaderboardWinnerType) {
    if (type === TransactionType.DEPOSIT) {
        return "Deposit" ;
    }
    else if (type === TransactionType.WITHDRAWAL) {
        return "Withdrawal" + withdrawalStatusText(withdrawalStatus);
    }
    else if (type === TransactionType.PAYED_CHALLENGE_FEE) {
        return "Entry fee #" + challengeId + " " + translateHcpType(hcpType) + " " + translateGameType(gameType)   ;
    }
    else if (type === TransactionType.CHALLENGE_PAYOUT) {
        return "Payout #" + challengeId + " " + translateHcpType(hcpType) + " " + translateGameType(gameType);
    }
    else if (type === TransactionType.CHALLENGE_GROUP_PAYOUT) {
        return "Payout " + description;
    }
    else if (type === TransactionType.REFUNDED_CHALLENGE_FEE) {
        return "Refund #" + challengeId + " " + translateHcpType(hcpType) + " " + translateGameType(gameType) + (description !== undefined ? " ("+description+")" : '');
    }
    else if (type === TransactionType.PROMOTION) {
        return "Promotion";
    }
    else if (type === TransactionType.LEADERBOARD_PAYOUT) {
        return translateLeaderboardWinnerType(leaderboardWinnerType) + " leaderboard winner";
    }
    else if (type === TransactionType.DISCOUNT_CODE) {
        return "Coupon redeemed";
    }
    else {
        return type;
    }
}

function withdrawalStatusText(withdrawalStatus) {
    if (withdrawalStatus === WithdrawalStatus.REQUESTED) {
        return " (Pending payout)";
    }
    else if (withdrawalStatus === WithdrawalStatus.COMPLETE) {
        return " (Complete)";
    }
    else if (withdrawalStatus === WithdrawalStatus.DENIED) {
        return " (Denied)";
    }
    else if (withdrawalStatus === WithdrawalStatus.FAILED) {
        return " (Failed)";
    }
    else {
        return "";
    }
}

export default Transaction