import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import css from './challenge.module.scss';
import btn from './navigation/button.module.scss';
import { ChallengeStatus, HcpType } from '../../../utils/const';
import { showSingle,showUpTo18,showUpTo54 } from '../../../utils/hcp';
import { formatChallengeStartAndEndTime } from '../../../utils/translate';

export default function Challenge({
    id,
    url,
    img,
    title,
    dates,
    gameType,
    hcp,
    hcpType,
    price,
    currentPlayers,
    user,
    isLoggedIn,
    challenge,
  }) {

    const [glow, setGlow] = useState(false);

    const disableStyle = {
      pointerEvents: 'none',
      opacity: 0.5,
    }

    return (
      <div className={css.challenge}>
        <div className={css.head}>
          <img src={img} alt={''} width={500} height={200} />
        </div>
        <div className={css.body}>
          <h3>{title}</h3>
          <p>{formatChallengeStartAndEndTime(challenge?.startTime,challenge?.endTime)}</p>
          <p>
            Game type : <b>{gameType}</b>
          </p>
          <div className={css.copyBtn}>
            <input type="text" value={url} disabled />
            <button className={glow ? "copyLinkButton glow" : "copyLinkButton"}
            onClick={() => {setGlow(true); navigator.clipboard.writeText(url)}}
            onAnimationEnd={() => setGlow(false)}>
              <img src={'./images/extracurricular/pictograms/copy.svg'} alt={'Copy Link'} width={20} height={20} />
            </button>
          </div>
        </div>
        <div className={css.banner}>HCP {hcp}</div>
        <div className={css.footer}>
          <span className={css.price}>{price || 'free'}</span>
          <span className={css.players}>
            <img src={'./images/extracurricular/pictograms/user.svg'} alt={'Users participating'} width={20} height={20} />
            &nbsp;{currentPlayers}
          </span>
          <Link className={`${btn.btn}`} to={`/extracurricular/lobby/${id!==undefined ? id: ''}`} style={isDisabled(challenge, isLoggedIn,user,hcpType) ? disableStyle:{}} >
            {challenge?.status===ChallengeStatus.DONE ? 'View' : (challenge?.isParticipating  ? 'Go to' : 'Join now')}
          </Link>
        </div>
      </div>
    );
  }

  const isDisabled = (challenge, isLoggedIn, user,hcpType) => {

    if(challenge === undefined){
      return false
    }

    if(challenge.status === ChallengeStatus.DONE){
      return false;
    }

    if (!isLoggedIn) {
      return false;
    }

    if (hcpType === HcpType.SINGLE) {
      return !showSingle(user);
    }
    else if (hcpType === HcpType.HCP_1_18) {
      return !showUpTo18(user);
    }
    else if (hcpType === HcpType.HCP_19_36) {
      return !showUpTo54(user);
    }
    else {
      return false;
    }

    return !isLoggedIn;
  }

