import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { userReducer } from './userReducer';
import { challengeReducer } from './challengeReducer';
import { tournamentReducer } from './tournamentReducer';
import { challengeEventReducer } from './challengeEventReducer';
import { dialogReducer } from './dialogReducer';
import { leaderboardReducer } from './leaderboardReducer';
import { caruselReducer } from './caruselReducer';
import { infoPanelReducer } from './infoPanelReducer';

function rootReducer(history) {
    return (
        combineReducers({
            router: connectRouter(history),
            userReducer,
            challengeReducer,
            challengeEventReducer,
            tournamentReducer,
            dialogReducer,
            leaderboardReducer,
            caruselReducer,
            infoPanelReducer,
            form: formReducer
        })
    );
}

export default rootReducer;