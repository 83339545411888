import React from 'react';
import { connect } from 'react-redux';
import { Popup } from './popup';
import { BasicSideBar } from './sideBarBasic';
import { BasicTopBar } from './topBarBasic';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ChallengeAnimations } from "./animations/challengeAnimations";

class LayoutBasic extends React.Component {
    render() {

        return (
            <div>
                <BasicTopBar/>
                <BasicSideBar />
                {this.props.children}
                <Popup />
                <ToastContainer />
                <ChallengeAnimations />
            </div>
        );
    }

}

function mapStateToProps(state) {
  
    //To refresh layout so that sidebar and topbar is not drawn for login page when redirected to login
    let data = typeof state.router.location.state !== 'undefined' ? state.router.location.state.refresh : 'undefined'
    return {
        data
    };
}


const layoutBasic = connect(mapStateToProps)(LayoutBasic);
export { layoutBasic as LayoutBasic }; 