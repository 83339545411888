import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { subscribeToLobby, leave, closeLobby, reportScore, signup } from "../actions/challengeActions";
import { showSuccessPopup, goToRegister } from "../actions/dialogActions";
import { joinDialog, goToRegisterDialog } from "./challengeDialogs";
import LobbyTable from "./lobbyTable";
import { CountdownTimer, StartGameRenderer, EndGameRenderer} from "./countdownTimer";
import { getSortedparticipantsCollection } from "../selectors/participantsSelector";
import { ChallengeType, ChallengeStatus, ParticipantStatus, PriceType, Region } from '../utils/const';
import { displayFee, pricesHtml, translateHcpType, translateGameType, displayCurrency } from '../utils/translate'
import { getNextPriceTable, getPriceTable } from '../utils/price'
import { scoreDialog } from "./challengeDialogs";
import Moment from 'react-moment';
import 'moment-timezone';

var titleStyle = { marginTop: '10px'}

class Lobby extends React.Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

        if (this.props.match.params.challengeId) {
            console.log("ChallengeId:" + this.props.match.params.challengeId);
            this.props.localSubscribeToLobby(this.props.match.params.challengeId);
        }
       
    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoggedIn, activeChallenge } = this.props;
        //Added prevprops check to avoid loading of join dialog on exit that happened sometimes
        if (prevProps.activeChallenge === undefined && activeChallenge !== undefined && activeChallenge.isParticipating === false && (activeChallenge.type !== ChallengeType.FINAL && (activeChallenge.status === ChallengeStatus.NOT_STARTED || activeChallenge.status === ChallengeStatus.RUNNING))) {
            if (isLoggedIn) {
                //Join flow
                console.log("parts min: ", activeChallenge.minParticipans);
                var additionalText = "";
                var additionalText2 = "";

                if (activeChallenge.type === ChallengeType.SPONSORED) {
                    additionalText = activeChallenge.description;
                }
                else {
                    if (activeChallenge.fee !== 0) {
                        additionalText = "<small><strong>Starts with a minimum of " + activeChallenge.minParticipans + " players</strong><br />Number of rewards is adjusted to the number of players.<br /></small>";
                        if (activeChallenge.name.startsWith("Weekend Championship")) {
                            activeChallenge.prices = [];
                            activeChallenge.priceType = PriceType.FIXED;
                            additionalText = "<small>Enter all your scores, 2 best scores count for the final ranking.<br/>No gimmies, Maximum quadruple bogey.<br/><br/>Fees cumulate for the final prize pool.<br/>Check the Leaderboard section for your current position.</small>";
                        }
                        if (activeChallenge.hcpType !== "PRO" && !activeChallenge.private) {
                            additionalText2 = "<small>Rewards are divided between tied winners.<br />Winners can be asked a proof of result by email.</small>";
                        } else {
                            additionalText2 = "<small>Rewards are divided between tied winners.</small>";
                        }
                    } else {
                        if (activeChallenge.name.startsWith("HS Championship")) {
                            activeChallenge.prices = [];
                            activeChallenge.priceType = PriceType.FIXED;
                            additionalText = "<small>Register to the Challenge every time you go for a round of golf.<br/>After your round, enter your score.<br/>3 best scores count for the leaderboard.</small>";
                        }
                        else {
                            additionalText = "<small>Ranking:</small>";
                        }
                    }
                }



                joinDialog({
                    challengeName: translateHcpType(activeChallenge.hcpType), challengeNumber: activeChallenge.name, additionalText, additionalText2, startTime: activeChallenge.startTime, gameType: translateGameType(activeChallenge.gameType), holes: activeChallenge.holes, fee: displayFee(activeChallenge.fee), pricePot: activeChallenge.pricePotAfterCut, prices: pricesHtml({ prices: getNextPriceTable(activeChallenge.type, activeChallenge.status, activeChallenge.hcpType, activeChallenge.minParticipans, activeChallenge.numberOfParticipants, activeChallenge.fee, activeChallenge.extraPrizePot, activeChallenge.private, activeChallenge.potShare, activeChallenge.priceType, activeChallenge.prices) }),
                    signup: () => this.props.localSignup(activeChallenge.id, false)
                });
            }
            else {
                //Register flow
                var additionalTxt = "";
                var additionalTxt2 = "";

                if (activeChallenge.type === ChallengeType.SPONSORED) {
                    additionalText = activeChallenge.description;
                }
                else {
                    if (activeChallenge.fee !== 0) {
                        additionalTxt = "<small>strong>Starts with a minimum of " + activeChallenge.minParticipans + " players</strong><br />Number of rewards is adjusted to the number of players.<br /></small>";
                        if (activeChallenge.name.startsWith("Weekend Championship")) {
                            activeChallenge.prices = [];
                            activeChallenge.priceType = PriceType.FIXED;
                            additionalText = "<small>Enter all your scores, 2 best scores count for the final ranking.<br/>No gimmies, Maximum quadruple bogey.<br/><br/>Fees cumulate for the final prize pool.<br/>Check the Leaderboard section for your current position.</small>";
                        }
                        if (activeChallenge.hcpType !== "PRO" && !activeChallenge.private) {
                            additionalText2 = "<small>Rewards are divided between tied winners.<br />Winners can be asked a proof of result by email.</small>";
                        } else {
                            additionalText2 = "<small>Rewards are divided between tied winners.</small>";
                        }
                    } else {
                        if (activeChallenge.name.startsWith("HS Championship")) {
                            activeChallenge.prices = [];
                            activeChallenge.priceType = PriceType.FIXED;
                            additionalText = "<small>Register to the Challenge every time you go for a round of golf.<br/>After your round, enter your score.<br/>3 best scores count for the leaderboard.</small>";
                        }
                        else {
                            additionalText = "<small>Ranking:</small>";
                        }
                    }
                }

                goToRegisterDialog({ challengeName: translateHcpType(activeChallenge.hcpType), challengeNumber: activeChallenge.name, additionalText: additionalTxt, additionalText2: additionalTxt2, startTime: activeChallenge.startTime, gameType: translateGameType(activeChallenge.gameType), holes: activeChallenge.holes, fee: displayFee(activeChallenge.fee), pricePot: activeChallenge.pricePotAfterCut, prices: pricesHtml({ prices: getNextPriceTable(activeChallenge.type, activeChallenge.status, activeChallenge.hcpType, activeChallenge.minParticipans, activeChallenge.numberOfParticipants, activeChallenge.fee,  activeChallenge.extraPrizePot, activeChallenge.private, activeChallenge.potShare, activeChallenge.priceType, activeChallenge.prices) }), goToRegister: () => this.props.localGoToRegister() });
            }
        }
               
    }

    render() {

        const { activeChallengeStatus, activeChallenge, activeChallengeUserInfo, localLeave, localCloseLobby, localReportScore, isLoggedIn, region } = this.props;

        const challengeId = this.props.match.params.challengeId;

        if (activeChallengeStatus === 'NOT_LOADED' || activeChallenge === undefined) return (<div></div>);
            
        return (
          
            <div className="container">
                <div className="row">
                    <div className="col-xl-3">
                    <div className="title-container">
                        <h2>
                            {activeChallenge.name}
                        </h2>
                        <h4>
                            {
                                activeChallengeStatus === 'NOT_FOUND' ? 
                                'Not found'
                                :
                                (activeChallenge.error === true ?
                                    'Error'
                                    :
                                        ((activeChallenge.status === ChallengeStatus.NOT_STARTED || activeChallenge.status === ChallengeStatus.READY_TO_START) ?
                                        countdownStart(activeChallenge)
                                            :
                                            (activeChallenge.status === ChallengeStatus.RUNNING) ?
                                            countdownEnd(activeChallenge)
                                                :
                                                (activeChallenge.status === ChallengeStatus.DONE ?
                                                    finishedLabel(activeChallenge, activeChallengeUserInfo, localCloseLobby)
                                                    :
                                                    'Cancelled'
                                                )))
                                                
                                                

                            }
                            {lobbyName(activeChallenge,region)}
                        
                        </h4>
                        </div>
                    </div>
                    <div className="col-xl-9 logo-banner"> {/* style={showBgImage({ lobbyHeaderImageUrl: activeChallenge.bannerImageUrl })}  */ }
                        <img src={activeChallenge.bannerImageUrl} alt=""/>      
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-3">
                        <h2 className="panel-title">&nbsp;</h2>
                        <div className="">
                            {creatorEdit(activeChallenge, isLoggedIn, activeChallengeUserInfo)}
                        </div>
                         <div className="panel">
                            
                        {
                            activeChallengeStatus === 'NOT_FOUND' ? 
                                notFound() 
                                :
                                (activeChallenge.error === true ?
                                    challengeError(activeChallenge, localCloseLobby)
                                    :
                                    ((activeChallenge.status === ChallengeStatus.NOT_STARTED || activeChallenge.status === ChallengeStatus.READY_TO_START || activeChallenge.status ===ChallengeStatus.RUNNING) ?
                                        lobbyInfo(activeChallenge, activeChallengeUserInfo, localLeave, localCloseLobby, localReportScore, challengeId,this)
                                                :
                                                (activeChallenge.status === ChallengeStatus.DONE ?
                                                    finished(activeChallenge, activeChallengeUserInfo, localCloseLobby)
                                                    :
                                                    cancelled(activeChallenge, localCloseLobby)
                                            )))
                        }
 
                        </div>
                    </div>
                    <div className="col-xl-9">
                        <h2 className="panel-title">Lobby</h2>
                        <div className="panel panel-lobby">
                            <div className="panel-container">
                                {activeChallenge === null ? '' :
                                    <LobbyTable challengeStatus={activeChallenge.status} challengeError={activeChallenge.error} participants={activeChallenge.participants} maxParticipans={activeChallenge.maxParticipans} type={activeChallenge.type} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const showBgImage = ({ lobbyHeaderImageUrl }) => {
    if (lobbyHeaderImageUrl !== undefined && lobbyHeaderImageUrl !== null) {
        return { backgroundImage: "url('" + lobbyHeaderImageUrl + "')", minHeight: "100px", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" };
    } else {
        return null;
    }
}

function leaveChallengeButton(localLeave, id, thisRef) {
    return (
        <div className="actions">
            <button type="button" className="abort" name="button" onClick={() => localLeave(id)}> Unregister</button>
                      

            {/* <button type="button" className="abort" name="button" onClick={() => thisRef.setState({ enabled:true, isVisible: !thisRef.state.isVisible })}>Test</button>*/}
        </div>
    );
}

function registerScoreButton(localReportScore, challengeId, activeChallenge ) {
    return (
        <div className="actions">
            <button type="button" className="enter" name="button" onClick={() => scoreDialog({ reportScoreFunction: localReportScore, challengeId, activeChallenge })}> Enter score</button>
        </div>
    );
}

function countdownStart(activeChallenge){
    return (
        <div className="countdown-wrapper">
            <span>Starts in: </span>
            <CountdownTimer mykey="CountdownStart" started={activeChallenge.startTime} offset={0} renderer={StartGameRenderer} />
        </div>
    );
}
function countdownEnd(activeChallenge){
    return (
        <div className="countdown-wrapper">
            <span>Ongoing. Ends in: </span>
            <CountdownTimer mykey="CountdownStart" started={activeChallenge.endTime} offset={0} renderer={EndGameRenderer} />
        </div>
    );
}


function lobbyName(activeChallenge,region){
    return <div className="lobby-head">
        <div className="challenge-starttime"><span className="challenge-time-label">Start time:</span> <Moment format={region === Region.EUROPE ? 'YYYY-MM-DD HH:mm z' : 'LLL z'}>{activeChallenge.startTime}</Moment></div>
        <div className="challenge-endtime"><span className="challenge-time-label">End time:</span> <Moment format={region === Region.EUROPE ? 'YYYY-MM-DD HH:mm z' : 'LLL z'}>{activeChallenge.endTime}</Moment></div>
    </div>
}

const buyIn = ({ fee }) => {
    return fee === 0 ? 'Free' : <div>{displayCurrency(fee)}</div>;
}

const priceDisplay = ({ price }) => {
    return <div className="placement-payout">{displayCurrency(price.toFixed(2))}</div>;
}
const showPricePot = ({ price }) => {
    return (
        <div className="placement-payout placement-text">
            <span>{price.text}</span>
        </div>
    )
}

// const privateImage = (activeChallenge) => {
//     if (activeChallenge.activeChallenge.id === 901){
//         return <img className="privateImage" src="/images/challenge-bg2.jpg" />;
//     } else {
//         return null;
//     }
// }

const lobbyPrivate = (activeChallenge) => {
    return <div className="lobby-description">
        <h5>Description</h5>
        <div className="privateDescription" style={{ whiteSpace: 'pre-line' }}>{activeChallenge.description}</div>
        <div className="privateCreator"><strong>Creator: {activeChallenge.creatorName}</strong></div>
        {/* {privateImage({activeChallenge})} */}
        <div className="divider"></div>
    </div>
} 

const creatorEdit = (activeChallenge, isLoggedIn, activeChallengeUserInfo) => {
    if(isLoggedIn && activeChallenge.private){
        if (activeChallengeUserInfo !==undefined && activeChallengeUserInfo.userId === activeChallenge.creatorId) {
            return <div className="privateChallenge-edit">
                <div className="copyLink"><span>{activeChallenge.challengeUrl}</span><button className="copyLinkButton" onClick={() => {navigator.clipboard.writeText(activeChallenge.challengeUrl)}}>Copy</button></div>
                <Link to={"/edit/" + activeChallenge.id}><button className="editChallenge">Edit challenge</button></Link>
            </div>
        }   
    }
    return null;
}



function lobbyInfo(activeChallenge, activeChallengeUserInfo, localLeave, localCloseLobby, localReportScore, challengeId, thisRef) {
    return <div className="lobby-info">
        {/* {creatorEdit(activeChallenge, activeChallengeUserInfo)} */}

        {/* {lobbyPrivate(activeChallenge)} */}
        {activeChallenge.private ? lobbyPrivate(activeChallenge) : null}
        <h5>Game Rules</h5>
        <div className="lobby-quickinfo">
            
            <div className="quickinfo-item"> 
                Game type<br />
                <span className="item-value">{translateGameType(activeChallenge.gameType)}</span>
            </div>
            <div className="quickinfo-item">
                Buy-in cost<br />
                <span className="item-value">{buyIn({ fee: activeChallenge.fee })}</span>
            </div>
            <div className="quickinfo-item">
                Course type<br />
                <span className="item-value">{activeChallenge.holes} holes</span>
            </div>
            <div className="quickinfo-item">
                Player count min<br />
                <span className="item-value">{activeChallenge.minParticipans}</span>
            </div>
            <div className="quickinfo-item">
                Player HCP<br />
                <span className="item-value">{translateHcpType(activeChallenge.hcpType)}</span>
            </div>
            <div className="quickinfo-item">
                Player count max<br />
                <span className="item-value">-</span>
            </div>
            
        </div>
        
            

        <div className="divider"></div>
       
        {activeChallengeUserInfo !== undefined ? (activeChallengeUserInfo.status === ParticipantStatus.JOINED && activeChallenge.status === ChallengeStatus.RUNNING
            ? registerScoreButton(localReportScore, challengeId, activeChallenge)
            :
            null) : null

        }
        {activeChallengeUserInfo !== undefined && activeChallengeUserInfo.status === ParticipantStatus.JOINED && activeChallenge.type !== ChallengeType.FINAL && (activeChallenge.status === ChallengeStatus.NOT_STARTED || ((activeChallenge.status === ChallengeStatus.READY_TO_START || activeChallenge.status === ChallengeStatus.RUNNING) && activeChallenge.type === ChallengeType.FREE)) ? leaveChallengeButton(localLeave, activeChallenge.id, thisRef) : ''}

        {activeChallengeUserInfo !== undefined ? (activeChallengeUserInfo.status === ParticipantStatus.DONE 
            ? userFinished(activeChallenge)
            :
            null) : null

        }
        <div className="player-count">
            <h5><span>{activeChallenge.numberOfParticipants}</span><br />{(activeChallenge.numberOfParticipants === 1 ? 'player registered' : 'players registered')}</h5>
        </div>
        <div className="divider"></div>
        <div className="price-pool-section">
            <h4 className="challenge-title" style={titleStyle}>
                <small>Prize pool:</small>
            </h4>

            {getPriceTable(activeChallenge).map((price, index) => (
                <div key={index} className={'reward-cont ' + (index === 0 ? 'first' : 'second')}>
                    <div className="placement">
                        {'#' + price.placement + (price.placementEnd !== undefined ? '-' + price.placementEnd : '')}
                    </div>
                    {price.payoutType === 'MERCHANDISE' ? showPricePot({ price }) : priceDisplay({ price: price.payout })}
                    {/* {price.payoutType === 'MERCHANDISE' ? null : <img src="/images/currency.svg" alt="Currency" />} */}
                </div>
            ))}
        </div>
        <div className="divider"></div>
    </div>;
}

function waitingForUserNotParticipating(activeChallenge) {
    return (<div className="lobby-info">
        <p className="description">
            You are not taking part in this challenge
			<br />
            <br />
        </p>
        <div className="warumup-status-container text-center">
            {/* <img src="/images/loader.svg" alt="" /> */}
            <p>Waiting for players to complete their game</p>
            <CountdownTimer mykey="Waiting" started={activeChallenge.started} offset={(activeChallenge.preCooldownTime + activeChallenge.matchTime + activeChallenge.resultCollectionTime) * 60} renderer={StartGameRenderer} />
        </div>
        </div>
    );
}

function userFinished(activeChallenge) {
    return (<div className="lobby-info">
            <p className="description">
                Great you are done!
                <br />
                <small>You can see your score in the list</small>
		    </p>

        </div>
    );
}

function finished(activeChallenge, activeChallengeUserInfo, localCloseLobby) {
    if (typeof activeChallengeUserInfo !== 'undefined') {
        if (activeChallengeUserInfo.error === false) {
            return (<div className="lobby-info">
                    <br />
                    <p className="description">
                        The challenge is complete! Well played!
			    <br />
                        <br />
                        The winners are displayed on the right side, you are allowed to leave and play as usual! Or maybe play another one ;)
		        </p>
                    <div className="divider"></div>
                    <div className="actions">
                        <button type="button" className="abort" name="button" onClick={() => localCloseLobby(activeChallenge.id)}>CLOSE LOBBY</button>
                    </div>
                </div>
            );
        }
        else {
            return (<div className="lobby-info">
                    <br />
                    <p className="description">
                        The challenge is complete! Unfortunately you where disqualified because of the following error.
			    <br />
                        <br />
                        {activeChallengeUserInfo.errorDescription}
                        <br />
                        <br />
                        The winners are displayed on the right side, you are allowed to leave and play as usual! Or maybe play another one ;)
		        </p>
                    <div className="divider"></div>
                    <div className="actions">
                        <button type="button" className="abort" name="button" onClick={() => localCloseLobby(activeChallenge.id)}>CLOSE LOBBY</button>
                    </div>
                </div>
            );
        }
    }
    else {
        return (<div className="lobby-info">
                <br />
                <p className="description">
                    The challenge is complete!
			    <br />
                    <br />
                    The winners are displayed on the right side
		        </p>
                <div className="divider"></div>
                <div className="actions">
                    <button type="button" className="abort" name="button" onClick={() => localCloseLobby(activeChallenge.id)}>CLOSE LOBBY</button>
                </div>
            </div>
        );
    }
}

function finishedLabel(activeChallenge, activeChallengeUserInfo, localCloseLobby) {
    if (typeof activeChallengeUserInfo === 'undefined' || activeChallengeUserInfo.error === false) {
        return 'FINISHED';
    } else {
        return 'ERROR';
    }
}

function challengeError(activeChallenge, localCloseLobby) {
    return <div className="lobby-info">
        <br />
        <p className="description">
            This challenges has ended because of an error!
			<br />
            <br />
            {activeChallenge.errorDescription}
		</p>
        <div className="divider"></div>
        <div className="actions">
            <button type="button" className="abort" name="button" onClick={() => localCloseLobby(activeChallenge.id)}>CLOSE LOBBY</button>
        </div>
    </div>
}

function cancelled(activeChallenge, localCloseLobby) {
    return <div className="lobby-info">
        <br />
        <p className="description">
            This challenges has been cancelled. Your money has been refunded.
			<br />
            <br />
        </p>
        <div className="divider"></div>
        <div className="actions">
            <button type="button" className="abort" name="button" onClick={() => localCloseLobby(activeChallenge.id)}>CLOSE LOBBY</button>
        </div>
    </div>
}

function participantError(activeChallenge, activeChallengeUserInfo, localCloseLobby) {
    return <div className="lobby-info">
        <br />
        <p className="description">
            You can no longer participate in this challenge.
			<br />
            <br />
            {activeChallengeUserInfo.errorDescription}
        </p>
        <div className="divider"></div>
        <div className="actions">
            <button type="button" className="abort" name="button" onClick={() => localCloseLobby(activeChallenge.id)}>CLOSE LOBBY</button>
        </div>
    </div>
}

function notFound() {
    return <div className="lobby-info">
        <br />
        <p className="description">
            Challenge can not be found
			<br />
            <br />
        </p>
        <div className="divider"></div>
      
        <div className="actions">
            <Link to="/">
                <button type="button" className="abort" name="button">BACK</button>
            </Link>
        </div>
    </div>
}


function mapStateToProps(state, props) {

    const challengeId = parseInt(props.match.params.challengeId, 10)
    return {
        isLoggedIn: state.userReducer.loggedIn,
        region: state.userReducer.userInfo.region,
        activeChallenge: getSortedparticipantsCollection(state, challengeId),
        activeChallengeUserInfo: getActiveChallengeUserInfo(state, challengeId),
        activeChallengeStatus: state.challengeReducer.activeChallengeStatus
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localSubscribeToLobby: (id) => dispatch(subscribeToLobby(id)),
        localLeave: (id) => dispatch(leave(id,false)),
        localCloseLobby: (id) => dispatch(closeLobby(id)),
        localshowSuccessPopup: (message) => dispatch(showSuccessPopup(message)),
        localReportScore: (id, score) => dispatch(reportScore(id, score)),
        localSignup: (id, special) => dispatch(signup(id, special)),
        localGoToRegister: () => dispatch(goToRegister()),
    };
}

const getActiveChallengeUserInfo = (state, challengeId) => {
    const userId = state.userReducer.user.id;
    const challenge = state.challengeReducer.activeChallenges.find(x => x.id === challengeId);
  
    if (challenge === undefined)
        return undefined;
   return challenge.participants.find(item => item.userId === userId);
}


const lobby = connect(mapStateToProps, mapDispatchToProps)(Lobby);
export { lobby as Lobby }; 