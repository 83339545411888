import css from './footer.module.scss';

export default function Footer() {
  return (
    <footer className={css.footer}>
      Copyright 2024 -&nbsp;
      <a href='https://wgc.gg' target="_blank">
        World Golf Challenge
      </a>
    </footer>
  );
}
