import css from "./rankings.module.scss";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Hero from "./components/hero";
import PricePool from "./components/prizePool";
import Button from "./components/navigation/button";
import ContactCTA from "./components/contactCTA";
import { RankingsWithPrices } from "./components/rankings";
import CardsSection from "./components/cardsSection";
import { getExtracurricular } from "../../actions/leaderboardActions";
import { ChallengeStatus } from "../../utils/const";
import { HcpType } from "../../utils/const";
import { subscribeToChallenges } from "../../actions/challengeActions";
import { Helmet } from "react-helmet-async";
import ScrollToTop from '../../utils/scrollToTop';

export default function Rankings() {

    const dispatch = useDispatch();

    const extracurricular = useSelector(state => state.leaderboardReducer.leaderboard.extracurricular);
    const challengeData = mapChallengeData(extracurricular);

    //Check if extracurricular challenges are finished
    let finished =[];
    let anyFinshed = false;
    let allFinshed = false;
    let noneStarted = false;
    if(extracurricular){
        finished = extracurricular.filter(challenge => challenge.status ===ChallengeStatus.DONE);
        anyFinshed = finished.length > 0;        
        allFinshed = extracurricular.length === finished.length;
        //check if all have statsus NOT_STARTED
        if(extracurricular.every(challenge => challenge.status === ChallengeStatus.NOT_STARTED)){
            noneStarted = true;
        }

    } 

    useEffect(() => {      
        dispatch(getExtracurricular());   
        dispatch(subscribeToChallenges());   
    },[dispatch])


  return (
    <>
      <Helmet>
        <title>Rankings | EXTRACURRICULAR x WGC</title>
        <meta
              name="keywords"
              content="golf, challenge, golf course, golf challenge, extracurricular"
            />
            <meta
              name="description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta name="author" content="Redox Digital" />
            <meta property="og:title" content="EXTRACURRICULAR x World Golf Challenge" />
            <meta
              property="og:description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta property="og:image" content={`https://wgc.gg/extracurricular/socials/social_thumbnail.jpg`} />
            {/*<!-- Update domain -->*/}
            <meta property="og:url" content="https://wgc.gg/extracurricular" /> {/*<!-- Update domain -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="EXTRACURRICULAR x World Golf Challenge" />
            <meta
              name="twitter:description"
              content="Playing golf physically while competing digitally, Extracurricular Prices to reward you!"
            />
            <meta name="twitter:image" content={`https://wgc.gg/extracurricular/socials/social_thumbnail.jpg`} />
            <link rel="icon" href="/images/extracurricular/logos/favicon.svg" type="image/svg+xml" />
      </Helmet>
      <ScrollToTop />
      <Hero
        title={greetings(noneStarted,anyFinshed)}
        subtitle={""}
        source={"/images/extracurricular/layouts/rankings_hero.webp"}
        opacity={0.3}
      />
      <main>
        <section className={css.results}>
          <div className={css.titles}>
            <h2>The results</h2>
            <p>{resultText(noneStarted,anyFinshed)}</p>
          </div>
          <div className={css.tables}>
            <RankingsWithPrices  challenge={challengeData.single} />
            <RankingsWithPrices  challenge={challengeData.hcp1_18}/>
            <RankingsWithPrices  challenge={challengeData.hcp19_36}/>
          </div>
          
        </section>
        <CardsSection
          title={'Redeem your prize'}
          desc={''}
          cards={[
            {
              icon: '/images/extracurricular/pictograms/golf-white.svg',
              title: 'Net Stableford, 18 holes',
              desc: 'Pick any course in the world & adjust your strokes. Only Net Stableford counts.',
            },
            {
              icon: '/images/extracurricular/pictograms/genders.svg',
              title: 'Only HCP matters',
              desc: 'Gender and age mixed into 3 different categories : Single / HCP 10 to 18 / HCP +18',
            },
            {
              icon: '/images/extracurricular/pictograms/gift.svg',
              title: 'A. lot. of. prizes.',
              desc: 'Extracurricular Prices to reward you - we will get in touch!',
            },
          ]}
        />
         <ContactCTA
          img="/images/extracurricular/layouts/contact-cta.webp"
          title={<>Do you want more&nbsp;?</>}
          desc="More Free and Buy-in Challenges available on wgc.gg & your own Challenges with friends, clients, club members to create!"
          btnLabel="Visit wgc.gg"
          btnLink="https://wgc.gg"
        />

      </main>
    </>
  );
}

const greetings = (noneStarted,anyFinshed)=>{
    if(anyFinshed){
        return 'And the winner is...';
    }
    else if(noneStarted){
        return 'Get ready for the celebration!';
    }
    else{
        return 'Game in progress...';
    }
}
const resultText = (noneStarted,anyFinshed)=>{
    if(anyFinshed){
        return 'Congratulations for playing!';
    }
    else if(noneStarted){
        return 'Event starts soon, stay tuned!';
    }
    else{
        return 'Game in progress, good luck to all players!';
    }
}


const emptyResults = {
    single: [],
    hcp1_18: [],
    hcp19_36: [],
}

const mapChallengeData = (extracurricular) => {

    if(!extracurricular || extracurricular.length===0) return emptyResults;

    const htpSingle = extracurricular.find(challenge => challenge.hcpType === HcpType.SINGLE);
    const htpUpTo18 = extracurricular.find(challenge => challenge.hcpType === HcpType.HCP_1_18);
    const htpUpTo54 = extracurricular.find(challenge => challenge.hcpType === HcpType.HCP_19_36);

    return {
        single: htpSingle ? htpSingle : [],
        hcp1_18: htpUpTo18 ? htpUpTo18 : [],
        hcp19_36: htpUpTo54 ? htpUpTo54 : [],
    }

};

