import React from 'react'
import { Link } from 'react-router-dom';

const Faq = () => {
    return (

        <div>
            {/* <br /> */}
            <div className="container-fluid">
                {/* <h2 className="panel-title">FAQ</h2> */}
                <div className="panel faq">
                    <div className="container-fluid">
                        <div className="faq-item">
                            <h4>Why do I have to be 18 years old for money related challenges?</h4>
                            <p>Since regulations vary among countries, we have decided to limit money related challenges for 18+.</p>
                        </div>
                        <div className="faq-item">
                            <h4>How to start a Challenge?</h4>
                            <p>You need to join a Challenge in the Clubhouse and pay the entry fee if requested. Once the Challenge has started you cannot unregister to respect your opponents. Make sure you choose a Challenge in the appropriate handicap zone and on the correct day.</p>
                            <p>Buy-In and Sponsored Challenges must be played in parallel with an official round of golf (i.e., Saturday club’s tournament, etc.).</p>
                        </div>
                        <div className="faq-item">
                            <h4>What happens in case of tied scores?</h4>
                            <p>Unless indicated otherwise, prize money is divided between tied winners. Some Challenges can have the format of “Best handicap wins”.</p>
                        </div>
                        <div className="faq-item">
                            <h4>What money is given back to players?</h4>
                            <p>Please find prize money breakdown <a href="/images/prize-money-breakdown.xlsx" className="header-link" download>here</a>. WGC takes the following commission: Buy-In: 0% &lt;9 players. 20% 10-19 players. 30% &gt;19 players.</p>
                            <p>Private: 20%. Pro Challenge: 20%</p>
                        </div>
                        <div className="faq-item">
                            <h4>How do I make sure my opponents have their scores right?</h4>
                            <p>As per all golf rounds, golf is a self-referred sport and rely on players’ good faith.</p>
                            <p>Winners can be asked a proof of result by email.</p>
                        </div>
                        <div className="faq-item">
                            <h4>What are the rules of WGC?</h4>
                            <p>For Buy-In and Sponsored Challenges, as you play an official round of golf, R&amp;A and USGA rules of golf apply.</p>
                            <p>For Free Challenges, R&amp;A and USGA rules of golf apply too.</p>
                        </div>
                        <div className="faq-item">
                            <h4>How do I deposit money?</h4>
                            <p>Just head to <Link to="/deposit">deposit</Link>, for the moment we only allow payments through PayPal. You do not need a PayPal account to deposit money. You can pay with your Visa, Mastercard or American Express through PayPal >> When you are redirected from our site to PayPal chose the option to pay with your credit card.</p>
                        </div>
                        <div className="faq-item">
                            <h4>How do I withdraw my winnings?</h4>
                            <p>You can request a withdrawal <Link to="/withdraw">here</Link>, we will handle the payout through PayPal on Thursdays.</p>
                        </div>
                        <div className="faq-item">
                            <h4>Do I have to pay taxes on my winnings?</h4>
                            <p>This all depends on the laws and regulations in your country, we advise you to check with your tax agency to make sure you do right by yourself. Remember that when you signed up on wgc.gg, you agreed to the terms that you take full responsibility for paying taxes on your winnings if the law in your country requires you to do so.</p>
                        </div>
                        <div className="faq-item">
                            <h4>I've spotted a cheater! What to do?</h4>
                            <p>It’s very important that we keep wgc.gg clean from cheaters. If you spot a cheater, head to his profile and press report. Once we’ve got enough reports our anti cheat team will take care of the matter!</p>
                        </div>
                        <div id="stableford" className="faq-item">
                                <h4>Stableford</h4>
                                <p>While both Stroke Play and Stableford scoring use the concept of a net score, they apply it differently. Stroke Play adjusts the raw stroke count by subtracting the player's handicap, whereas Stableford calculates points based on performance relative to a predetermined standard for each hole.</p>
                                <p>In Stableford scoring, the points awarded for each hole are typically based on the player's score relative to par. Here's a common breakdown:</p>
                                <ul style={{color:"black"}}>
                                    <li><b>Net Double Bogey or Worse:</b> 0 points (2 or more strokes above net par)</li>
                                    <li><b>Net Bogey:</b> 1 point (1 stroke above net par)</li>
                                    <li><b>Net Par:</b> 2 points (equal to net par)</li>
                                    <li><b>Net Birdie:</b> 3 points (1 stroke below net par)</li>
                                    <li><b>Net Eagle:</b> 4 points (2 strokes below net par)</li>
                                    <li><b>Net Albatross or Better:</b> 5 points (3 strokes below net par)</li>
                                </ul>
                                <p>Net par = par + extra strokes received due to the player's handicap. </p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        
        );
}

export default Faq