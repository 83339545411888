import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector  } from 'redux-form'
import { Link } from 'react-router-dom';
import { registerUser } from "../actions/userActions";
import { renderFieldWithShake, renderPhoneField, renderFieldCheckboxWithLink, renderCountrySelectField, renderStatusSelectField } from '../utils/renderFields';
import { required, minLength8, maxLength16, email, phoneNumber, alphaNumeric, passwordsMustMatch, isdecimal } from "./validations";
//import { appendScript } from '../utils/jsLoader';

import './additional.css';

class Signup extends Component {

    constructor(props) {
        super(props);

       

        //this.handleChange = this.handleChange.bind(this);
      // this.handleFormSubmit = this.handleFormSubmit.bind(this);
       // this.checkboxValue = this.checkboxValue.bind(this);

    }

    componentDidMount() {
        //appendScript('/js/chatScript.js');
    }
  

    handleFormSubmit(values) {
        console.log("handeling registration")
        console.log(values);
        if (this.props.valid) {

            var hcp = values.handicap !== undefined ? (values.handicap + '').replace(/,/g, '.') : undefined; 

            this.props.register(values.firstName, values.lastName, values.playerType, hcp, values.countryIso, values.phoneNumber, values.username, values.password, values.confirmPassword);
        }
    }


    render() {
        const { handleSubmit, pristine, reset, submitting, valid, working, playerTypeValue, fromState } = this.props;

        return (
            <div className="register-cont">
                <div className="auth-panel">
                    <h2 className="text-center">Create your account</h2>
                    <form id="signup-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    name="firstName"
                                    type="text"
                                    component={renderFieldWithShake}
                                    label="First name"
                                    placeholder="Enter your first name"
                                    validate={[required]}
                                />
			                </div>
                            <div className="col-md-6">
                                <Field
                                    name="lastName"
                                    type="text"
                                    component={renderFieldWithShake}
                                    label="Last name"
                                    placeholder="Enter your last name"
                                    validate={[required]}
                                />
			                </div>
                        </div>
                                                                       
                        <div className="row-full">
                            <Field
                                name="playerType"
                                label="Status"
                                type="text"
                                component={renderStatusSelectField}
                                placeholder="Select status"
                                validate={[required]} />
                        </div>

                        {playerTypeValue !== undefined && playerTypeValue === 'AMATURE' ? 
                            <div className="row-full">
                                <Field
                                    name="handicap"
                                    type="text"
                                    component={renderFieldWithShake}
                                    label="Handicap"
                                    placeholder="Enter your handicap"
                                    format={(val) => val !== undefined ? (val + '').replace('-', '+') : ''}
                                    validate={[required, isdecimal]}
                                />
                            </div>
                            : null
                            
                        }

                        <div className="row-full">
                            <Field
                                name="countryIso"
                                type="text"
                                component={renderCountrySelectField}
                                label="Country"
                                placeholder="Select your country"
                                validate={[required]}
                            />
                        </div>
                        <div className="row-full">
                            <Field
                                name="username"
                                type="text"
                                component={renderFieldWithShake}
                                label="E-mail address"
                                placeholder="Enter your e-mail address"
                                validate={[required, email]}
                            />
                        </div>

                        <div className="row-full">
                            <Field
                                name="phoneNumber"
                                component={renderPhoneField}
                                label="Phone"
                                placeholder="Enter your phone number"
                                validate={[required]}
                            />
                        </div>
                        <div className="row-full">
                            <Field
                                name="password"
                                type="password"
                                component={renderFieldWithShake}
                                label="Password"
                                placeholder="Enter your password"
                                className="_lr-hide"
                                validate={[required, maxLength16, minLength8]}
                            />
                        </div>
                        <div className="row-full">
                            <Field
                                name="confirmPassword"
                                type="password"
                                component={renderFieldWithShake}
                                label="Confirm Password"
                                placeholder="Confirm your password"
                                className="_lr-hide"
                                validate={[required, passwordsMustMatch]}
                            />
                        </div>

                        {/*  <Field
                            name="phoneNumber"
                            type="text"
                            component={renderField}
                            label="Phone number"
                            placeholder="Enter your phone number"
                            validate={[required, phoneNumber]}
                        /> */}

                        {/*  <Field
                            id="over18checkboxInput"
                            name="confirmOver18"
                            type="checkbox"
                            component={renderFieldCheckbox}
                            label="I confirm that I am over 18 years of age"
                            placeholder=""
                            validate={[required]}
                        /> */}

                        <Field
                            id="privacyCheckboxInput"
                            name="privacy"
                            type="checkbox"
                            component={renderFieldCheckboxWithLink}
                            label="I agree to privacy policy."
                            placeholder=""
                            link="/privacy-policy"
                            linkText="Link to privacy policy"
                            validate={[required]}
                        />

                        <Field
                            id="termsCheckboxInput"
                            name="terms"
                            type="checkbox"
                            component={renderFieldCheckboxWithLink}
                            label="I agree to terms."
                            placeholder=""
                            link="/terms-of-agreement"
                            linkText="Link to terms of agreement"
                            validate={[required]}
                        />
                 
                        <button type="submit" name="button" disabled={!valid || pristine || submitting}> 
                            {working ? 'Working...' : 'Create account'}
                        </button>
                    </form>
                </div>
                <Link to={{ pathname: "/login", state: fromState }}>
                    <p className="text-center switch-auth">Already have an account? Sign in here</p>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const selector = formValueSelector('signup');
    const playerTypeValue = selector(state, 'playerType');

    const { working, type, message } = state.userReducer;

    const from = state.router.location.state !== undefined ? state.router.location.state.from : undefined;

    const fromState = from !== undefined ?
        { from: from }
        : undefined;

    return {
        working, type, message, playerTypeValue, fromState
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        register: (firstName, lastName, playerType, handicap, countryIso, phoneNumber, username, password, confirmPassword) => dispatch(registerUser(firstName, lastName, playerType, handicap, countryIso, phoneNumber, username, password, confirmPassword)),
    };

}



Signup = reduxForm({
    form: 'signup' // a unique name for this form
})(Signup);

Signup = connect(mapStateToProps, mapDispatchToProps)(Signup);

export default Signup

