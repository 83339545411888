import css from './contactCTA.module.scss';
import { Link } from 'react-router-dom';
import {ExternalButton } from './navigation/button';

export default function ContactCTA({ title, desc, btnLink, btnLabel, grey, img }) {
  return (
    <section className={`${css.contactCTA} ${grey && css.grey}`}>
      <div className={css.contactCTA__texts}>
        <h2>{title}</h2>
        <p>{desc}</p>
        {btnLink && (
          <ExternalButton to={btnLink} blank>
            {btnLabel || 'Learn more'}
          </ExternalButton>
        )}
      </div>
      <div className={css.contactCTA__img}>
        <img src={img} alt={''} fill />
      </div>
    </section>
  );
  }
  