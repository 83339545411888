import React from 'react';
import { connect } from 'react-redux';
import { StartChallenge } from "./startChallenge";
import { LobbyFull } from "./lobbyFull";
import { GameFinished } from "./gameFinished";
import { ChallengeResults } from "./challengeResults";
import { ChallengeStatus, ParticipantStatus } from "../../utils/const";
import { hideAnimation } from "../../actions/dialogActions";

class ChallengeAnimations extends React.Component {
    constructor(props) {
        super(props);
       
        this.onTransition = this.onTransition.bind(this);
    }

    onTransition() {
        this.props.localHideAnimation();
    }

    render() {
        const { showEvent, event, extraData } = this.props;

        {/* 
        if (event === ChallengeStatus.READY_TO_START) {
            return (
                <LobbyFull onTransition={this.onTransition} enabled={showEvent} isVisible={showEvent} />
            );
        }
        else */}
        
        if (event === ChallengeStatus.RUNNING) {
            return (
                <StartChallenge onTransition={this.onTransition} enabled={showEvent} isVisible={showEvent} />
            );
        }
        else if (event === ParticipantStatus.DONE) {
            return (
                <GameFinished onTransition={this.onTransition} enabled={showEvent} isVisible={showEvent} score={extraData} />
            );
        }
        else if (event === "RESULTS") {
            return (
                <ChallengeResults onTransition={this.onTransition} enabled={showEvent} isVisible={showEvent} result={extraData} />
            );
        }
        return null;
    }

}

function mapStateToProps(state) {

    const { showEvent, event, extraData } =  state.challengeEventReducer;

    return {
        showEvent, event, extraData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
       localHideAnimation: () => dispatch(hideAnimation())
    };
}


const challengeAnimations = connect(mapStateToProps, mapDispatchToProps)(ChallengeAnimations);
export { challengeAnimations as ChallengeAnimations }; 