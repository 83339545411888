import React from 'react'
import PropTypes from 'prop-types'
import { PayoutType } from '../utils/const';
import { showScore, showHcp,  displayCurrency } from '../utils/translate';
import { lookup } from "country-data"
import 'flag-icon-css/css/flag-icon.min.css';


const LobbyParticipant = ({ challengeStatus, challengeError, firstName, lastName, playerStatus, hcp, countryIso, profilePictureUrl, isInfluenser, index, onClick, status, strokes, points, finishedTop25, finishedTop10, finishedTop1, placement, payoutType, payout, merchandise, error, errorDescription }) => {

    var pictureUrl = typeof profilePictureUrl === 'undefined' ? '/images/profile-picture.png' : profilePictureUrl;

    var country = lookup.countries({ alpha3: countryIso })[0];

    return(
        <>
        <tr className="table-spacing"></tr>
        <tr>
            {/* <td className="count">
                {showMedalOrIndex({ index, payout, error, challengeError})}
            </td> */}
            <td className="stats user-placement">
                {/* {error === false ? '' : errorDescription} */}
                    <span className="place-stat">{showMedalOrIndex({ index, challengeStatus, placement, payout, error, challengeError})}</span>
            </td>
            <td className="challenger">
                {/* <img className="profile-picture" src={pictureUrl} alt="Profile" /> */}
                    <div className="desktop-items"  style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="profile-picture" style={{ backgroundImage: `url(${pictureUrl})` }}></div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
             
                            <span>{firstName + " " + lastName}</span>
                            {country !== undefined ?
                                <span className={"flag-icon flag-icon-" + country.alpha2.toLowerCase()} style={{ marginLeft: '5px', width:'1.0em' }} ></span>
                            : null
                                }                    
                        </div>
                    </div>
                    <div className="mobile-items">
                        <div className="profile-picture" style={{ backgroundImage: `url(${pictureUrl})` }}></div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>

                            <span>{firstName + " " + lastName}</span>
                            {country !== undefined ?
                                <span className={"flag-icon flag-icon-" + country.alpha2.toLowerCase()} style={{ marginLeft: '5px', width: '1.0em' }} ></span>
                                : null
                            }
                        </div>
                        <span className="stat-number"><small style={{ fontSize: 'small' } }>HCP: {showHcp({ playerStatus, hcp })}</small></span>
                    </div>
            </td>
            <td className="stats desktop-items">{showHcp({ playerStatus, hcp })}
            </td>
           
            <td className="stats">
                {showScore({ strokes, points })}
                {showPayout({ payoutType, payout, merchandise })}
            </td>
        </tr>
        </>
    )
}


const displayStatus = ({ status }) =>{
    switch (status) {
        case 'JOINED':
            return '';
        case 'STARTING_SCORE_COLLECTED':
            return '';
        case 'PLAYED_FINAL':
            return 'Played game!';
        default:
            return '';
    }
}



const showPayout = ({ payoutType, payout, merchandise }) => {
    if (typeof payoutType === 'undefined')
        return '';

    else if (payoutType === PayoutType.CASH) {
        return (

            typeof payout !== 'undefined' && payout !== null && payout > 0 ? <div className="winnings">{displayCurrency(payout)}</div> : ''
        );
    }
    else if (payoutType === PayoutType.MERCHANDISE) {
        return (

            typeof merchandise !== 'undefined' && merchandise !== null ? <div className="winnings">{merchandise}</div> : ''
        );
    }
    else {
        return '';
    }
}

const showMedalOrIndex = ({ index, challengeStatus, placement, payout, error, challengeError }) => {


    if (typeof payout !== 'undefined' && payout !== null && !error) {
        //Since we have payout and no error challenge is done
        if (placement === 1) {
            return <img className="medal" src="/images/WGC_gold_ball.png" alt="" />
        }
        else if (placement === 2) {
            return <img className="medal" src="/images/WGC_silver_ball.png" alt="" />
        }
        else if (placement === 3) {
            return <img className="medal" src="/images/WGC_bronze_ball.png" alt="" />
        }
        else {
            return <>
                <span className="stat-number desktop-items">{'#' + (placement)}</span>
                <span className="stat-number mobile-items">{(placement)}</span>
            </>
        }
    }
    else if (error || challengeError) {
        return <span className="stat-number">-</span>;
    }
    else {
        return <>
        <span className="stat-number desktop-items">{'#' + (index + 1)}</span>
        <span className="stat-number mobile-items">{(index + 1)}</span>
        </>
    }

}

export default LobbyParticipant
