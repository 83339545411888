export const ChallengeStatus = {
    NOT_STARTED: 'NOT_STARTED',
    READY_TO_START: 'READY_TO_START',  
    RUNNING: 'RUNNING',
    DONE: 'DONE',
    CANCELLED: 'CANCELLED',
}

export const TournamentStatus = {
    NOT_STARTED: 'NOT_STARTED',
    QUALIFIER_RUNNING: 'QUALIFIER_RUNNING',
    QUALIFIER_DONE: 'QUALIFIER_DONE',
    FINAL_RUNNING: 'FINAL_RUNNING',
    FINAL_DONE: 'FINAL_DONE',
}

export const ParticipantStatus = {
    NOT_PARTICIPATING: 'NOT_PARTICIPATING',
    JOINED: 'JOINED',
    DONE: 'DONE',
}


export const TransactionType = {
    DEPOSIT: 'DEPOSIT',
    WITHDRAWAL: 'WITHDRAWAL',
    PAYED_CHALLENGE_FEE: 'PAYED_CHALLENGE_FEE',
    REFUNDED_CHALLENGE_FEE: 'REFUNDED_CHALLENGE_FEE',
    CHALLENGE_PAYOUT: 'CHALLENGE_PAYOUT',
    COMMISSION: 'COMMISSION',
    PROMOTION: 'PROMOTION',
    LEADERBOARD_PAYOUT: 'LEADERBOARD_PAYOUT',
    DISCOUNT_CODE: 'DISCOUNT_CODE',
    CHALLENGE_GROUP_PAYOUT: 'CHALLENGE_GROUP_PAYOUT'
}

export const ChallengeType = {
    FREE: 'FREE',
    PAYED: 'PAYED',
    SPONSORED: 'SPONSORED',
    QUALIFIER: 'QUALIFIER',
    FINAL: 'FINAL'
}

export const WithdrawalStatus = {
    REQUESTED: 'REQUESTED',
    COMPLETE: 'COMPLETE',
    DENIED: 'DENIED',
    FAILED: 'FAILED'
}


export const LeaderboardWinnerType = {
    DAILY_SCORE: 'DAILY_SCORE',
    WEEKLY_SCORE: 'WEEKLY_SCORE',
    MONTHLY_SCORE: 'MONTHLY_SCORE'
}

export const DiscountCodeStatus = {
    CREATED : 'CREATED',
    DISTRIBUTED : 'DISTRIBUTED',
    USED : 'USED',
    REVOKED : 'REVOKED'
}

export const PriceType = {
    NONE: 'NONE',
    FROM_FEE: 'FROM_FEE',
    FIXED: "FIXED",
    POT: 'POT'
}

export const PayoutType = {
    CASH: 'CASH',
    MERCHANDISE: 'MERCHANDISE'
} 

export const PlayerStatus = {
    PRO: 'PRO',
    AMATURE: 'AMATURE'
} 

export const HcpType = {
    PRO: 'PRO',
    SINGLE: 'SINGLE',
    HCP_1_18: 'HCP_1_18',
    HCP_19_36: 'HCP_19_36',
    ANY: 'ANY'
}

export const GameType = {
    STABLEFORD_NET: 'STABLEFORD_NET',
    STROKE_PLAY_NET: 'STROKE_PLAY_NET',
    STABLEFORD: 'STABLEFORD',
    STROKE_PLAY: 'STROKE_PLAY'
}

export const Region = {
    EUROPE: 'EUROPE',
    AMERICAN: 'AMERICAN',
    ASIA: 'ASIA'
}

