
export function showErrorPanel(message) {
    return { type: "SHOW_PANEL", message: message, messageType: "ERROR", displayType: "STICKY" };
}

export function showInfoPanel(message) {
    return { type: "SHOW_PANEL", message: message, messageType: "INFO", displayType: "STICKY" };
}

export function flashInfoPanel(message) {
    return { type: "SHOW_PANEL", message: message, messageType: "INFO", displayType: "FLASH" };
}

export function hideInfoPanel() {
    return { type: "HIDE_PANEL" };
}