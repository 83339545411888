import React from 'react'
import { GameType } from '../utils/const';
import { showHcp, displayCurrency } from '../utils/translate'
import { PayoutType } from '../utils/const';
import { round } from '../utils/math';
import { lookup } from "country-data"
import 'flag-icon-css/css/flag-icon.min.css';

var flagStyle = { marginLeft: '5px', width: '1.0em' }

const LeaderboardParticipant = ({ firstName, lastName, countryIso, hcp, playerStatus, profilePictureUrl, score, gameType, payoutType, payout, merchandise, index, prize }) => {

    var pictureUrl = typeof profilePictureUrl === 'undefined' ? '/images/profile-picture.png' : profilePictureUrl;
    var country = lookup.countries({ alpha3: countryIso })[0];

    return (
        <tr>
            <td className="count">
                #{index + 1}
            </td>
            <td className="challenger">
                <img className="profile-picture" src={pictureUrl} alt="Profile" />
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <span>
                    {firstName + ' ' + lastName}
                </span>
                {country !== undefined ?
                    <span style={flagStyle} className={"flag-icon flag-icon-" + country.alpha2.toLowerCase()}></span>
                    : null
                    }
                 </div>
                {showHcp({ playerStatus, hcp })}
            </td>
            <td className="points">
                {score}<br /><small>{gameType === GameType.STABLEFORD_NET || gameType === GameType.STABLEFORD ? 'Points' : 'Strokes'}</small>
                {prize === undefined ? showPayout({ payoutType, payout, merchandise }) : showPayout({ payoutType: prize.payoutType, payout: prize.payout, merchandise: prize.text })}
            </td>
            <td className="stats">
                
            </td>
        </tr>
    )
}

const showPayout = ({ payoutType, payout, merchandise }) => {
    if (typeof payoutType === 'undefined')
        return '';

    else if (payoutType === PayoutType.CASH) {
        return (

            typeof payout !== 'undefined' && payout !== null && payout > 0 ? <div className="winnings">{displayCurrency(round(payout,2))}</div> : ''
        );
    }
    else if (payoutType === PayoutType.MERCHANDISE) {
        return (

            typeof merchandise !== 'undefined' && merchandise !== null ? <div className="winnings">{merchandise}</div> : ''
        );
    }
    else {
        return '';
    }
}

export default LeaderboardParticipant