import css from './button.module.scss';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Button({ to, children, wgc, white, addClass, blank, onClick }) {
  return (
    <Link
      to={to}
      className={`${css.btn} ${wgc && css.btnWgc} ${white && css.white} ${addClass}`}
      target={blank ? '_blank' : ''}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}

export function ExternalButton({ to, children, wgc, white, addClass, blank, onClick }) {
  return (
    <a
      href={to}
      className={`${css.btn} ${wgc && css.btnWgc} ${white && css.white} ${addClass}`}
      target={blank ? '_blank' : ''}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

export function HashButton({ to, children, wgc, white, addClass, blank, onClick }) {
  return (
    <HashLink smooth
      to={to}
      className={`${css.btn} ${wgc && css.btnWgc} ${white && css.white} ${addClass}`}
      target={blank ? '_blank' : ''}
      onClick={onClick}
    >
      {children}
    </HashLink>
  );
}