import Header from './components/navigation/menu';
import Footer from './components/footer';
import {Popup} from '../popup';
import { ToastContainer } from 'react-toastify';
import './excr-globals.scss';
import  ActiveChallenges  from '../activeChallenges';
import { ChallengeAnimations } from "../animations/challengeAnimations";


export default function ExtracurricularLayout({ children }) {

  return (
    <div className="excr">
      <Header />
     {/* <ActiveChallenges /> */}
      {children}
      <Footer />
      <Popup />
      <ToastContainer />        
      <ChallengeAnimations  />
    </div>
  );
}
