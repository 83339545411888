const initialState = {
    show: false,
    message: null,
    messageType: "ERROR",    //ERROR, INFO, SUCCESS
    displayType: "STICKY"   //STICKY, FLASH
};

export function infoPanelReducer(state = initialState, action) {
    switch (action.type) {
        case "SHOW_PANEL":
            return {
                show: true,
                message: action.message,
                messageType: action.messageType,
                displayType: action.displayType
            }
        case "HIDE_PANEL":
            return initialState;
        default:
            return state;
    }
}