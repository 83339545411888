import React from 'react'
import PropTypes from 'prop-types'
import Challenge from './challenge'


const ChallengeList = ({ challenges, isLoggedIn, signup, deleteChallenge , goto, color, title, goToRegister, showLink }) => (
    <div className="boxChallengeList">
        {challenges.map((challenge, index) => (
            <Challenge key={challenge.id} {...challenge} index={index} isLoggedIn={isLoggedIn} goto={() => goto(challenge.id)} signup={() => signup(challenge.id, false)} goToRegister={() => goToRegister(challenge.id)} deleteChallenge={() => deleteChallenge(challenge.id)}  color={color} title={title} showLink={showLink} privateChallenge={challenge.private} />
        ))}
    </div>
)

{/*
ChallengeList.propTypes = {
    todos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            completed: PropTypes.bool.isRequired,
            text: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    toggleTodo: PropTypes.func.isRequired
} */}

export default ChallengeList
