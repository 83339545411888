import React from 'react';
import { connect } from 'react-redux';
import { getFinishedChallenge } from "../actions/challengeActions";
import LobbyParticipant from './lobbyParticipant'
import { ChallengeStatus, Region } from '../utils/const';
import { translateHcpType, translateGameType, displayCurrency } from '../utils/translate'
import Moment from 'react-moment';
import 'moment-timezone';

class MatchHistory extends React.Component {

    componentDidMount() {

        if (this.props.match.params.challengeId) {
            console.log("ChallengeId:" + this.props.match.params.challengeId);
			this.props.localGetFinishedChallenge(this.props.match.params.challengeId);
        }
    }   

	render() {

		const { challengeInfo, region } = this.props;

		return (
                
            <div className="container-fluid">
                {challengeInfo.challenge != null ?
                    <h2 className="panel-title">{challengeInfo.challenge.name} {challengeInfo.challenge.status === ChallengeStatus.CANCELLED ? '(Cancelled)' : ''}</h2> : <h2 className="panel-title">Loading...</h2>}
                <h4>
                    {challengeInfo.challenge != null ? lobbyName(challengeInfo.challenge, region) : null}
                </h4>
				<div className="row">
					<div className="col-xl-3">
						
                        <h2 className="panel-title">&nbsp;</h2>
                        <div className="panel">
                        {challengeInfo.challenge != null ? lobbyInfo(challengeInfo.challenge) : null}
                        </div>
						
                    </div>
                    <div className="col-xl-9">
                        <h2 className="panel-title">Lobby</h2>
                        <div className="panel panel-lobby">

                            <table className="table lobby-table table-hover">
                                <thead>
                                    <tr>
                                        {/* <th className="number-head"></th> */}
                                        <th className="text-center user-placement">
                                            <span className="desktop-items">Place</span>
                                            <span className="mobile-items">#</span>
                                        </th>
                                        <th className="user-head">Player Name</th>
                                        <th className="text-center desktop-items-table">HCP</th>
                                        <th className="text-center desktop-items-table">Country</th>
                                        <th className="text-center">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {typeof challengeInfo.challenge !== 'undefined' && challengeInfo.challenge != null ? (challengeInfo.challenge.participants.map((participant, index) => (
                                        <LobbyParticipant key={participant.userId} {...participant} challengeStatus={challengeInfo.challenge.status} challengeError={challengeInfo.challenge.error} index={index} />
                                    ))) : null}
                                </tbody>
                            </table>

                        </div>
                    </div>
						{/* <div className="col-xl-6">
							<h2 className="panel-title">&nbsp;</h2>
							<div className="panel description">
							<p>{challengeInfo.personalChallengeInfo != null ? challengeInfo.personalChallengeInfo : 'Personal information of challenge...'}</p>
							</div>
						</div> */}
					</div>
				</div>
            
        );
    }
}

function lobbyName(activeChallenge, region) {
	return <div className="lobby-head">
        <div className="challenge-starttime"><span className="challenge-time-label">Started:</span> <Moment format={region === Region.EUROPE ? 'YYYY-MM-DD HH:mm' : 'LLL'}>{activeChallenge.startTime}</Moment></div>
        <div className="challenge-endtime"><span className="challenge-time-label">Ended:</span> <Moment format={region === Region.EUROPE ? 'YYYY-MM-DD HH:mm' : 'LLL'}>{activeChallenge.endTime}</Moment></div>
	</div>
}

function lobbyInfo(activeChallenge) {
    return <div className="lobby-info">
    
        <h5>Game Rules</h5>
        <div className="lobby-quickinfo">

            <div className="quickinfo-item">
                Game type<br />
                <span className="item-value">{translateGameType(activeChallenge.gameType)}</span>
            </div>
            <div className="quickinfo-item">
                Buy-in cost<br />
                <span className="item-value">{buyIn({ fee: activeChallenge.fee })}</span>
            </div>
            <div className="quickinfo-item">
                Course type<br />
                <span className="item-value">{activeChallenge.holes} holes</span>
            </div>
            <div className="quickinfo-item">
                Player count min<br />
                <span className="item-value">{activeChallenge.minParticipans}</span>
            </div>
            <div className="quickinfo-item">
                Player HCP<br />
                <span className="item-value">{translateHcpType(activeChallenge.hcpType)}</span>
            </div>
            <div className="quickinfo-item">
                Player count max<br />
                <span className="item-value">-</span>
            </div>

        </div>



        <div className="divider"></div>
        <div className="player-count">
            <h5><span>{activeChallenge.numberOfParticipants}</span><br />{(activeChallenge.numberOfParticipants === 1 ? 'player registered' : 'players registered')}</h5>
        </div>

     
        
        <div className="divider"></div>
    </div>;
}

const buyIn = ({ fee }) => {
    return fee === 0 ? 'Free' : <div>{displayCurrency(fee)}</div>;
}

function mapStateToProps(state) {
    return {
        region: state.userReducer.userInfo.region,
		challengeInfo: state.challengeReducer.challengeInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localGetFinishedChallenge: (id) => dispatch(getFinishedChallenge(id)),
      
    };
}

const matchHistory = connect(mapStateToProps, mapDispatchToProps)(MatchHistory);
export { matchHistory as MatchHistory }; 