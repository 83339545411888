import React from 'react';
import { connect } from 'react-redux';
import { hideInfoPanel } from "../actions/infoPanelActions";
import './infoBar.css';

class InfoBar extends React.Component {

    render() {
        const { show, message, messageType, displayType } = this.props;

        if (show) {
            openModal();
            if (displayType === 'FLASH') {
                setTimeout(() => { this.props.hide() }, 2000);
            }
        }
        else {
            hideModal();
        }

        return (
            <div className={(messageType==='INFO' ? 'disable-bar-info ' : '') + 'disable-bar'}>
                {message}
            </div>
        );
    }

    
   
}

const openModal = () => {
    document.body.classList.add('disabled');
}

const hideModal = () => {
    document.body.classList.remove('disabled');
}

function mapStateToProps(state) {

    const { show, message, messageType, displayType } = state.infoPanelReducer;

    return {
        show,
        message,
        messageType,
        displayType 
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        hide: () => dispatch(hideInfoPanel()),
    };

}

const infoBar = connect(mapStateToProps, mapDispatchToProps)(InfoBar);
export { infoBar as InfoBar }; 