import { currencySymbol } from "../utils/translate";

const normalizeAmount = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d.]/g, '')

    if (onlyNums === '')
        return onlyNums;
    else
        return currencySymbol() + onlyNums;
}

export default normalizeAmount