import React from 'react';
import { connect } from 'react-redux';
import { Animated } from "react-animated-css";

class StartChallenge extends React.Component {

    constructor(props) {
        super(props);
       
        this.audio = new Audio("/audio/challenge_startlobby.mp3");
        this.hide = this.hide.bind(this);
        this.divRef = React.createRef();
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       
    }

    hide() {
       // this.divRef.current.classList.remove('display');
        // setTimeout(() => { this.props.onTransition() }, 2000);
        this.audio.pause();
        this.audio.currentTime = 0;
        this.props.onTransition();
    }

    render() {   

        if (!this.props.enabled) {
            return null;
        }

        if (this.props.isVisible) {
            this.audio.play();
        }

       // setTimeout(() => { this.hide() }, 5000);

        return (
            <div ref={this.divRef} className={`lobby-trigger ${this.props.isVisible ? "display" : ""}`} id="lobbyStart">
                <div className="lobby-trigger-background">
                    <div className="container text-center">
                        <Animated animationIn="bounceInLeft" animationOut="bounceOutRight" animationInDuration={2000} animationOutDuration={2000} animationInDelay={0} animationOutDelay={0} isVisible={this.props.isVisible}>
                            <h1 className="lobby-trigger-title">The challenge has started</h1>
                            <h4 className="lobby-trigger-title">You can now register your score</h4>
                        </Animated>
                        <Animated animationIn="bounceInRight" animationOut="bounceOutLeft" animationInDuration={2000} animationOutDuration={2000} animationInDelay={0} animationOutDelay={0} isVisible={this.props.isVisible}>
                            <button type="button" className="success" onClick={this.hide} name="button">Ok</button>
                        </Animated>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function

    };
}

const startChallenge = connect(mapStateToProps, mapDispatchToProps)(StartChallenge);
export { startChallenge as StartChallenge }; 