import { createSelector } from "reselect";

const activeChallenges = (state) => state.challengeReducer.activeChallenges.filter(x => x.isParticipating);

export const getCurrentActiveChallenge = createSelector(
    activeChallenges,   
    (activeChallenges) => {

        if (activeChallenges !== undefined && activeChallenges.length > 0) {
            const sorted = activeChallenges.slice().sort((a, b) => {
            const  date1 = new Date(a.startTime);
            const date2 = new Date(b.startTime);
                return date1 - date2;
           });
               //slice will avoid sorting original array

            return sorted[0]; //return first eleemnt that should be most current active challenge
        }
        else {
            return undefined;
        }
       
       
    }

   
);