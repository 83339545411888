import { createSelector } from "reselect";
import { ChallengeStatus, GameType } from "../utils/const";

const participantsSelector = (state, challengeId) => state.challengeReducer.activeChallenges.findIndex(x => x.id === challengeId) > -1 && state.challengeReducer.activeChallenges.find(x => x.id === challengeId).participants;

export const sortSelector = (state, challengeId) => state.challengeReducer.activeChallenges.findIndex(x => x.id === challengeId) > -1 && state.challengeReducer.activeChallenges.find(x => x.id === challengeId).status;

const activeChallenge = (state, challengeId) => state.challengeReducer.activeChallenges.find(x => x.id === challengeId);

export const getSortedparticipantsCollection = createSelector(
    participantsSelector,
    sortSelector,
    activeChallenge, 
    (participantsCollection, sort, activeChallenge) => {
        console.log("Challenge status for sort=" + sort);
        if (sort === ChallengeStatus.RUNNING || sort === ChallengeStatus.DONE) {           

            const sorted = participantsCollection.sort((a, b) => {

                if (activeChallenge.gameType === GameType.STABLEFORD_NET || activeChallenge.gameType === GameType.STABLEFORD) {

                    if (a.points === undefined && b.points === undefined) {
                        return 0;
                    }
                    else if (a.points === undefined && b.points !== undefined) {
                        return 1;
                    }
                    else if (a.points !== undefined && b.points === undefined) {
                        return -1;
                    }

                    else if (a.points < b.points) {
                        return 1;
                    }
                    else if (a.points > b.points) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                }
                else {
                    if (a.strokes === undefined && b.strokes === undefined) {
                        return 0;
                    }
                    else if (a.strokes === undefined && b.strokes !== undefined) {
                        return 1;
                    }
                    else if (a.strokes !== undefined && b.strokes === undefined) {
                        return -1;
                    }

                    else if (a.strokes < b.strokes) {
                        return -1;
                    }
                    else if (a.strokes > b.strokes) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                }

               
            });

            return {
                ...activeChallenge,
                participants: sorted,   
            };
        }
        return activeChallenge;
    }

   
);