import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PlayerStatus } from '../utils/const'

class MobileMenu extends React.Component {
	render() {
		const { loggedIn, userInfo, location } = this.props;

        return (
			<div className="mobile-menu">
				<div className="login-register-buttons">
					{!loggedIn ?
						<Link to="/login">
							<div className="top-bar-auth-button login">
								Login
								</div>
						</Link> : null
					}
					{!loggedIn ?
						<Link to="/signup">
							<div className="top-bar-auth-button register">
								Register
								</div>
						</Link> : null
					}
				</div>
				{loggedIn ?
					<div className="profile">
						<Link to="/profile"><img className="picture menu-close" src={profilePictureUrl(userInfo.profilePictureUrl)} alt="" /></Link>
						<Link to="/profile"><p className="menu-close">{userInfo.firstName} {userInfo.lastName}<br /><small>Hcp: {userInfo.playerStatus === PlayerStatus.PRO ? 'PRO' : userInfo.hcp !== undefined ? round(userInfo.hcp, 1).toFixed(1) :  null}</small></p></Link>
						<div className="wallet-actions">
							<Link to="/deposit" className="wallet-action menu-item-no-styling">
								Deposit
							</Link>
							<Link to="/withdraw" className="wallet-action menu-item-no-styling">
								Widthdraw
							</Link>
							{/*	<Link to="/reedem" className="wallet-action menu-item-no-styling">
								Redeem code
							</Link>*/}
						</div>

					</div> : null
				}

				<Link to="/clubhouse" className={location.pathname === "/" ? "active" : ""}>
					<div className="menu-item">
						<div className="img-holder">
							<img src="/images/icons/clubhouse.svg" alt="Clubhouse"/>
						</div>
						Clubhouse
					</div>
				</Link>
				{/*
				<Link to="/challenges">
					<div className="menu-item">
						<div className="img-holder">
							<img src="/images/icons/challenges-icon.svg" alt="Dashboard"/>
						</div>
							Challenges
					</div>
				</Link>
				*/}
				<Link to="/leaderboard" className={location.pathname === "/leaderboard" ? "active" : ""}>
					<div className="menu-item">
						<div className="img-holder">
							<img src="/images/icons/leaderboard.svg" alt="Leaderboard"/>
						</div>
							Leaderboard
					</div>
				</Link>
				{loggedIn ?
					<Link to="/wallet" className={location.pathname === "/wallet" ? "active" : ""}>
						<div className="menu-item">
							<div className="img-holder">
								<img src="/images/icons/wallet.svg" alt="Wallet" />
							</div>
							Wallet
						</div>
					</Link> : null
				}
				{loggedIn ?
					<Link to="/settings" className={location.pathname === "/settings" ? "active" : ""}>
					<div className="menu-item">
						<div className="img-holder">
							<img src="/images/icons/settings.svg" alt="Settings"/>
						</div>
						Settings
					</div>
					</Link> : null
				}
				<Link to="/about" className={location.pathname === "/about" ? "active" : ""}>
					<div className="menu-item">
						<div className="img-holder">
							<img src="/images/icons/about.svg" alt="About"/>
						</div>
							About
					</div>
                </Link>
				<Link to="/faq" className={location.pathname === "/faq" ? "active" : ""}>
					<div className="menu-item">
						<div className="img-holder">
							<img src="/images/icons/faq.svg" alt="Faq" />
						</div>
				                FAQ
			            </div>
				</Link>
				{loggedIn ?
					<Link to="/logout" className={location.pathname === "/logout" ? "active" : ""}>
						<div className="menu-item">
							<div className="img-holder">
								<img src="/images/icons/logout.svg" alt="Logout" />
							</div>
				            Sign out
			            </div>
					</Link>
					: null
				}
				<div className="social-links">
					<Link to={{ pathname: "https://www.instagram.com/worldgolfchallenge/"}} target="_blank">
                        <img src="/images/icons/instagram-icon.svg" alt="WGC Instagram" />
					</Link>
					<Link to={{ pathname: "https://www.facebook.com/worldgolfchallenge" }} target="_blank">
                        <img src="/images/icons/facebook-icon.svg" alt="WGC Facebook" />
					</Link>
					<Link to={{ pathname: "https://www.linkedin.com/company/worldgolfchallenge/about/" }} target="_blank">
                        <img src="/images/icons/linkedin-icon.svg" alt="WGC LinkedIn" />
					</Link>
					<Link to={{ pathname: "mailto:hello@wgc.gg" }} target="_blank">
                        <img src="/images/icons/email-icon.svg" alt="WGC Mail" />
					</Link>
                </div>
                <div className="important-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-of-agreement">Terms of agreement</Link>
                </div>
				<div className="menu-space"></div>
				
			</div>
        );
    }
}
// function renderCurrentChallenge(index, activeChallenge) {
//     return <Link key={index } to={'/lobby/' + activeChallenge.id}>
//                 <div className="current-lobby-holder">
//                     <h3>
//                         <small>Your Challenges</small>
//                         <br />
//                 {activeChallenge.name}<br/>  <Moment locale="en" date={activeChallenge.startTime} format="MMMM Do" />
// 		            </h3>
//                     <div className="player-count">
//                     {activeChallenge.numberOfParticipants} {(activeChallenge.numberOfParticipants === 1 ? 'player' : 'players')} registered
//                     </div>
//                     <p>Go to lobby</p>
//                 </div>
//         </Link>;
// }
function round(value, precision) {
	var multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
}

function profilePictureUrl(url) {
	var newUrl = typeof url === 'undefined' ? '/images/profile-picture.png' : url;
	return newUrl;
}

function mapStateToProps(state) {
	const { loggedIn, userInfo } = state.userReducer;

	return {
		loggedIn,
		userInfo
    };
}

const mobileMenu = withRouter(connect(mapStateToProps)(MobileMenu));
export { mobileMenu as MobileMenu }; 