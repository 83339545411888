import React from 'react';
import './dropZone.css'

class Dropzone extends React.Component {

    constructor(props) {
        super(props)

        this.state = { hightlight: false };

        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded(evt) {
        if (this.props.disabled) return;
        const files = evt.target.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }

    fileListToArray(list) {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    }

    onDragOver(evt) {
        evt.preventDefault()

        if (this.props.disabled) return

        this.setState({ hightlight: true })
    }

    onDragLeave() {
        this.setState({ hightlight: false })
    }

    onDrop(event) {
        event.preventDefault()

        if (this.props.disabled) return

        const files = event.dataTransfer.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        this.setState({ hightlight: false })
    }

    render() {
        return (
            <div className={`profile-picture ${this.state.hightlight ? "highlight" : ""}`}
                onClick={this.openFileDialog}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                style={{ cursor: this.props.disabled ? "default" : "pointer", backgroundImage: "url(" +this.props.pictureUrl+ ")" }}>

                <div className="overlay">
                    <p>Change picture</p>
                </div>
                <input
                    ref={this.fileInputRef}
                    className="fileInput"
                    type="file"
                    onChange={this.onFilesAdded}
                />
            </div>
        );
    }
}

export default Dropzone