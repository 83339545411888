import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateRegion } from "../actions/userActions";
import { showHcp } from '../utils/translate'
import { Region } from '../utils/const'
import { displayCurrency } from "../utils/translate";

class TopBar extends React.Component {

    constructor(props) {
        super(props);
      
        this.onChange = this.onChange.bind(this);
    }


    onChange(event){
        console.log(event.target.value);
        this.props.localUpdateRegion(event.target.value);
    }
    
    render() {

        const { loggedIn, userInfo } = this.props;
        

        return (
            <div className="topbar">               

                <div className="desktop-items">

                    {!loggedIn ?
                        <Link to="/login">
                            <div className="top-bar-auth-button login">
                                Login
                            </div>
                        </Link> : null
                    }
                    {!loggedIn ?
                        <Link to="/signup">
                            <div className="top-bar-auth-button register">
                                Register
                             </div>
                        </Link> : null
                    }

                    {loggedIn ?
                        <div className="currency-controller">

                            <div className="wallet">
                                {/* <img src="/images/currency.svg" alt="Currency" /> */}
                                {loggedIn ? displayCurrency(userInfo.amount) : 0}
                            </div>


                            <Link to="/deposit">
                                <div className="wallet-action">
                                    Deposit
		                        </div>
                            </Link>

                            <Link to="/withdraw">
                                <div className="wallet-action">
                                    Withdraw
                                </div>
                            </Link>
                            {/*
                            <Link to="/redeem">
                                <div className="wallet-action">
                                    Redeem code
				                </div>
                            </Link>
                        */} 
                        </div> : null
                    }
                   
                    <div className="currency-controller">
                        <select className="region-select" value={userInfo.region} onChange={this.onChange}>
                            <option className="region-select-region" value={Region.EUROPE}>{Region.EUROPE}</option>
                            <option className="region-select-region" value={Region.AMERICAN}>AMERICAS</option>
                            <option className="region-select-region" value={Region.ASIA}>{Region.ASIA}</option>
                        </select>
                    </div>
                 
                    {loggedIn ?
                        <Link to="/profile">
                            <div className="account-holder">
                                <h3>
                                    {userInfo.firstName} {userInfo.lastName}
                                    <br />
                                    <small>HCP: {showHcp({ playerStatus: userInfo.playerStatus, hcp: userInfo.hcp })}</small>
                                </h3>

                                <img className="profile" src={profilePictureUrl(userInfo.profilePictureUrl)} alt="profile" />

                            </div>
                        </Link> : null
                   }
                </div>
                <div className="mobile-items">
                    {loggedIn ?
                        <div className="currency-controller">
                            <div className="wallet">
                                {displayCurrency(userInfo.amount)}
                            </div>
                        </div> : null
                    }

                    <Link to="/">
                        <img src="/images/wgc-logo.png" className="mobile-logo" alt=""/>
		            </Link>

                    <div className="menu-open">
                        <img className="open" src="/images/mobile-menu-open.svg" alt="Open menu"/>
                        <img className="close" src="/images/mobile-menu-close.svg" alt="Close menu"/>
		            </div>
	            </div>
            </div>
        );
    }
}

function profilePictureUrl(url) {
    var newUrl = typeof url === 'undefined' ? '/images/profile-picture.png' : url;
    return newUrl;
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localUpdateRegion: (region) => dispatch(updateRegion(region)),
    };
}

function mapStateToProps(state) {
    const { loggedIn, userInfo } = state.userReducer;
    return {
        loggedIn,
        userInfo
    };
}

const topBar = connect(mapStateToProps, mapDispatchToProps)(TopBar);
export { topBar as TopBar }; 