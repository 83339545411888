import React, { Component } from "react";
import { connect } from "react-redux";
import { displayCurrency } from "../../utils/translate";

class DepositDone extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <br />
                <div className="container" >
                    <h2 className="panel-title">You have deposited {displayCurrency(this.props.location.state.amount)} to your account. Your new total is {displayCurrency(this.props.location.state.totalAmount)}</h2>

                </div>
            </div>
        );
    }


}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
    };

}

const depositDone = connect(mapStateToProps, mapDispatchToProps)(DepositDone);
export { depositDone as DepositDone }