import React from 'react';
import { connect } from 'react-redux';
import { withdraw } from "../../actions/userActions";
import { Field, reduxForm } from 'redux-form'
import { required, email } from "../validations";
import { renderFieldWithShake } from "../../utils/renderFields";
import normalizeAmount from "../../utils/inputNormalizer";
import { currencySymbol, displayCurrency } from "../../utils/translate";

var panelStyle = { padding: '20px' }

class Withdraw extends React.Component {

    handleFormSubmit(values) {
        if (this.props.valid) {

            var amount = values.amount.replace(currencySymbol(),"");

            this.props.withdraw(
                amount,
                values.email
            );
        }
    }
    render() {
        const { handleSubmit, pristine, reset, submitting, valid, working } = this.props;

        return (

            <div>
                <br />
                <div className="container container-left">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel settings-form deposit" style={panelStyle}>
                                <img className="deposit-hero" src="images/withdraw-image.jpg" alt="WGC Withdraw" />
                                <form id="withdraw-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Field
                                                name="amount"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="Amount to withdraw"
                                                placeholder={` e.g. ${displayCurrency(100)}`}
                                                validate={[required]}
                                                normalize={normalizeAmount}
                                            />
					                    </div>
                                        <div className="col-md-12">
                                            <Field
                                                name="email"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="Paypal email"
                                                placeholder="Enter your paypal email"
                                                validate={[required, email]}
                                            />
					                     </div>
                                     </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className={'button warning' + (!valid || pristine || submitting ? ' disabledBtn' : '')} name="button" disabled={!valid || pristine || submitting}>
                                                {working ? 'Working...' : 'Request withdrawal'}
                                            </button>
                                        </div>
                                    </div>
                                 </form>
                            </div>
                        </div>
	                </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { working, type, message } = state.userReducer;
    return {
        working, type, message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        withdraw: (amount,email) => dispatch(withdraw(amount,email)),
    };

}

Withdraw = reduxForm({
    form: 'withdraw'
})(Withdraw);

Withdraw = connect(mapStateToProps, mapDispatchToProps)(Withdraw);

export default Withdraw;