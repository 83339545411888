import React from 'react'
import { Link } from 'react-router-dom';
import { ParticipantStatus, ChallengeStatus } from '../utils/const'
import { translateChallengeType, translateGameType, showScore } from '../utils/translate'
import { localeDateFormat } from '../utils/datetime'

const MatchHistoryEntry = ({ challengeId, challengeType, gameType, challengeStatus, challengeError, datePlayed, participantStatus, participantError, strokes, points, placement }) => (
    <>
    <tr className="table-spacing"></tr>
    <tr>
        <td className="count">
            {placementFormat(challengeStatus, challengeError, participantStatus, participantError, placement)}
        </td>
        <td className="points text-left">
            {showScore({ strokes, points })}
        </td>
        <td className="desktop-items-table">
            {translateGameType(gameType)}
        </td>
        <td className="desktop-items-table">
            #{challengeId}
        </td>
    
        <td>
            {localeDateFormat(datePlayed)}
        </td>
       
        <td className="text-right">
            <Link className="table-button" to={'/challenge/' + challengeId}>
                View
            </Link>
       </td>
    </tr>
    </>
)



function placementFormat(challengeStatus, challengeError, participantStatus, participantError, placement) {
    if (!challengeError && !participantError && challengeStatus === ChallengeStatus.DONE && participantStatus === ParticipantStatus.DONE) {
        if (placement === 1) {
            return <img className="medal" src="/images/WGC_gold_ball.png" alt="" />
        }
        else if (placement === 2) {
            return <img className="medal" src="/images/WGC_silver_ball.png" alt="" />
        }
        else if (placement === 3) {
            return <img className="medal" src="/images/WGC_bronze_ball.png" alt="" />
        }
        else {
            return "#" + placement;
        }
    }
    else {
        return "-";
    }
}

export default MatchHistoryEntry