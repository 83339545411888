const prod = {
    url: {
        BASE_URL: "https://wgc.gg",
    },
    logrocket: {
        key: "i4mmqc/wgc-prod"
    },
    ga: {
        TRACKING_ID: "UA-208807308-1"
    }
};

const stage = {
    url: {
        BASE_URL: "https://worldgolfchallenge-stage.azurewebsites.net",
    },
    logrocket: {
        key: "i4mmqc/wgc-stage"
    },
    ga: {
        TRACKING_ID: "UA-208807308-2"
    }
};
const test = {
    url: {
        BASE_URL: "https://worldgolfchallenge-test.azurewebsites.net",
    },
    logrocket: {
        key: "i4mmqc/wgc-test"
    },
    ga: {
        TRACKING_ID: "UA-208807308-2"
    }
};


const dev = {
        url: {
        BASE_URL: "https://localhost:44396",
    },
    logrocket: {
        key: "i4mmqc/wgc-dev"
    },
    ga: {
        TRACKING_ID: "UA-208807308-2"
    }
};

export const config = process.env.NODE_ENV === "development" ? (process.env.REACT_APP_ENV === "test" ? test : dev) : (process.env.REACT_APP_ENV === "stage" ? stage : (process.env.REACT_APP_ENV === "test" ? test : prod));
