import React from 'react';
import { connect } from 'react-redux';
import { depositStripe, depositPayPal } from "../../actions/userActions";
import { currencySymbol, displayCurrency } from "../../utils/translate";

var panelStyle = { padding: '20px'}

class Deposit extends React.Component {
    constructor(props) {
        super(props);
		this.state = {
			amount: '',
			amountToDeposit: 0,
			transactionFee: 0,
			transactionFeePercentage: this.props.countryIso === 'USA' ? 3.5: 3.5,
			loadingStripe: false,
			loadingPayPal: false,
			amountOk: false,
			error: false,
			over18: false
		};


		this.onDepositStripe = this.onDepositStripe.bind(this);
		this.onDepositPayPal = this.onDepositPayPal.bind(this);
		this.onChange = this.onChange.bind(this);
    }

	onChange(e) {

		console.log(e.target.id);

		if (e.target.validity.valid) {

			//console.log("CountryCode:" + this.props.countryIso);
			
			if (e.target.id === 'amountToDeposit') {
				if (e.target.value === '') {
					this.setState({ amountToDeposit: 0, transactionFee: 0, amount: '', error: false, amountOk: false });
				}
				else if (isNormalInteger(e.target.value)) {
					var amount = Number(e.target.value);
					if (amount > 500) {
						this.setState({ error: true, amountOk: false });
					}
					else {
						this.setState({ error: false });
						if (amount >= 10) {
							this.setState({ amountOk: true });
						}
						else {
							this.setState({ amountOk: false });
						}
					}

					var percentage = 3.5;
					if (this.props.countryIso === 'USA') {
						if (amount > 50)
							percentage = 3.5;
						else
							percentage = 3.5;
					}
					else {
						if (amount > 50)
							percentage = 3.5;
						else
							percentage = 3.5;
					}
					this.setState({ transactionFeePercentage: percentage });
					var transactionFee = amount * (percentage / 100);

					this.setState({ amountToDeposit: amount, transactionFee: transactionFee, amount: amount });

				}
			}
			else if (e.target.id === 'over18checkboxInputDeposit') {
				console.log(e.target.checked);
				if (e.target.checked) {
					this.setState({ over18: true });
				}
				else {
					this.setState({ over18: false });
                }
			}
			else {
				console.log("Incorrect id");
            }
		}
		
	}
	onDepositStripe() {

		var amount = +(this.state.amountToDeposit).toFixed(2);
		var transactionFee = +(this.state.transactionFee).toFixed(2);

		if (amount >= 10 && amount <= 500) {
			this.setState({ loadingStripe: true });
			this.props.localDepositStripe(amount, transactionFee);
		}
		else {
			this.setState({ error: true });
		}
	}

	onDepositPayPal() {

		var amount = +(this.state.amountToDeposit).toFixed(2);
		var transactionFee = +(this.state.transactionFee).toFixed(2);

		if (amount >= 10 && amount <= 500) {
			this.setState({ loadingPayPal: true });
			this.props.localDepositPayPal(amount, transactionFee);
		}
		else {
			this.setState({ error: true });
		}
	}

    render() {

		return (
			<div>
				<br/>
				<div className="container container-left" >
					<div className="row" >
						<div className="col-md-12" >
							<div className="panel settings-form deposit" style={panelStyle}>
								<img className="deposit-hero" src="images/deposit-image.jpg" alt="WGC Deposit"/>
								<div className="row">
									<div className="col-md-12">
										<label htmlFor="">Deposit amount</label>
										<input id="amountToDeposit" type="text" name="depositAmount" className={this.state.error ? 'error animated shake' : ''} placeholder={`Enter amount to deposit (Min ${displayCurrency(10)} - Max ${displayCurrency(500)})`} value={this.state.amount} onChange={this.onChange} />
										<label className="errorLabel">{this.state.error ? `Min  ${displayCurrency(10)} - Max  ${displayCurrency(500)}` : ''}</label>
									</div>
									<div className="col-md-12">
										<br />
										
										<div className="checkbox">
											<input name="confirmOver18" type="checkbox" id="over18checkboxInputDeposit" value="" onChange={this.onChange} />
											<label htmlFor="" id="over18checkboxLabelDeposit">I confirm that I am over 18 years of age</label>
										</div>
		
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<table className="table lobby-table table-hover deposit table-deposit">
											<tbody>
												<tr>
													<td className="deposit-description">Amount to deposit</td>
													<td className="deposit-amount">{displayCurrency(this.state.amountToDeposit)}</td>
												</tr>
												<tr>
													<td className="deposit-description">Provider fee ({this.state.transactionFeePercentage}%)</td>
													<td className="deposit-amount">{currencySymbol()}{+(this.state.transactionFee).toFixed(2)}</td>{/* The + converts to number */}
												</tr>
												<tr>
													<td className="deposit-description">Subtotal</td>
													<td className="deposit-amount">{currencySymbol()}{+(this.state.amountToDeposit + this.state.transactionFee).toFixed(2)}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="col-md-12 text-center">
										<h2 className="total-cost">
											<small>Total cost</small>
											<br/>
											{currencySymbol()}{+(this.state.amountToDeposit + this.state.transactionFee).toFixed(2)}
										</h2>
										{/* 
										<button type="button" className={'button warning' + (this.state.loadingStripe || !this.state.amountOk ? ' disabledBtn' : '')} name="button" disabled={this.state.loadingStripe || !this.state.amountOk || !this.state.over18} onClick={this.onDepositStripe}> {this.state.loadingStripe ? 'Working...' : 'Pay with Stripe'}</button> 
										<br /><br />*/}
										<button type="button" className={'button warning' + (this.state.loadingPayPal || !this.state.amountOk ? ' disabledBtn' : '')} name="button" disabled={this.state.loadingPayPal || !this.state.amountOk || !this.state.over18} onClick={this.onDepositPayPal}> {this.state.loadingPayPal ? 'Working...' : 'Pay with PayPal'}</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

function isNormalInteger(str) {
	var n = Math.floor(Number(str));
	return n !== Infinity && String(n) === str && n >= 0;
}

function mapStateToProps(state) {

    return {
		countryIso: state.userReducer.userInfo.countryIso,
    };
}

function mapDispatchToProps(dispatch) {
    return {
		localDepositStripe: (amount, transactionFee) => dispatch(depositStripe(amount, transactionFee)),
		localDepositPayPal: (amount, transactionFee) => dispatch(depositPayPal(amount, transactionFee)),
    };
}

const depositPage = connect(mapStateToProps, mapDispatchToProps)(Deposit);
export { depositPage as Deposit }; 