import React from 'react';
import { connect } from 'react-redux';
import { getPersonalPosition } from "../actions/leaderboardActions";
import { getMatchesPlayed, uploadProfilePicture } from "../actions/userActions";
import MatchHistoryTable from './matchHistoryTable';
import Dropzone from './dropZone';
import { showSingle, showHcp18, showHcp54 } from '../utils/hcp';

var panelStyle = { paddingLeft: '20px', paddingRight: '20px'}

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        //this.uploadFiles = this.uploadFiles.bind(this);
       // this.sendRequest = this.sendRequest.bind(this);
        //this.renderActions = this.renderActions.bind(this);
    }

    componentDidMount() {
        // calling the new action creator
        this.props.localGetPersonalPosition();
        this.props.localGetMatchesPlayed();
    }

    onFilesAdded(files) {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }));
        console.log(files);
        console.log(files[0]);
        this.props.localUploadProfilePicture(files[0]);
    }

    render() {
        const { user, leaderboard, matchesPlayed } = this.props;
        var since = new Date(user.created);
        var url = typeof user.profilePictureUrl === 'undefined' ? '/images/profile-picture.png' : user.profilePictureUrl;

        return (
            <div className="container-fluid" >
                <h2 className="panel-title">Profile</h2>
                <div className="profile-container">
                   
                    <Dropzone onFilesAdded={this.onFilesAdded} pictureUrl={url}
                        disabled={this.state.uploading || this.state.successfullUploaded} />
                
                    <h1>
                        {user.firstName + " " + user.lastName} 
                        <br />
                        <small>Member since:  {since.toLocaleDateString()}</small>
                    </h1>
                </div>
                <h2 className="panel-title">Statistics</h2>
                <div className="panel mini panel-leaderboard-stats">
                    <div className="row">
                        {/*
                                        {showSingle(user) ?
                                        <div className="col-lg-4 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Your monthly Hcp Single Stroke Play position</h4>
                                                <h2 className="current-position">#{leaderboard.monthlySingleStrokePlayPosition}</h2>
                                            </div>
                                        </div> : null}
                                        {showSingle(user) ?
                                        <div className="col-lg-4 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Your monthly Hcp Single Stroke Play score</h4>
                                                <h2 className="current-position">{leaderboard.monthlySingleStrokePlayScore}</h2>
                                            </div>
                                        </div> : null} */}


                       


                        {showSingle(user) ?
                            <div className="col-lg-6 text-center">
                                <div className="col-inner">
                                    <h4 className="panel-title">Stats - Monthly HCP Single Buy-in/Sponsored</h4>
                                    <table className="monthly-stats-table">
                                        <thead>
                                            <tr>
                                                <th>Your position</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="current-position">#{leaderboard.monthlySingleStablefordPayedSponsoredPosition}</td>
                                                <td className="current-position">{leaderboard.monthlySingleStablefordPayedSponsoredScore}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div> : null}

                        {showSingle(user) ?
                            <div className="col-lg-6 text-center">
                                <div className="col-inner">
                                    <h4 className="panel-title">Stats - Monthly HCP Single Free</h4>
                                    <table className="monthly-stats-table">
                                        <thead>
                                            <tr>
                                                <th>Your position</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="current-position">#{leaderboard.monthlySingleStablefordFreePosition}</td>
                                                <td className="current-position">{leaderboard.monthlySingleStablefordFreeScore}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : null}



                        {showHcp18(user) ?
                            <div className="col-lg-6 text-center">
                                <div className="col-inner">
                                    <h4 className="panel-title">Stats - Monthly HCP up to 18 Buy-in/Sponsored</h4>
                                    <table className="monthly-stats-table">
                                        <thead>
                                            <tr>
                                                <th>Your position</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="current-position">#{leaderboard.monthly1_18PayedSponsoredPosition}</td>
                                            <td className="current-position">{leaderboard.monthly1_18PayedSponsoredScore}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : null}


                        {showHcp18(user) ?
                            <div className="col-lg-6 text-center">
                                <div className="col-inner">
                                    <h4 className="panel-title">Stats - Monthly HCP up to 18 Free</h4>
                                    <table className="monthly-stats-table">
                                        <thead>
                                            <tr>
                                                <th>Your position</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="current-position">#{leaderboard.monthly1_18FreePosition}</td>
                                                <td className="current-position">{leaderboard.monthly1_18FreeScore}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : null}

                       
                        {showHcp54(user) ?
                            <div className="col-lg-6 text-center">
                                <div className="col-inner">
                                    <h4 className="panel-title">Stats - Monthly HCP up to 54 Buy-in/Sponsored</h4>
                                    <table className="monthly-stats-table">
                                        <thead>
                                            <tr>
                                                <th>Your position</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="current-position">#{leaderboard.monthly19_36PayedSponsoredPosition}</td>
                                                <td className="current-position">{leaderboard.monthly19_36PayedSponsoredScore}</td>
                                            </tr>
                                        </tbody>     
                                    </table>
                                </div>
                            </div> : null}

                        {showHcp54(user) ?
                            <div className="col-lg-6 text-center">
                                <div className="col-inner">
                                    <h4 className="panel-title">Stats - Monthly HCP up to 54 Free</h4>
                                    <table className="monthly-stats-table">
                                        <thead>
                                            <tr>
                                                <th>Your position</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="current-position">#{leaderboard.monthly19_36FreePosition}</td>
                                                <td className="current-position">{leaderboard.monthly19_36FreeScore}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : null}


                    </div>

                </div>
                <h2 className="panel-title">Challenge history</h2>
                <div className="panel panel-lobby">
                    <MatchHistoryTable matches={matchesPlayed} />
	            </div>
            </div>
       );
    }
}

function mapStateToProps(state) {
    const { userInfo } = state.userReducer;

    return {
        user : userInfo,
        leaderboard: state.leaderboardReducer.leaderboard,
        matchesPlayed: state.userReducer.matchesPlayed
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localGetPersonalPosition: () => dispatch(getPersonalPosition()),
        localGetMatchesPlayed: () => dispatch(getMatchesPlayed()),
        localUploadProfilePicture: (file) => dispatch(uploadProfilePicture(file)),
    };
}

const profile = connect(mapStateToProps, mapDispatchToProps)(Profile);
export { profile as Profile }; 