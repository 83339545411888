import React from 'react';
import { connect } from 'react-redux';
import { getLeaderboard, getStatistics } from "../actions/leaderboardActions";
import LeaderboardTable from './leaderboardTable';
import { WinnersTimer } from './winnersTimer';
import { MonthlyWinnerRenderer, DailyWinnerRenderer } from "./countdownTimer";
import TopListTable from './topListTable';
import { showSingle, showHcp18, showHcp54 } from '../utils/hcp';
import { translateGameType } from '../utils/translate';

var disclaimerStyle = { textAlign: 'center', fontSize: '0.9rem', marginTop: '0.4rem' }

class Leaderboard extends React.Component {

    componentDidMount() {
        // calling the new action creator
        this.props.localGetLeaderboard();
    }

    render() {
        const { isLoggedIn, user, leaderboard } = this.props;

        const randomImage = "/images/champange-crop.jpg";


        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <h2 className="panel-title">Leaderboard</h2> */}
                        {isLoggedIn ?
                            <div className="panel mini panel-leaderboard-stats">
                                <div className="row">
                                    {/*
                                        {showSingle(user) ?
                                        <div className="col-lg-4 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Your monthly Hcp Single Stroke Play position</h4>
                                                <h2 className="current-position">#{leaderboard.monthlySingleStrokePlayPosition}</h2>
                                            </div>
                                        </div> : null}
                                        {showSingle(user) ?
                                        <div className="col-lg-4 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Your monthly Hcp Single Stroke Play score</h4>
                                                <h2 className="current-position">{leaderboard.monthlySingleStrokePlayScore}</h2>
                                            </div>
                                        </div> : null} */}

                                    {showSingle(user) ?
                                        <div className="col-lg-6 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Monthly HCP Single Buy-in/Sponsored</h4>
                                                <table className="monthly-stats-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Your position</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="current-position">#{leaderboard.monthlySingleStablefordPayedSponsoredPosition}</td>
                                                            <td className="current-position">{leaderboard.monthlySingleStablefordPayedSponsoredScore}</td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div> : null}

                                    {showSingle(user) ?
                                        <div className="col-lg-6 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Monthly HCP Single Free</h4>
                                                <table className="monthly-stats-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Your position</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="current-position">#{leaderboard.monthlySingleStablefordFreePosition}</td>
                                                            <td className="current-position">{leaderboard.monthlySingleStablefordFreeScore}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null}


                                    {showHcp18(user) ?
                                        <div className="col-lg-6 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Monthly HCP up to 18 Buy-in/Sponsored</h4>
                                                <table className="monthly-stats-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Your position</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="current-position">#{leaderboard.monthly1_18PayedSponsoredPosition}</td>
                                                            <td className="current-position">{leaderboard.monthly1_18PayedSponsoredScore}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null}


                                    {showHcp18(user) ?
                                        <div className="col-lg-6 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Monthly HCP up to 18 Free</h4>
                                                <table className="monthly-stats-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Your position</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="current-position">#{leaderboard.monthly1_18FreePosition}</td>
                                                            <td className="current-position">{leaderboard.monthly1_18FreeScore}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null}

                                    {showHcp54(user) ?
                                        <div className="col-lg-6 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Monthly HCP up to 54 Payed/Sponsored</h4>
                                                <table className="monthly-stats-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Your position</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="current-position">#{leaderboard.monthly19_36PayedSponsoredPosition}</td>
                                                            <td className="current-position">{leaderboard.monthly19_36PayedSponsoredScore}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null}

                                    {showHcp54(user) ?
                                        <div className="col-lg-6 text-center">
                                            <div className="col-inner">
                                                <h4 className="panel-title">Monthly HCP up to 54 Free</h4>
                                                <table className="monthly-stats-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Your position</th>
                                                            <th>Score</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="current-position">#{leaderboard.monthly19_36FreePosition}</td>
                                                            <td className="current-position">{leaderboard.monthly19_36FreeScore}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> : null}

                                </div>

                            </div> : null}
                    </div>
                </div>
                {/*
                <div className="row">
                    <div className="col-lg-3">
                        <div className="leaderborad-card red">
                            <h4><small>Highest K/D</small></h4>
                            <TopListTable participants={topLists.kdTopList} numericType="DECIMAL" />
			        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="leaderborad-card orange">
                            <h4><small>Highest Win %</small></h4>
                            <TopListTable participants={topLists.highestWinPercentageTopList} numericType="DECIMAL" />
			            </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="leaderborad-card purple">
                            <h4><small>Highest score/match</small></h4>
                            <TopListTable participants={topLists.highestScorePerGameTopList} numericType="INTEGER" />
			            </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="leaderborad-card green">
                            <h4><small>Highest kill count</small></h4>
                            <TopListTable participants={topLists.killsTopList} numericType="INTEGER" />
			            </div>
                    </div>
                </div> */}
                <br />
                <div className="row">
                    {leaderboard.privateLeaderboards!==undefined ? leaderboard.privateLeaderboards.map((privateleaderboard, index) => (
                        <div className="col-lg-6">
                            <div className="panel panel-leaderboard">
                                <div className="panel-inner">
                                    {privateleaderboard.bannerImageUrl !== undefined ?
                                        <div className="title-container">
                                            <img src={privateleaderboard.bannerImageUrl} alt="" style={{ width: "100%", maxWidth: '300px' }} />
                                            {/*paddingLeft: "15px", paddingRight: "15px", objectFit: 'cover', maxHeight: '150px' */}

                                        </div>
                                        : null
                                        }

                                    <div className="container-fluid">
                                        <div className="leaderboard-left">
                                            <h4 className="timer-title" style={{ padding: '5px', lineHeight:'1.2rem' }} >{privateleaderboard.name} - {privateleaderboard.bestOf} Best {translateGameType(privateleaderboard.gameType)} Scores</h4>

                                            <div className="period-leaders bg-contain" >
                                                {privateleaderboard.leaderbordPrizeImageUrl !== undefined ?
                                                    <img src={privateleaderboard.leaderbordPrizeImageUrl} style={{ width: "auto", marginTop: "15px", objectFit: 'cover', maxHeight: '280px', margin: '0 auto', display: 'flex', maxWidth: '100%' }} alt="Prizes" />
                                                    : null
                                                }
                                            </div>
                                       </div>
                                        <div className="leaderboard-right">
                                            <LeaderboardTable participants={privateleaderboard.leaderboard} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : null}
                </div>
                <div className="row">
                    {/*
                    <div className="col-lg-4">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">

                                <h4>HCP Single Stroke Play<br /><small>Monthly Leaderboard</small></h4>
                            </div>
                            <div className="container-fluid">
                                <WinnersTimer id="MonthlyWinners" winners={leaderboard.monthlySingleStrokePlayLeaderboard.slice(0, 3)} ends={leaderboard.monthlySingleStrokePlayLeaderboardEnds} prices={leaderboard.monthlySingleStrokePlayPrices} renderer={MonthlyWinnerRenderer} />
                                <LeaderboardTable participants={leaderboard.monthlySingleStrokePlayLeaderboard} />
                            </div>
                        </div>
                    </div> */}
                    {/*
                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="panel-inner">
                                <div className="title-container">
                                    <img src="/images/tournament/wgc_tournament_banner.jpg" alt="World Golf Challenge - Tournament" style={{ width: "100%", paddingLeft: "15px", paddingRight: "15px" }} />
                                </div>
                                <div className="container-fluid">
                                    <div className="leaderboard-left">
                                        <h4 className="timer-title">HCP Single - Best Net Stableford Scores</h4>
                                        <div className="period-leaders bg-contain" >
                                            <img src="/images/prizes/comboprizeimage.png" style={{ width: "100%", marginTop: "15px" }} alt="Prizes" />
                                        </div>
                                        <h4 style={disclaimerStyle}>*minimum of 10 players required</h4>
                                    </div>
                                    <div className="leaderboard-right">
                                        <LeaderboardTable participants={leaderboard.wgcTournamentSingleLeaderboard} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="panel-inner">
                                <div className="title-container">
                                    <img src="/images/tournament/wgc_tournament_banner.jpg" alt="World Golf Challenge - Tournament" style={{ width: "100%", paddingLeft: "15px", paddingRight: "15px" }} />
                                </div>
                                <div className="container-fluid">
                                    <div className="leaderboard-left">
                                        <h4 className="timer-title">HCP up to 18 - Best Net Stableford Scores</h4>
                                        <div className="period-leaders bg-contain" >
                                            <img src="/images/prizes/comboprizeimage.png" style={{ width: "100%", marginTop: "15px" }} alt="Prizes" />
                                        </div>
                                        <h4 style={disclaimerStyle}>*minimum of 10 players required</h4>
                                    </div>
                                    <div className="leaderboard-right">
                                        <LeaderboardTable participants={leaderboard.wgcTournament1_18Leaderboard} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="panel-inner">
                                <div className="title-container">
                                    <img src="/images/tournament/wgc_tournament_banner.jpg" alt="World Golf Challenge - Tournament" style={{ width: "100%", paddingLeft: "15px", paddingRight: "15px" }} />
                                </div>
                                <div className="container-fluid">
                                    <div className="leaderboard-left">
                                        <h4 className="timer-title">HCP up to 54 - Best Net Stableford Scores</h4>
                                        <div className="period-leaders bg-contain" >
                                            <img src="/images/prizes/comboprizeimage.png" style={{ width: "100%", marginTop: "15px" }} alt="Prizes" />
                                        </div>
                                        <h4 style={disclaimerStyle}>*minimum of 10 players required</h4>
                                    </div>
                                    <div className="leaderboard-right">
                                        <LeaderboardTable participants={leaderboard.wgcTournament19_36Leaderboard} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-6">
                    </div> */}
                    {/*
                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="panel-inner">
                                <div className="title-container">
                                    <img src="/images/landingpage/trackman-banner.jpg" alt="WGC Indoor Challenge" style={{width: "100%", paddingLeft: "15px", paddingRight: "15px"}} />
             
                                </div>
                                <div className="container-fluid">
                                    <div className="leaderboard-left">
                                        <h4 className="timer-title">HCP Single - 3 Best Net Stableford Scores</h4>
                                        <div className="period-leaders bg-contain" >
                                            <a target="_blank" href="https://bunker-mentality.com/" rel="noreferrer"> 
                                                <img src="/images/prizes/prizeimage.jpg" style={{ width: "100%", marginTop: "25px" }} alt="Prizes" />
                                            </a>
                                        </div>
                                      </div>
                                    <div className="leaderboard-right">
                                        <LeaderboardTable participants={leaderboard.trackmanSingleLeaderboard} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="panel-inner">
                                <div className="title-container">
                                    <img src="/images/landingpage/trackman-banner.jpg" alt="WGC Indoor Challenge" style={{width: "100%", paddingLeft: "15px", paddingRight: "15px"}} />
                         
                                </div>
                                <div className="container-fluid">
                                    <div className="leaderboard-left">
                                        <h4 className="timer-title">HCP up to 18 - 3 Best Net Stableford Scores</h4>
                                        <div className="period-leaders bg-contain" >
                                            <a target="_blank" href="https://bunker-mentality.com/" rel="noreferrer"> 
                                                <img src="/images/prizes/prizeimage.jpg" style={{ width: "100%", marginTop: "25px" }} alt="Prizes" />
                                            </a>
                                        </div>
                 
                                    </div>
                                    <div className="leaderboard-right">
                                        <LeaderboardTable participants={leaderboard.trackman1_18Leaderboard} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="panel-inner">
                                <div className="title-container">
                                    <img src="/images/landingpage/trackman-banner.jpg" alt="WGC Indoor Challenge" style={{width: "100%", paddingLeft: "15px", paddingRight: "15px"}} />
                              
                                </div>
                                <div className="container-fluid">
                                    <div className="leaderboard-left">
                                        <h4 className="timer-title">HCP up to 54 - 3 Best Net Stableford Scores</h4>
                                        <div className="period-leaders bg-contain" >
                                            <a target="_blank" href="https://bunker-mentality.com/" rel="noreferrer"> 
                                                <img src="/images/prizes/prizeimage.jpg" style={{ width: "100%", marginTop: "25px" }} alt="Prizes" />
                                            </a>
                                        </div>
               
                                    </div>
                                    <div className="leaderboard-right">
                                        <LeaderboardTable participants={leaderboard.trackman19_36Leaderboard} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    */}

                                      
                  
                  

               
                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                  <h4>Monthly Leaderboard - Buy-in/Sponsored</h4>
                            </div>
                            <div className="container-fluid">
                                <h4 className="timer-title">HCP Single - 2 Best Net Stableford Scores</h4>
                                <div className="period-leaders" style={{ backgroundImage:`url("${randomImage}")` }}>
                                    <WinnersTimer id="MonthlyWinners" winners={leaderboard.monthlySingleStablefordPayedSponsoredLeaderboard.slice(0, 3)} ends={leaderboard.monthlySingleStablefordPayedSponsoredLeaderboardEnds} prices={leaderboard.monthlySingleStablefordPayedSponsoredPrices} priceImages={"no"} renderer={MonthlyWinnerRenderer} />
                                </div>
                                <LeaderboardTable participants={leaderboard.monthlySingleStablefordPayedSponsoredLeaderboard} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                {/* <img src="/images/green-title-bg.svg" alt="Big bucks"/> */}
                                <h4>Monthly Leaderboard - Free</h4>
						    </div>
                            <div className="container-fluid">
                                <h4 className="timer-title">HCP Single - 3 Best Net Stableford Scores</h4>
                                <div className="period-leaders" style={{ backgroundImage:`url("${randomImage}")` }}>
                                    <WinnersTimer id="MonthlyWinners" winners={leaderboard.monthlySingleStablefordFreeLeaderboard.slice(0, 3)} ends={leaderboard.monthlySingleStablefordFreeLeaderboardEnds} prices={leaderboard.monthlySingleStablefordFreePrices} priceImages={"no"} renderer={MonthlyWinnerRenderer} />
                                </div>
                                <LeaderboardTable participants={leaderboard.monthlySingleStablefordFreeLeaderboard}/>
						    </div>
                        </div>
                    </div>


                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                {/* <img src="/images/red-title-bg.svg" alt="Big bucks"/> */}
                                <h4>Monthly Leaderboard - Buy-in/Sponsored</h4>
                            </div>
                            <div className="container-fluid">
                                <h4 className="timer-title">HCP up to 18 - 2 Best Net Stableford Scores</h4>
                                <div className="period-leaders" style={{ backgroundImage:`url("${randomImage}")` }}>
                                    <WinnersTimer id="WeeklyWinners" winners={leaderboard.monthly1_18PayedSponsoredLeaderboard.slice(0, 3)} ends={leaderboard.monthly1_18PayedSponsoredLeaderboardEnds} prices={leaderboard.monthly1_18PayedSponsoredPrices} priceImages={"no"} renderer={MonthlyWinnerRenderer} />
                                </div>
                                <LeaderboardTable participants={leaderboard.monthly1_18PayedSponsoredLeaderboard} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                {/* <img src="/images/red-title-bg.svg" alt="Big bucks"/> */}
                                <h4>Monthly Leaderboard - Free</h4>
						    </div>
                            <div className="container-fluid">
                                <h4 className="timer-title">HCP up to 18 - 3 Best Net Stableford Scores</h4>
                                <div className="period-leaders" style={{ backgroundImage:`url("${randomImage}")` }}>
                                    <WinnersTimer id="WeeklyWinners" winners={leaderboard.monthly1_18FreeLeaderboard.slice(0, 3)} ends={leaderboard.monthly1_18FreeLeaderboardEnds} prices={leaderboard.monthly1_18FreePrices} priceImages={"no"} renderer={MonthlyWinnerRenderer} />
                                </div>
                                <LeaderboardTable participants={leaderboard.monthly1_18FreeLeaderboard}/>
						    </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                {/* <img src="/images/purple-title-bg.svg" alt="Big bucks" /> */}
                                <h4>Monthly Leaderboard - Buy-in/Sponsored</h4>
                            </div>
                            <div className="container-fluid">
                                <h4 className="timer-title">HCP up to 54 - 2 Best Net Stableford Scores</h4>
                                <div className="period-leaders" style={{ backgroundImage:`url("${randomImage}")` }}>
                                    <WinnersTimer id="DailyWinners" winners={leaderboard.monthly19_36PayedSponsoredLeaderboard.slice(0, 3)} ends={leaderboard.monthly19_36PayedSponsoredLeaderboardEnds} prices={leaderboard.monthly19_36PayedSponsoredPrices} priceImages={"no"} renderer={MonthlyWinnerRenderer} />
                                </div>
                                <LeaderboardTable participants={leaderboard.monthly19_36PayedSponsoredLeaderboard} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                {/* <img src="/images/purple-title-bg.svg" alt="Big bucks" /> */}
                                <h4>Monthly Leaderboard - Free</h4>
                            </div>
                            <div className="container-fluid">
                                <h4 className="timer-title">HCP up to 54 - 3 Best Net Stableford Scores</h4>
                                <div className="period-leaders" style={{ backgroundImage:`url("${randomImage}")` }}>
                                    <WinnersTimer id="DailyWinners" winners={leaderboard.monthly19_36FreeLeaderboard.slice(0, 3)} ends={leaderboard.monthly19_36FreeLeaderboardEnds} prices={leaderboard.monthly19_36FreePrices} priceImages={"no"} renderer={MonthlyWinnerRenderer} />
                                </div>
                                <LeaderboardTable participants={leaderboard.monthly19_36FreeLeaderboard} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                {/* <img src="/images/purple-title-bg.svg" alt="Big bucks" /> */}
                                <h4>Most played - Buy-in/Sponsored</h4>
                            </div>
                            <div className="container-fluid">
                                <TopListTable participants={leaderboard.mostPlayedPayedSponsored} numericType="INTEGER" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="panel panel-leaderboard">
                            <div className="title-container">
                                {/* <img src="/images/purple-title-bg.svg" alt="Big bucks" /> */}
                                <h4>Most played - Free</h4>
                            </div>
                            <div className="container-fluid">
                                <TopListTable participants={leaderboard.mostPlayedFree} numericType="INTEGER" />
                            </div>
                        </div>
                    </div>


                    {/*
                    <div className="col-lg-4">
                        <div className="leaderborad-card orange">
                            <img className="profile-picture" src="/images/profile-picture.png" alt="Drac"/>
                            <h4>
                                <small>Monthly leader</small>
                                <br />
                                {leader(leaderboard.monthlyLeader)}
	                        </h4>
                        </div>
                        <div className="leaderborad-card red">
                            <img className="profile-picture" src="/images/profile-picture.png" alt="Drac"/>
                            <h4>
                                <small>Weekly leader</small>
                                <br />
                                {leader(leaderboard.weeklyLeader)}
	                        </h4>
                        </div>
                        <div className="leaderborad-card green">
                            <img className="profile-picture" src="/images/profile-picture.png" alt="Drac"/>
                            <h4>
                                <small>Rising star</small>
                                <br />

	                        </h4>
                        </div>
                        <div className="leaderborad-card purple">
                            <img className="profile-picture" src="/images/profile-picture.png" alt="Drac"/>
                            <h4>
                                <small>Longest streak</small>
                                <br />

	                        </h4>
                        </div>
                        <div className="leaderborad-card blue">
                            <img className="profile-picture" src="/images/profile-picture.png" alt="Drac"/>
                            <h4>
                                <small>Most played matches</small>
                                <br />
                                {mostPlayed(leaderboard.general.mostPlayed)}
                            </h4>
                        </div>
                    </div>
                      */}
                </div>
            </div>

        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.userReducer.userInfo,
        isLoggedIn: state.userReducer.loggedIn,
        leaderboard: state.leaderboardReducer.leaderboard,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localGetLeaderboard: () => dispatch(getLeaderboard()),
    };
}

const leaderboard = connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
export { leaderboard as Leaderboard };
