import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { signalrMiddleware } from "../signalrmiddleware/signalrconnection";
import apiSaga from "../sagas/apiSagas";
import LogRocket from 'logrocket';

export const history = createBrowserHistory();

const initialiseSagaMiddleware = createSagaMiddleware();
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer(history), storeEnhancers(applyMiddleware(routerMiddleware(history), signalrMiddleware, initialiseSagaMiddleware, LogRocket.reduxMiddleware())));

initialiseSagaMiddleware.run(apiSaga);

export default store;