import React, { Component } from "react";
import { connect } from "react-redux";
import { forgotPassword } from "../../actions/userActions";
import { Field, reduxForm } from 'redux-form'
import { required, email } from "../validations";
import { renderFieldWithShake } from "../../utils/renderFields";


class ForgotPassword extends Component {
    handleFormSubmit(values) {
        if (this.props.valid) {
            this.props.forgotPassword(
                values.email
            );
        }
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, valid, working } = this.props;

        return (
            <div class="login-cont">
                <div class="auth-panel">
            <div className="register-cont">
                <h2 className="text-center">Forgot my password</h2>
                <p className="text-center">Did you forget your password to Challenge.gg? No worries! Just enter your email reset it!</p>
                <div className="step-container">
                    <div className="step">
                        <div className="circle current">1</div>
                        <span>Enter your email</span>
                    </div>
                    <div className="step">
                        <div className="circle">2</div>
                        <span>Set new password</span>
                    </div>
                </div>
                <form id="forgotpassword-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                    <Field
                        name="email"
                        type="text"
                        component={renderFieldWithShake}
                        label="Email"
                        placeholder="Enter your Challenge.gg account email"
                        validate={[required, email]}
                    />

                
                    <button type="submit" name="button" disabled={!valid || pristine || submitting}>
                        {working ? 'Working...' : 'Send email'}
                    </button>
                </form>
            </div>
            </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { working, type, message } = state.userReducer;
    return {
        working, type, message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        forgotPassword: (email) => dispatch(forgotPassword(email)),
    };

}

ForgotPassword = reduxForm({
    form: 'forgotPassword'
})(ForgotPassword);

ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

export default ForgotPassword;