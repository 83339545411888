import css from './rankings.module.scss';
import { ParticipantStatus, PlayerStatus } from '../../../utils/const';
import { round } from '../../../utils/math';
import { translateHcpType,translateGameType,formatChallengeStartAndEndTime } from '../../../utils/translate';
import { ChallengeStatus } from '../../../utils/const';
import { lookup } from "country-data"
import 'flag-icon-css/css/flag-icon.min.css';

const players = [
    {
      name: 'J. Jaeggi',
      img: '/images/extracurricular/layouts/profile-placeholder.webp',
      hcp: 16,
      score: 257,
    },
    {
      name: 'J. Jaeggi',
      img: '/images/extracurricular/layouts/profile-placeholder.webp',
      hcp: 18,
      score: 250,
    },
    {
      name: 'J. Jaeggi',
      img: '/images/extracurricular/layouts/profile-placeholder.webp',
      hcp: 16,
      score: 245,
    },
  ];
  
  export default function Rankings({ challengeStatus, challengeError, participants, maxParticipans, viewProfile, color, title, type }) {
    return (
      <table className={css.rankings}>
        <thead>
          <tr>
            <th>Pos</th>
            <th className={css.name}>Name</th>
            <th>HCP</th>
            <th>Score</th>
          </tr>
        </thead>
  
        <tbody>
          {participants === 'undefined' ? '' : participants.map((p, key) => (
           showPlayer(p,key,challengeError)
          ))}
        </tbody>
      </table>
    );
  }

  function showPlayer(player, key,challengeError){

    var pictureUrl = player.profilePictureUrl === undefined ? '/images/profile-picture.png' : player.profilePictureUrl;
    var country = lookup.countries({ alpha3: player.countryIso })[0];
    return (
        <tr key={key}>
            <td>#{showPlacement(player,key,challengeError)}</td>
            <td className={css.profile}>
              <img src={pictureUrl} alt={''} width={50} height={50} /> 
              <span>{player.firstName + " " + player.lastName}</span>
              {country !== undefined ?
                  <span className={"flag-icon flag-icon-" + country.alpha2.toLowerCase()} style={{width:'1.0em' }} ></span>
                  : 
                  null
              }                 
            </td>

            <td className={css.hcp}>{showHcp(player.playerStatus, player.hcp)}</td>
            <td className={css.score}>{showScore(player.strokes, player.points )}</td>
        </tr>
    )
  }

  function showPlacement(player,index,challengeError){
    if(player.payout !== undefined && player.payout !== null && !player.error){
         //Since we have payout and no error challenge is done
        return player.placement+'';
    }
    else if(player.error || challengeError){
        return '-';
    }
    else{
        return (index + 1)+'';
    }
  }

  function showHcp( playerStatus, hcp )  {
    return playerStatus === PlayerStatus.PRO ? 'PRO' : (hcp !== undefined ? round(hcp, 1).toFixed(1).replace('-','+') : null);
  }

  function showScore ( strokes, points )  {

    if ( strokes !== undefined && strokes !== null) {
        return ' ' + strokes + ' strokes';
    }
    else if (points !== undefined && points !== null) {
        return ' ' + points;
    }
    else {
        return '';
    }
}

  
  export function RankingsWithPrices({challenge}) {

    let players = challenge.participants ? challenge.participants : [];

    const finished = challenge.status === ChallengeStatus.DONE;    

    return (
      <div className={css.card}>
        <div className={css.head}>
          <img src={'/images/extracurricular/logos/logo_excr_white.svg'} alt={''} width={600} height={200} />
          <h5>{translateHcpType(challenge.hcpType)}</h5>
          {/*<h3>
            EXTRACURRICULAR
            <br />- HCP Single
          </h3> */}
          <p>{formatChallengeStartAndEndTime(challenge.startTime,challenge.endTime)}</p>
          <p>
            Game type : <b>{translateGameType(challenge.gameType)}</b>
          </p>
        </div>
        <div className={css.prices}>
          <div className={css.price}>
            <h4 title="1st, winner">🥇</h4>
            <p>{players.length>0 && finished && players[0].status===ParticipantStatus.DONE ? `${players[0].firstName} ${players[0].lastName}`:''}</p>
            <img src={'/images/extracurricular/layouts/WGC-EXCR_price_1000.png'} alt={''} width={120} height={120} />
          </div>
          <div className={css.price}>
            <h4 title="2nd">🥈</h4>
            <p>{players.length>1 && finished && players[1].status===ParticipantStatus.DONE ? `${players[1].firstName} ${players[1].lastName}`:''}</p>
            <img src={'/images/extracurricular/layouts/WGC-EXCR_price_500.png'} alt={''} width={120} height={120} />
          </div>
          <div className={css.price}>
            <h4 title="3rd">🥉</h4>
            <p>{players.length>2 && finished && players[2].status===ParticipantStatus.DONE ? `${players[2].firstName} ${players[2].lastName}`:''}</p>
            <img src={'/images/extracurricular/layouts/WGC-EXCR_price_250.png'} alt={''} width={120} height={120} />
          </div>
        </div>
        <table className={css.rankings}>
          <thead>
            <tr>
              <th>Pos</th>
              <th className={css.name}>Name</th>
              <th>HCP</th>
              <th>Score</th>
            </tr>
          </thead>
          {players === undefined || !finished 
              ? 
              <tbody>
                <tr key='0'></tr>
              </tbody>
             :
            <tbody>
                {players.map((p, key) => (
                  showPlayer(p,key,challenge.challengeError)
                ))}
            </tbody>
          }
        </table>
      </div>
    );
  }
  