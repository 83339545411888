import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'query-string';
import { resetPassword } from "../../actions/userActions";
import { Field, reduxForm } from 'redux-form'
import { required, minLength8, maxLength16, alphaNumeric, passwordsMustMatch } from "../validations";
import { renderFieldWithShake } from "../../utils/renderFields";


class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            code: null
        };
    }

    componentDidMount() {

        let url = this.props.location.search;
        let params = queryString.parse(url);
        this.setState({ id: params.id, code: params.code });
         
    }

    handleFormSubmit(values) {
        if (this.props.valid) {
            this.props.resetPassword(
                this.state.id,
                this.state.code,
                values.password
            );
        }
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, valid, working } = this.props;

        return (
            <div className="register-cont">
                <h2 className="text-center">Forgot my password</h2>
                <p className="text-center">Awesome! Please enter your new password twice to set a new one!</p>
                <div className="step-container">
                    <div className="step">
                        <div className="circle">1</div>
                        <span>Enter your email</span>
                    </div>
                    <div className="step">
                        <div className="circle current">2</div>
                        <span>Set new password</span>
                    </div>
                </div>
                <form id="resetpassword-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                    <Field
                        name="password"
                        type="password"
                        component={renderFieldWithShake}
                        label="New password"
                        placeholder="Enter your new password"
                        className="_lr-hide"
                        validate={[required, maxLength16, minLength8]}
                    />

                    <Field
                        name="confirmPassword"
                        type="password"
                        component={renderFieldWithShake}
                        label="Re-enter new password"
                        placeholder="Confirm your new password"
                        className="_lr-hide"
                        validate={[required, passwordsMustMatch]}
                    />

                
                    <button type="submit" name="button" disabled={!valid || pristine || submitting}>
                        {working ? 'Working...' : 'Set new password'}
                    </button>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { working, type, message } = state.userReducer;
    return {
        working, type, message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        resetPassword: (id,code,email) => dispatch(resetPassword(id,code,email)),
    };

}

ResetPassword = reduxForm({
    form: 'resetPassword'
})(ResetPassword);

ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export default ResetPassword;