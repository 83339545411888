import React from 'react';
import { connect } from 'react-redux';
import { Animated } from "react-animated-css";
import { showScore } from '../../utils/translate';
import { PayoutType } from '../../utils/const';

class ChallengeResults extends React.Component {

    constructor(props) {
        super(props);
       
        this.audio = new Audio("/audio/challenge_startlobby.mp3");
        this.hide = this.hide.bind(this);
        this.divRef = React.createRef();
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       
    }

    hide() {
       // this.divRef.current.classList.remove('display');
        // setTimeout(() => { this.props.onTransition() }, 2000);
        this.audio.pause();
        this.audio.currentTime = 0;
        this.props.onTransition();
    }

    render() {   

        if (!this.props.enabled) {
            return null;
        }

        if (this.props.isVisible) {
            this.audio.play();
        }

       // setTimeout(() => { this.hide() }, 5000);


        const fee = this.props.result.fee;
        const results = this.props.result.results; //TO get list
        var itemsToShow=null;
        if (fee > 0) { //we have pricepool
            itemsToShow = results.filter(r => (r.payoutType === PayoutType.CASH && r.payout > 0) || (r.payoutType === PayoutType.MERCHANDISE))
        }
        else {
            itemsToShow = results.slice(0, 3);
        }

        //Filters so only participants with payout is shown
        const items = itemsToShow.map((item, index) =>           
            <Animated key={item.placement} animationIn={index % 2 === 0 ? "bounceInLeft" : "bounceInRight"} animationOut={index % 2 === 0 ? "bounceOutRight" : "bounceOutLeft"} animationInDuration={2000} animationOutDuration={2000} animationInDelay={0} animationOutDelay={0} isVisible={this.props.isVisible}>
                <div className={`placement-container ${index % 2 === 0 ? "bg1" : "bg2"}`} id="first">
                    <div className="placement">
                        #{index+1}
				</div>
                    <div className="player">
                        {item.firstName + " " + item.lastName}
				</div>
                    <div className="score">
                        {showScore({ strokes: item.strokes, points: item.points })}
                    </div>
                </div>
            </Animated>
        );

       

        return (
            <div ref={this.divRef} className={`lobby-trigger ${this.props.isVisible ? "display" : ""}`} id="lobbyFull">
                <div className="lobby-trigger-background">
                    <div className="container text-center">                      
                        <Animated animationIn="bounceInDown" animationOut="bounceOutUp" animationInDuration={2000} animationOutDuration={2000} animationInDelay={0} animationOutDelay={0} isVisible={this.props.isVisible}>
                            <h3 className="lobby-trigger-subtitle">{itemsToShow.length>0 ? 'Winners':'Sorry no winners'}</h3>
                        </Animated>

                        {items}

                        <Animated animationIn="bounceInRight" animationOut="bounceOutLeft" animationInDuration={2000} animationOutDuration={2000} animationInDelay={0} animationOutDelay={0} isVisible={this.props.isVisible}>
                            <button type="button" className="success" onClick={this.hide} name="button">Ok</button>
                        </Animated>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function

    };
}

const challengeResults = connect(mapStateToProps, mapDispatchToProps)(ChallengeResults);
export { challengeResults as ChallengeResults }; 