import React from 'react'
import { CountdownTimer, CooldownRenderer, GameRenderer } from "./countdownTimer";
import { PayoutType } from "../utils/const";

export const WinnersTimer = ({ id, winners, ends, prices, priceImages, renderer }) => {

	// const winningsArray
	// const imageArray = ["mgp.jpg", "champange-crop.jpg"];
	// const randomImage = "/images/" + imageArray[Math.floor(Math.random() * imageArray.length)];

	const tempPrices = ["TBA", "TBA", "TBA"];
	console.log("priceImages", priceImages);
	if (typeof winners === 'undefined')
		return null;
		
	return (
		<>
			<div className="leaderboard-countdown-timer">
				<h5>
					<small>Ends in</small>
					<br />
					<CountdownTimer mykey={id} started={ends} offset={0} renderer={renderer} />
				</h5>
			</div>
			<div className={`prize-image ${priceImages === "yes" ? "with-priceImage" : ""}`}>
					{priceImages === "yes" ? 
					<div className="prize-image-wrapper">
						<img src="/images/HS_eyewear.jpg" alt="HS Championship Eyewear" className="prize-image-img" />
						<div className="prize-text">
							<div className="prize-1"><p className="position-number">1</p><p className="prize-description">Free sunglasses</p></div>
							<div className="prize-1"><p className="position-number">2</p><p className="prize-description">50% discount</p></div>
							<div className="prize-1"><p className="position-number">3</p><p className="prize-description">30% discount</p></div>
							<div className="prize-1"><p className="position-number">4</p><p className="prize-description">20% discount</p></div>
						</div>
					</div>
					
					 : null }
			</div>
		</>
		);

	}
