import Swal from "sweetalert2"; 

export default function SweetAlert2({ onClose, onError, onConfirm, showLoading, show, title, background, icon, text, confirmButtonColor, confirmButtonText }) {
    console.log("Show" + show + " title=" + title + " icon=" + icon);
    const rest = {
        title: title,
        background: "#FFFFFF",
        icon: icon,
        text: text,  
        confirmButtonColor: "#8FC863",
        confirmButtonText: confirmButtonText,
        onClose: onClose,
    };

    function run() {
      //  if (!Swal.isVisible()) {
            if (show) {
                Swal.fire(rest).then(result => onConfirm && onConfirm(result)).catch(error => onError && onError(error));

                if (showLoading)
                    Swal.showLoading();
            } else {
                Swal.close();
            }
      //  }
    }

    run();
    return null;
}