import React from 'react'
import PropTypes from 'prop-types'

const NotFound = ({ }) => (

    <div>
        <br />
        <div className="container" >
            <h2 className="panel-title">Page not found</h2>

        </div>
    </div>       
)

export default NotFound