import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import { login, clear } from "../actions/userActions";
import { required, email, alphaNumeric } from "./validations";
import { renderFieldWithShake } from '../utils/renderFields';

class Login extends Component {

    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        // calling the new action creator
        this.props.localClear();

    }
       

    handleFormSubmit(values) {
        if (this.props.valid) {
            this.props.localLogin(values.username, values.password);
        }
    }


    render() {

        const { handleSubmit, pristine, reset, submitting, valid, working, fromState } = this.props;

        return (
            
            <div className="login-cont">
                <div className="auth-panel">
                    {/* <div className="title-holder">
                        CHALLENGE.GG
				    </div> */}
                    <div className="left"></div>
                    <div className="right">
                        <h2>Challenge yourself</h2>
                        <form id="login-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                            <Field
                                name="username"
                                type="text"
                                component={renderFieldWithShake}
                                label="E-mail address"
                                placeholder="Enter your e-mail address"
                                validate={[required, email]}
                            />

                            <Field
                                name="password"
                                type="password"
                                component={renderFieldWithShake}
                                label="Password"
                                placeholder="Enter your password"
                                className="_lr-hide"
                                validate={[required]}
                            />
                            <Link className="forgot-password" to="/forgot-password">Forgot your password?</Link>
                            <div className="auth-controller">
                                <button type="submit" id="submit-button" name="button" className={'submit' + (!valid || pristine || submitting ? ' disabledBtn' : '')} disabled={!valid || pristine || submitting}>
                                    <span>{working ? 'Working...' : 'Sign in'}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <Link to={{ pathname: "/signup", state: fromState }} >
                    <p className="text-center switch-auth">Dont have an account? Register here</p>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { working, type, message } = state.userReducer;
    const from = state.router.location.state!== undefined ? state.router.location.state.from : undefined;

    const fromState = from !== undefined ? 
        { from: from }
     : undefined ;

    return {
        working, type, message, fromState
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        localLogin: (username, password) => dispatch(login(username, password)),
        localClear: () => dispatch(clear())
    };

}

Login = reduxForm({
    form: 'login' // a unique name for this form
})(Login);

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login
