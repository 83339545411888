import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

class BasicSideBar extends React.Component {
    state = { isActive: false };

    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
   };
    
   

    render() {
        const isActive = this.state.isActive;

        return (
            <div className="sidebar-menu">
                <Link to="/"><img src="/images/wgc-logo.png" className="logo" alt="logo"/></Link>
                
               
                <div className="important-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-of-agreement">Terms of agreement</Link>
                    <span>This site is in no way associated or endorsed by game Publishers</span>
                </div>
                
           </div>
        )
    }
    
}

function mapStateToProps(state) {
    return {
       
    };
}

const sideBarBasic = withRouter(connect(mapStateToProps)(BasicSideBar));
export { sideBarBasic as BasicSideBar }; 