import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'query-string';
import { verifyDeposit } from "../../actions/userActions";

class DepositWait extends Component {

    constructor(props) {
        super(props);
        console.log("URL=" + props.location.state.redirectUrl);
    }

    componentDidMount() {
        window.location = this.props.location.state.redirectUrl
    }

    render() {

        return (
            <div>
                <br />
                <div className="container" >
                    <h2 className="panel-title">Please wait while you are redirected to {this.props.location.state.provider}...</h2>
                  
                </div>
            </div>
        );
    }


}

function mapStateToProps(state) {
    return {
   
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions

    };

}

const depositWait = connect(mapStateToProps, mapDispatchToProps)(DepositWait);
export { depositWait as DepositWait }; 