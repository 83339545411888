export function currentTournament(tournament) {
    return { type: "CURRENT_TOURNAMENT", tournament: tournament };
}


export function signup(id) {
    return { type: "TOURNAMENT_SIGNUP", id: id };
}

export function leave(id) {
    return { type: "TOURNAMENT_LEAVE", id: id };
}


