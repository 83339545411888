import React from 'react';
import { connect } from 'react-redux';
import { getTransactions } from "../actions/userActions";
import TransactionsTable from './transactionsTable'

var panelStyle = { paddingLeft: '20px', paddingRight: '20px' }


class Wallet extends React.Component {

    componentDidMount() {
        // calling the new action creator
        this.props.localGetTransactions();
    }

    render() {
        const { transactions } = this.props;

        return (
            <div className="container-fluid">
                {/* <h2 className="panel-title">Wallet</h2> */}
                <div className="panel panel-lobby">
                    <TransactionsTable transactions={transactions.results} />
	            </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        transactions: state.userReducer.transactions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localGetTransactions: () => dispatch(getTransactions()),
    };
}

const wallet = connect(mapStateToProps, mapDispatchToProps)(Wallet);
export { wallet as Wallet }; 