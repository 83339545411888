import { ParticipantStatus, HcpType } from '../utils/const';
import { CalculatePot, CalculatePayout } from '../utils/price'

const initialState = {
    proChallenges: [],
    isProChallengesDisabled: false,
    freeChallenges: [],
    isFreeChallengesDisabled: false,
    bigBucksChallenges: [],
    isBigBucksChallengesDisabled: false,
    majorMayhemChallenges: [],
    isMajorMayhemChallengesDisabled: false,
    specialChallenges: [],
    isSpecialChallengesDisabled: false,
    invitations: [],
    isInvitationsDisabled: false,
    yourChallenges: [],
    isYourChallengesDisabled: false,
    activeChallenges: [],
    activeChallengeStatus: 'NOT_LOADED',
    challengeInfo: {
        challenge: null,
        personalChallengeInfo: null,
    }
};

export function challengeReducer(state = initialState, action) {
    switch (action.type) {
        case "LOBBIES_INFO":
            console.log("Lobbies info recieved!!");
            return {
                ...state,
                activeChallenges: action.challenges,
                activeChallengeStatus: 'LOADED',
            };
        case "LOBBY_INFO":
            console.log("Lobby info recieved!!");

            var newCollection;    
            if (state.activeChallenges.findIndex(x => x.id === action.challenge.id) !== -1) {
                //exists
                newCollection = state.activeChallenges.map((item, index) => {
                    if (item.id === action.challenge.id) {
                        return action.challenge;
                    }
                    return item;
                });
            }
            else {
                //new
                newCollection = [...state.activeChallenges, action.challenge];
            }

            return {
                ...state,
                activeChallengeStatus: 'LOADED',
                activeChallenges: newCollection,
                
            };
        case "LOBBY_NOT_FOUND": 
            return {
                ...state,
                activeChallengeStatus: 'NOT_FOUND',
            };
        case "CLEAR_ACTIVE_LOBBY":
            const challengeIdToRemove = action.id;

            return {
                ...state,
                activeChallenges: state.activeChallenges.filter((item, index) =>
                    item.id !== challengeIdToRemove  //Remove with id
                ),
            };
        case "AVAILABLE_CHALLANGES": 
            console.log("Available challanges recieved");
            return Object.assign({}, state,
                {
                    proChallenges: action.challenges.proChallenges,
                    isProChallengesDisabled: action.challenges.isProChallengesDisabled,
                    freeChallenges: action.challenges.stokeChallenges,
                    isFreeChallengesDisabled: action.challenges.isStrokeChallengesDisabled,
                    bigBucksChallenges: action.challenges.hcp1_18Challenges,
                    isBigBucksChallengesDisabled: action.challenges.isHcp1_18ChallengesDisabled,
                    majorMayhemChallenges: action.challenges.hcp19_36Challenges,
                    isMajorMayhemChallengesDisabled: action.challenges.isHcp19_36ChallengesChallengesDisabled,
                    invitations: action.challenges.invitations,
                    yourChallenges: action.challenges.yourChallenges,
                });
        case "AVAILABLE_SPECIAL_CHALLANGES":
            return {
                ...state,
              specialChallenges: action.challenges,
            };
        case "INVITED_CHALLENGES":
            return {
                ...state,
                invitations: action.invitations,
            };
        case "PRIVATE_CHALLENGE_LOAD":

            if (state.yourChallenges === undefined || state.yourChallenges.length === 0){
                return {
                    ...state,
                    yourChallenges : [...state.yourChallenges, action.privateChallenge],
                }
            }
            else {
                return {
                    ...state,
                    yourChallenges: state.yourChallenges.map((item, index) => {
                        if (item.id === action.privateChallenge.id) {
                            return action.privateChallenge;

                        }
                        return item;
                    }),

                };
            }
        case "CHALLENGE_INFO":
            const userchallenge = action.challenge.participants.find(me => me.userId === action.userId);

            return {
                ...state,
                challengeInfo: {
                    challenge: action.challenge,
                    personalChallengeInfo: action.challenge.error ? action.challenge.errorDescription : (typeof userchallenge !== 'undefined' ? (userchallenge.error ? userchallenge.errorDescription : null) : null),
                }
            };
        case "CHALLENGE_UPDATE":
            console.log("Challenge update recieved");
            const id = action.challengeUpdate.challengeId;
            const updatedUserId = action.userId;
            console.log("Id=" + id + " UserId=" + updatedUserId);

            //If participantGroupCount i set we are updating prizepool for a group of challenges
            const updatePrizePoolForAll = action.challengeUpdate.participantGroupCount !== undefined;

            //So we do not update all hcp types
            const updatePrizePoolForFree = (state.freeChallenges.findIndex(x => x.id === id) !== -1) && updatePrizePoolForAll;
            const updatePrizePoolForBigBucks = (state.bigBucksChallenges.findIndex(x => x.id === id) !== -1) && updatePrizePoolForAll;
            const updatePrizePoolForMajorMayhem = (state.majorMayhemChallenges.findIndex(x => x.id === id) !== -1) && updatePrizePoolForAll;
                                           
            let newState = {
                ...state, // copy the state (level 0)
                proChallenges: state.proChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            pricePotAfterCut: CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantCount, item.fee, item.extraPrizePot, item.private),
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating,

                        };
                    }
                    return item;
                }),
                freeChallenges: state.freeChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            pricePotAfterCut: CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantGroupCount !== undefined ? action.challengeUpdate.participantGroupCount : action.challengeUpdate.participantCount, item.fee, item.extraPrizePot, item.private),
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating,
                           
                        };
                    }
                    return { //Bad way should use group Id not 'Weekend Championship'but we need to update prizepool for all in group
                        ...item,
                        pricePotAfterCut: (updatePrizePoolForFree && item.name.startsWith('Weekend Championship')) ? CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantGroupCount, item.fee, item.extraPrizePot, item.private) : item.pricePotAfterCut
                    };
                }),
                bigBucksChallenges: state.bigBucksChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            pricePotAfterCut: CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantGroupCount !== undefined ? action.challengeUpdate.participantGroupCount : action.challengeUpdate.participantCount, item.fee, item.extraPrizePot, item.private),
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating,
                           
                        };
                    }
                    return { //Bad way should use group Id not 'Weekend Championship'but we need to update prizepool for all in group
                        ...item,
                        pricePotAfterCut: (updatePrizePoolForBigBucks && item.name.startsWith('Weekend Championship')) ? CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantGroupCount, item.fee, item.extraPrizePot, item.private) : item.pricePotAfterCut
                    };
                }),
                majorMayhemChallenges: state.majorMayhemChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            pricePotAfterCut: CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantGroupCount !== undefined ? action.challengeUpdate.participantGroupCount : action.challengeUpdate.participantCount, item.fee, item.extraPrizePot, item.private),
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating,
                           
                        };
                    }
                    return { //Bad way should use group Id not 'Weekend Championship'but we need to update prizepool for all in group
                        ...item,
                        pricePotAfterCut: (updatePrizePoolForMajorMayhem && item.name.startsWith('Weekend Championship')) ? CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantGroupCount, item.fee, item.extraPrizePot, item.private) : item.pricePotAfterCut
                    };
                }),

                invitations: state.invitations.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            pricePotAfterCut: CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantCount, item.fee, item.extraPrizePot, item.private),
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating,

                        };
                    }
                    return item;
                }),

                yourChallenges: state.yourChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            pricePotAfterCut: CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantCount, item.fee, item.extraPrizePot, item.private),
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating,

                        };
                    }
                    return item;
                }),

                specialChallenges: state.specialChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            pricePotAfterCut: CalculatePot(item.type, action.challengeUpdate.status, item.hcpType, item.minParticipans, action.challengeUpdate.participantCount, item.fee, item.extraPrizePot, item.private),
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating
                        };
                    }
                    return item;
                })
            };
            return newState;
           
        case "CHALLENGE_REMOVE":
            const idToRemove = action.id;
            console.log("Id=" + idToRemove);
            return {
                ...state, // copy the state (level 0)
                proChallenges: state.proChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),
                freeChallenges: state.freeChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),
                bigBucksChallenges: state.bigBucksChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),
                majorMayhemChallenges: state.majorMayhemChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),
                invitations: state.invitations.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),
                yourChallenges: state.yourChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),

            };
        case "CHALLENGE_ADD":
            let addedState = { ...state };
            if (action.newChallenge.hcpType === HcpType.PRO && !action.newChallenge.private) {
                addedState.proChallenges = [...state.proChallenges, action.newChallenge];
            }
            if (action.newChallenge.hcpType === HcpType.SINGLE && !action.newChallenge.private) {
                addedState.freeChallenges = [...state.freeChallenges, action.newChallenge];
            }
            if (action.newChallenge.hcpType === HcpType.HCP_1_18 && !action.newChallenge.private) {
                addedState.bigBucksChallenges = [...state.bigBucksChallenges, action.newChallenge];
            }
            if (action.newChallenge.hcpType === HcpType.HCP_19_36 && !action.newChallenge.private) {
                addedState.majorMayhemChallenges = [...state.majorMayhemChallenges, action.newChallenge];
            }
            if (action.newChallenge.private) {
                addedState.yourChallenges = [...state.yourChallenges, action.newChallenge];
            }
            return addedState;

        case "JOINED_CHALLENGE":
            const joinedChallengeId = action.joinedChallenge.challengeId;
            const myUserId = action.userId;
            return {
                ...state, // copy the state (level 0)
                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === joinedChallengeId) {
                        return {
                            ...item,
                            numberOfParticipants: item.numberOfParticipants + 1,
                            participants: [...item.participants, action.joinedChallenge],
                            isParticipating: (typeof myUserId !== 'undefined' && myUserId === action.joinedChallenge.userId) ? true : item.isParticipating,
                            pricePotAfterCut: CalculatePot(item.type, item.status, item.hcpType, item.minParticipans, item.numberOfParticipants + 1, item.fee, item.extraPrizePot, item.private),
                          
                        };
                        
                    }
                    return item;
                })
            };
        case "LEFT_CHALLENGE":
            const leftChallengeId = action.leftChallenge.challengeId;
            const myUserIdLeave = action.userId;

            return {
                ...state, // copy the state (level 0)

                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === leftChallengeId) {
                        return {
                            ...item,
                            numberOfParticipants: item.numberOfParticipants - 1,
                            participants: item.participants.filter((item, index) =>
                                item.userId !== action.leftChallenge.userId  //Remove user with id
                            ),
                            isParticipating: (typeof myUserIdLeave !== 'undefined' && myUserIdLeave === action.leftChallenge.userId) ? false : item.isParticipating,
                            pricePotAfterCut: CalculatePot(item.type, item.status, item.hcpType, item.minParticipans, item.numberOfParticipants - 1, item.fee, item.extraPrizePot, item.private),
                           
                        };

                    }
                    return item;
                })
            };
        case "CHALLENGE_PARTICIPANT_EVENT_FORWARDED":
            const challengeId = action.challengeParticipantEvent.challengeId;
           
            return {
                ...state,
                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === challengeId) {
                        return {
                            ...item,
                            participants: item.participants.map((pitem, index) => {
                                const event = action.challengeParticipantEvent.participantEvents.find(x => x.participantId === pitem.userId) 
                                if (event !== undefined) {
                                    return {
                                        ...pitem,
                                        status: event.status,
                                        points: event.status === ParticipantStatus.DONE ? event.points : null,
                                        strokes: event.status === ParticipantStatus.DONE ? event.strokes : null,
                                    };
                                }
                                return pitem;
                            }),
                        };
                    }
                    return item;

                }),
            };
        case "CHALLENGE_PARTICIPANT_ERROR_FORWARDED":
            const errorChallengeId = action.error.challengeId;
 
            return {
                ...state,

                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === errorChallengeId) {
                        return {
                            ...item,
                            participants: item.participants.map((pitem, index) => {
                                if (action.error.participantIds.findIndex(x=>x === pitem.userId)!==-1) {
                                    return {
                                        ...pitem,
                                        error: true,
                                        errorDescription: action.error.message,
                                    };
                                }
                                return pitem;
                            }),
                        };
                    }
                    return item;

                }),
            };
        case "FOLLOWING_STREAMER":
            return {
                ...state,
                streamer: action.streamer.nick,
            };
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }

}