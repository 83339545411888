const initialState = {
   
    currentTournament: null,
  
};


export function tournamentReducer(state = initialState, action) {
    switch (action.type) {
        case "CURRENT_TOURNAMENT":
            return {
                ...state,
                currentTournament: action.tournament,
            };
        case "CHALLENGE_UPDATE":            
            const id = action.challengeUpdate.challengeId;
            const updatedUserId = action.userId;

            if (state.currentTournament === null) {
                console.log("Tournament not found");
                return state;
            }
               
            if (state.currentTournament.qualifiers.findIndex(x => x.id === id) === -1 && state.currentTournament.final.id !== id) {
                console.log("Update is not for tournament");
                return state;
            }

            console.log("Tournament Challenge update recieved");
          
            const userIsParticipating = updatedUserId === action.challengeUpdate.userId ? action.challengeUpdate.userIsParticipating : state.currentTournament.isParticipating;
            const participatingChallengeId = (updatedUserId === action.challengeUpdate.userId) ? (userIsParticipating ? id : undefined ) : state.currentTournament.participatingChallengeId;

            //* Get new participant count from none changed challenges and changes
            var newParticipantCount = 0;
            state.currentTournament.qualifiers.forEach((item, index) => {
                if (item.id !== id) {
                    newParticipantCount = newParticipantCount + item.numberOfParticipants;
                }
            });
            newParticipantCount = newParticipantCount + action.challengeUpdate.participantCount;

            let newState = {
                ...state,
                currentTournament: {
                    ...state.currentTournament,
                    qualifiers: state.currentTournament.qualifiers.map((item, index) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                status: action.challengeUpdate.status,
                                hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                                influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : item.influenserEpicDisplayName,
                                numberOfParticipants: action.challengeUpdate.participantCount,
                                isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating
                            };
                        }
                        return item;
                    }),
                    final: (state.currentTournament.final.id === id ?
                        {
                            ...state.currentTournament.final,
                            status: action.challengeUpdate.status,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : state.currentTournament.final.hasInfluenser,
                            influenserEpicDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserEpicDisplayName : null) : state.currentTournament.final.influenserEpicDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : state.currentTournament.final.isParticipating
                        } : state.currentTournament.final),
                    
                    isParticipating: userIsParticipating,
                    numberOfParticipants: newParticipantCount,
                    participatingChallengeId: participatingChallengeId,
                }
            };
            return newState;



        default:
            return state;
    }
}