
export function localeDateTimeFormat(date) {
    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(played)

    return formated;
}


export function localeDateFormat(date) {
    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(played)

    return formated;
}


export function localeShortFormat(date) {
    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('en-us', {
        month: 'long',
        day: 'numeric',
    }).format(played)

    return formated;
}

export const datesAreOnSameDay = (first, second) => {
    var firstDate = new Date(first);
    var secondDate = new Date(second);

    return firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate();
}