import style from './mobileMenu.module.scss';
import menu from './menu.module.scss';
import burgerStyle from './burger.module.scss';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useEffect, useState } from 'react';
import { getLobbyLink } from './menu';


export default function MobileMenu(props) {
    const { open, toggleMenu } = props;
  
    useEffect(() => {
      open ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
    }, [open]);

    const extracurricular = useSelector(state => state.challengeReducer.specialChallenges);
    const user = useSelector(state =>  state.userReducer.userInfo);
    const isLoggedIn =  useSelector(state =>  state.userReducer.loggedIn);

    const lobby = getLobbyLink(isLoggedIn,user,extracurricular);
  
    return (
      <>
        <nav
          className={`${style.mobileMenu} ${
            open ? style.mobileMenu__open : style.mobileMenu__closed
          }`}
        >
          <div className={style.mobileMenu__links}>
            <HashLink smooth to="/extracurricular#challenges" onClick={toggleMenu}>
              Challenges
            </HashLink>
            <Link to={lobby} onClick={toggleMenu}>
              Lobby
            </Link>
            <Link to="/extracurricular/rankings" onClick={toggleMenu}>
              Rankings
            </Link>
            <HashLink smooth to="/extracurricular#howtoplay" onClick={toggleMenu}>
              How To Play
            </HashLink>
          </div>
        </nav>
      </>
    );
  }