import React from 'react'
import PropTypes from 'prop-types'
import LobbyParticipant from './lobbyParticipant'
import { ChallengeType } from '../utils/const'

const LobbyTable = ({ challengeStatus, challengeError, participants, maxParticipans, viewProfile, color, title, type }) => (

    <table className="table lobby-table table-hover">
        {/* <table className="table lobby-table table-hover table-striped"> */}
        <thead>
            <tr>
                {/* <th className="number-head"></th> */}
                <th className="text-center user-placement">
                    <span className="desktop-items">Place</span>
                    <span className="mobile-items">#</span>
                </th>
                <th className="user-head">Player Name</th>
                <th className="text-center desktop-items-table">HCP</th>
                <th className="text-center">Score</th>
            </tr>
        </thead>
        <tbody>
        {typeof participants === 'undefined' ? '' : (participants.map((participant,index) => (
            <LobbyParticipant key={index} {...participant} onClick={() => viewProfile(participant.userId)} challengeStatus={challengeStatus} challengeError={challengeError} color={color} title={title} index={index} />
        )))}


        </tbody>
    </table>
)

export default LobbyTable
