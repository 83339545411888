const initialState = {
    event: null,
    showEvent: false,
    extraData: null
};

export function challengeEventReducer(state = initialState, action) {
    switch (action.type) {
        case "SHOW_CHALLENGE_EVENT":
            return {
                ...state,
                event: action.event,
                showEvent: true,
                extraData: action.extraData
            }
        case "HIDE_CHALLENGE_EVENT":
            return {
                ...state,
                showEvent: false,
                extraData: null
            }
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }
}