import React from 'react'
import PropTypes from 'prop-types'
import TopListParticipant from './topListParticipant'

const TopListTable = ({ participants, numericType }) => (

    <table className="table lobby-table table-hover table-striped">
        <tbody>

            {typeof participants === 'undefined' ? '' : (participants.map((participant, index) => (
                <TopListParticipant numericType={numericType} key={participant.userId} {...participant} index={index} />
            )))}
	    </tbody>
    </table>
)

export default TopListTable