import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class BasicTopBar extends React.Component {

    render() {             

        return (
            <div className="topbar">               

                <div className="desktop-items">
                   
                   
                </div>
                <div className="mobile-items">
                   
                    <Link to="/">
                        <img src="/images/wgc-logo.png" className="mobile-logo" alt=""/>
		            </Link>

                    
	            </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {

    };
}

const topBarBasic = connect(mapStateToProps)(BasicTopBar);
export { topBarBasic as BasicTopBar }; 