import { ChallengeType, HcpType, PriceType, ChallengeStatus } from "../utils/const";
import { round } from "../utils/math";

function GetCompanyCutMatix() {

    var companyCut = [
        {
            challengeType: ChallengeType.FREE,
            cut: 0,
            private: true,
        },
        {
            challengeType: ChallengeType.SPONSORED,
            cut: 0,
            private: true,
        },
        {
            challengeType: ChallengeType.PAYED,
            cut: 0.2,
            private: true,
        },
        {
            challengeType: ChallengeType.FREE,
            cut: 0,
            private: false,
        },
        {
            challengeType: ChallengeType.SPONSORED,
            cut: 0,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.PRO,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.SINGLE,
            participantCountMin: 0,
            participantCountMax: 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.SINGLE,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.SINGLE,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.HCP_1_18,
            participantCountMin : 0,
            participantCountMax : 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.HCP_1_18,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.HCP_1_18,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.HCP_19_36,
            participantCountMin: 0,
            participantCountMax: 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.HCP_19_36,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.HCP_19_36,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.ANY,
            participantCountMin: 0,
            participantCountMax: 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.ANY,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.PAYED,
            hcpType: HcpType.ANY,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.PRO,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.SINGLE,
            participantCountMin: 0,
            participantCountMax: 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.SINGLE,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.SINGLE,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.HCP_1_18,
            participantCountMin: 0,
            participantCountMax: 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.HCP_1_18,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.HCP_1_18,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.HCP_19_36,
            participantCountMin: 0,
            participantCountMax: 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.HCP_19_36,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.HCP_19_36,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.ANY,
            participantCountMin: 0,
            participantCountMax: 9,
            cut: 0.0,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.ANY,
            participantCountMin: 10,
            participantCountMax: 19,
            cut: 0.2,
            private: false,
        },
        {
            challengeType: ChallengeType.QUALIFIER,
            hcpType: HcpType.ANY,
            participantCountMin: 20,
            cut: 0.3,
            private: false,
        },
    ];

    return companyCut;
}

function GetCompanyCut(challengeType, hcpType, participantCount, privateChallenge) {

    var cut = GetCompanyCutMatix();
    cut = cut.filter(x => x.challengeType === challengeType && x.private === privateChallenge && (x.hcpType === undefined || x.hcpType === hcpType));

    if (cut.length === 1) {
        return cut[0].cut;
    }
    else {
        cut = cut.filter(x => participantCount >= x.participantCountMin && (x.participantCountMax === undefined || participantCount <= x.participantCountMax));
        if (cut.length === 1) {
            return cut[0].cut;
        }
    }
    return 0;
}

function NumberOfParticipantWhenDone(challengeStatus, participantCount, minParticipans)
{
    return participantCount;

    //Commented out when pricepot should start at 0 always 20210813
    /*if (challengeStatus === ChallengeStatus.DONE)
        return participantCount;
    else
        return minParticipans > participantCount ? minParticipans : participantCount;*/
}

export function CalculatePot(challengeType, challengeStatus, hcpType, minParticipans, participantCount, fee, extraPrizePot, privateChallenge) {
    const number = NumberOfParticipantWhenDone(challengeStatus, participantCount, minParticipans);
    var pot = roundToTwo(extraPrizePot  + ((number * fee) * (1 - GetCompanyCut(challengeType, hcpType, number, privateChallenge))));

    return pot;
}

export function CalculatePayout(challengeType, challengeStatus, hcpType, minParticipans, participantCount, fee, extraPrizePot, privateChallenge, potCut) {

    return roundToTwo(CalculatePotInternal(challengeType, challengeStatus, hcpType, minParticipans, participantCount, fee, extraPrizePot, privateChallenge) * potCut);
}


function CalculatePotInternal(challengeType, challengeStatus, hcpType, minParticipans, participantCount, fee, extraPrizePot, privateChallenge) {

    const number = NumberOfParticipantWhenDone(challengeStatus, participantCount, minParticipans);
    var pot = (extraPrizePot + (number * fee) * (1 - GetCompanyCut(challengeType, hcpType, number, privateChallenge)));

    return pot;
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}


export function getPotShare(participantCount, potShare) {
   return potShare.find(x => x.minParticipants <= participantCount && (x.maxParticipants === undefined || x.maxParticipants >= participantCount)).share;
}

export function getPrice(share, pricePotAfterCut) {
    return roundToTwo(share * pricePotAfterCut);
}


export function getPriceTable(challenge) {

    const share = getPotShare(NumberOfParticipantWhenDone(challenge.challengeStatus, challenge.numberOfParticipants, challenge.minParticipans), challenge.potShare);


    if (challenge.priceType === PriceType.FIXED) {
        return challenge.prices;
    }
    else {
        const prices = share.map((item, index) => {
            return {
                placement: index + 1,
                payout: getPrice(item, challenge.pricePotAfterCut),
                payoutType: "CASH",
            };
        });

        return prices;
    }
   
}

export function getNextPriceTable(challengeType, challengeStatus, hcpType, minParticipans, participantCount, fee, extraPrizePot, privateChallenge, potShare, priceType, prices) {

    if (priceType === PriceType.FIXED) {
        return prices;
    }

    const pot = CalculatePot(challengeType, challengeStatus, hcpType, minParticipans, participantCount + 1, fee, extraPrizePot, privateChallenge)

    const share = getPotShare(NumberOfParticipantWhenDone(challengeStatus, participantCount + 1, minParticipans), potShare);

    const pricesRecalc = share.map((item, index) => {
        return {
            placement: index + 1,
            payout: getPrice(item, pot),
            payoutType: "CASH",
        };
    });

    return pricesRecalc;
}