import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { infoDialog} from "./../challengeDialogs";
import './style.css';
import "../../components/fontawesome/css/all.css"
import "bootstrap"

const Landingpage = () => {

    var selected = localStorage.getItem("i18nextLng");
    if (selected.startsWith("fr-")) {
        i18next.changeLanguage("fr");
        selected = "fr";
    }
    else if (selected.startsWith("en-")) {
        i18next.changeLanguage("en");
        selected = "en";
    }

    const { t } = useTranslation();

    function onChange(event){
        console.log(event.target.value);
        i18next.changeLanguage(event.target.value);
    }

    return (

    <div className="landing-page-body">
        <nav className="landing navbar navbar-expand-lg justify-content-between sticky-top">
            <div className="container">
                    <Link className="landing navbar-brand" to="/"><img src="./images/landingpage/logo.JPG" alt="WGC" /></Link>
                    <select className="region-select language-select" value={selected} onChange={onChange}>
                        <option className="region-select-region" value="en">{t("english")}</option>
                        <option className="region-select-region" value="fr">{t("french")}</option>
                    </select>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
                </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                      
                        <ul className="navbar-nav mr-auto">

                        <li className="nav-item active">
                            <Link className="landing nav-link" to="/clubhouse"><i className="fa-solid fa-house"></i> Clubhouse</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="landing nav-link" to="/leaderboard"><i className="fa-solid fa-chart-simple"></i> {t("leaderboard")}</Link>
                        </li>
                        <li className="nav-item">
                                <Link className="landing nav-link" to="/about"><i className="fa-solid fa-circle-info"></i> {t("about")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="landing nav-link" to="/faq"><i className="fa-solid fa-message"></i> FAQ</Link>
                        </li>
                    </ul>
                    <div className="nav-btns">
                        <Link to="/signup" className="btn btn-primary">{t("register")}</Link>
                        <Link to="/login" className="btn btn-outline">{t("login")}</Link>
                    </div>
                </div>
            </div>
        </nav>

        <section className="landing" id="intro-section">
            <div className="intro-gradient">
                <div className="container">
                        <h1 className="landing text-shadow">{t("playing_digitally")}</h1>
                        <p className="on-image text-shadow">{t("play_in_the_club")}</p>
                        <Link to="/clubhouse" className="btn btn-primary btn-m-100">{t("to_challenges")}</Link>
                        <Link to="#" onClick={() => infoDialog()} className="btn btn-secondary btn-m-100">{t("how_to_play")}</Link>
                </div>
            </div>
        </section>

        <div id="temp-events" className="container">
        <div className="row">
            <div className="col-0 col-lg-2 col-xl-4">
            </div>
                    
            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                <Link to="/extracurricular" className="landing event">
                    <div className="img-container">
                        <img src="/images/extracurricular/landingpage/WGC_EXCR_homepage_CTA.jpg" alt="Extracurricular Event"/>
                    </div>

                    <div className="texts">
                                <div className="surtitle">October 14<sup>th</sup> to 20<sup>th</sup></div>
                        <h5>EXTRACURRICULAR</h5><h5>Challenge!</h5>
                        <i className="fa-solid fa-arrow-right-long"></i>
                    </div>
                </Link>
            </div> 
            
            
        </div>
    </div>

    <section className="landing" id="concept-section">
        <div className="container">
                    <h2 className="landing">{t("concept")}</h2>
                    <p className="subtitle">{t("concept_text")}</p>

            <div className="row mt-4">
                <div className="col col-12 col-sm-6 col-lg-3">
                    <div className="concept-item">
                        <div className="icon"><i className="fa-solid fa-pencil"></i></div>

                                <p><b>{t("join")}</b></p>

                                <p>{t("join_text")}</p>
                    </div>
                </div>

                <div className="col col-12 col-sm-6 col-lg-3">
                    <div className="concept-item">
                        <div className="icon"><i className="fa-solid fa-golf-ball-tee"></i></div>

                                <p><b><p>{t("go_out")}</p></b></p>

                                <p><p>{t("go_out_text")}</p></p>
                    </div>
                </div>

                <div className="col col-12 col-sm-6 col-lg-3">
                    <div className="concept-item">
                        <div className="icon"><i className="fa-solid fa-medal"></i></div>

                                <p><b>{t("enter_score")}</b></p>

                                <p>{t("enter_score_text")}</p>
                    </div>
                </div>

                <div className="col col-12 col-sm-6 col-lg-3">
                    <div className="concept-item">
                        <div className="icon"><i className="fa-solid fa-sack-dollar"></i></div>

                                <p><b>{t("pay")}</b></p>

                                <p>{t("pay_text")}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

        <section className="landing" id="how-to-section">
        <div className="one-two-thirds-section" >
            <div className="one-third">
                <div className="container">
                    <h2 className="landing text-shadow">{t("how_to_play")}</h2>
                    <p className="on-image text-shadow">{t("choose")}</p>
                            <Link to="#" onClick={() => infoDialog()} className="btn btn-secondary">{t("need_info")}</Link>
                </div>
            </div>
            <div className="two-thirds">
                <div className="container">
                    <div className="numbered-items">
                        <div className="item">
                            <div className="item-title">
                                <span className="item-number">1</span>
                                <h5>{t("join") }</h5>
                            </div>

                            <p>{t("join_desc")}</p>
                        </div>
                        <div className="item">
                            <div className="item-title">
                                <span className="item-number">2</span>
                                        <h5>{t("enter_score_2")}</h5>
                            </div>

                            <p>{t("enter_score_desc")}</p>
                        </div>
                        <div className="item">
                            <div className="item-title">
                                <span className="item-number">3</span>
                                <h5>{t("leaderboard")}</h5>
                            </div>

                            <p>{t("leaderboard_desc") }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    


    <section className="landing" id="challenges-categories-section">
        <div className="container">
            <h2 className="landing">{t("compete")}</h2>
            <p className="subtitle">{t("compete_text")}</p>

            <div className="row my-4">
                <Link to="/clubhouse" className="landing col-12 col-sm-6 col-lg-3">
                    <div className="category-card cat-hcp-single">
                        <div className="top">
                            <h4 className="landing">HCP Single</h4>
                        </div>
                        <div className="bottom"></div>
                    </div>
                </Link>
                <Link to="/clubhouse" className="landing col-12 col-sm-6 col-lg-3">
                    <div className="category-card cat-hcp-16">
                        <div className="top">
                            <h4 className="landing">HCP up to 18</h4>
                        </div>
                        <div className="bottom"></div>
                    </div>
                </Link>
                <Link to="/clubhouse" className="landing col-12 col-sm-6 col-lg-3">
                    <div className="category-card cat-hcp-54">
                        <div className="top">
                            <h4 className="landing">HCP up to 54</h4>
                        </div>
                        <div className="bottom"></div>
                    </div>
                </Link>
                <Link to="/pro" className="landing col-12 col-sm-6 col-lg-3">
                    <div className="category-card cat-hcp-pro">
                        <div className="top">
                            <h4 className="landing">Pro</h4>
                        </div>
                        <div className="bottom"></div>
                    </div>
                </Link>
            </div>

            <Link to="/clubhouse" className="btn btn-secondary">{t("see_all")}</Link>

        </div>
    </section>

    <section className="landing" id="private-challenges-section">
        <div className="two-one-thirds-section">
            <div className="two-thirds">
                <div className="container">
                    <h2 className="landing">{t("private")}</h2>
                    <p>{t("private_text")}</p>
                    <Link to="/private" className="btn btn-primary">
                        {t("create")}
                    </Link>
                </div>
            </div>
            <div className="one-third"></div>
        </div>
    </section>

    <section className="landing" id="cta">
        <div className="cta-background container">
            <div className="row">
                <div className="col-12 col-md-6">
                        <h3 className="landing text-shadow">{t("join_now")}</h3>
                </div>
                <div className="col-12  col-md-6">
                        <Link to="/signup" className="btn btn-primary" href="#">{t("free_account")}</Link>
                </div>
            </div>
        </div>
    </section>

    <footer className="landing">
        <div className="container">
            <div className="footer-top row">
                <div className="col-12 col-lg-3">
                    <Link to="/" className="landing footer-logo">
                        <img src="./images/landingpage/logo.svg" alt="World Golf Challenge"/>
                    </Link>
                </div>
                <div className="col col-6 col-md-4 col-lg-3">
                    <ul>
                        <li><Link to="/clubhouse" className="landing">Clubhouse</Link></li>
                        <li><Link to="/leaderboard" className="landing">{t("leaderboard")}</Link></li>
                    </ul>
                </div>
                <div className="col col-6 col-md-4 col-lg-3">
                    <ul>
                        <li><Link to="/about" className="landing">{t("about")}</Link></li>
                        <li><Link to="/faq" className="landing">FAQ</Link></li>
                    </ul>
                </div>
                <div className="col col-12 col-md-4 col-lg-3">
                    <ul>
                        <li><Link to="/wallet" className="landing">{t("wallet")}</Link></li>
                        <li><Link to="/settings" className="landing">{t("settings")}</Link></li>
                        <li><Link to="/logout" className="landing color-danger">{t("sign_out")}</Link></li>
                    </ul>
                </div>
            </div>

                    <img src="./images/landingpage/paypal.png" alt="Paypal" className="paypal-logo" />
                    <img src="./images/landingpage/powered_by_stripe.svg" alt="Stripe" className="stripe-logo" />

            <ul className="footer-socials">
                <li>
                    <Link className="landing" title="Instagram" rel="nofollow" to={{ pathname: "https://www.instagram.com/worldgolfchallenge/" }} target="_blank">
                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                </li>
                <li>
                    <Link href="#" className="landing" title="Facebook" target="_blank" rel="nofollow" to={{ pathname: "https://www.facebook.com/worldgolfchallenge" }}>
                        <i className="fa-brands fa-facebook-square"></i>
                    </Link>
                </li>
                <li>
                    <Link href="#" className="landing" title="LinkedIn" target="_blank" rel="nofollow" to={{ pathname: "https://www.linkedin.com/company/worldgolfchallenge/about/" }}>
                        <i className="fa-brands fa-linkedin"></i>
                    </Link>
                </li>
                <li>
                    <Link href="#" className="landing" title="Mail" target="_blank" rel="nofollow" to={{ pathname: "mailto:hello@wgc.gg" }}>
                        <i className="fa-solid fa-square-envelope"></i>
                    </Link>
                </li>
            </ul>

            <ul className="footer-credits">
                <li><Link to="/privacy-policy" className="landing">{t("privacy_policy")}</Link></li>
                <li><Link to="/terms-of-agreement" className="landing">{t("terms")}</Link></li>
                <li>&copy; World Golf Challenge 2022</li>
            </ul>
        </div>

    </footer>
    </div> 

    );
}

export default Landingpage