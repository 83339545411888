import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class About extends React.Component {

    render() {
        var showPro = false;

        if (this.props.fromProUrl) {
            showPro = true;
        }
        else if (this.props.user !== undefined && this.props.isLoggedIn && this.props.user.playerStatus === "PRO") {
            showPro = true;
        }

        return (

            <div>
                {/* <br /> */}
                <div className="container-fluid">
                    {/* <h2 className="panel-title">FAQ</h2> */}
                    <div className="panel faq">
                        <div className="container-fluid">

                            
                            {showPro ?
                                <div>
                            <h3>ABOUT</h3>
                            <p>What about getting an additional income from your traditional professional tournaments?
                                It’s now possible with World Golf Challenge www.wgc.gg/pro.</p>

                            <p>You are now able to enter a Challenge on each day of your main tournament.</p>

                            <p>It has never been so easy!</p>
                            <ul>
                                <li>Register on wgc.gg (main page).</li>
                                <li>Deposit $ via PayPal.</li>
                                <li>Join the Challenge of your choice before 9am UCT+1.</li>
                                <li>Play your tournament’s round, score low!</li>
                                <li>Enter your score in Lobby (green box) before 9pm UCT+1 the same day.</li>
                                <li>Check your profile for playing history and winnings.</li>
                            </ul>

                            <p>Breakdown: 80% is given back to players with a breakdown modeled on the PGA Tour (between 25% — 30% of winners),
                                the more players the more winners « see chart ». World Golf Challenge takes 20% for tech development, support &amp; admin follow up.</p>

                            <p>PayPal: Your money is safe and sit on a PayPal segregated account.
                                You can claim your money anytime and we will make sure you get it within 10 days although we encourage you to
                                minimise transfers to avoid PayPal fees by leaving your cash on your account.</p>

                            <h4>PRIVATE CHALLENGE FOR PROS &amp; AMATEURS</h4>

                            <p>Practice round? Missed cut? Use « Private » on the main page to create your own Challenge and invite other players!</p>

                             <p>For further inquiries &amp; comments, please contact <a href="mailto:hello@wgc.gg">hello@wgc.gg</a>.</p> </div> : null}

                            <h3>WORLD GOLF CHALLENGE</h3>
                            <p>World Golf Challenge was created in 2021 by Jonas Jaeggi within the company KHEOX consulting Sàrl based on the shores of Lake Neuchâtel in Switzerland. This golf enthusiast combines activities in finance (for professional golfers among others) and golf sponsoring for the watch industry. To bring his project to life, Jonas Jaeggi collaborated with the Swedish company YONYFY, which developed the technological aspect of the World Golf Challenge platform and is known for having created the eSport challenge for Fortnite <a href="https://www.challenge.gg">www.challenge.gg</a> </p>

                            <h4>THE REFLEXION</h4>
                            <p>“Necessity is the mother of invention”.</p>
                            <p>World Golf Challenge was born from my own needs and experience. At the start of a given week, I used to compare my weekend competition scores with those of my friends, both at home and abroad. When lockdown came and made it impossible to travel, I found it restrictive to be able to compete only with the members of my club and not to be able to track and cross-reference our results and performance.</p>
                            <p>A year on, World Golf Challenge answers this need through a digital platform that gives golf and golfers a border-free playground enabling players to compete remotely while playing physically on the course of their choice. A way of opening up golfing borders and also of being challenged by going up against other players internationally. A great means of boosting progress and motivation.</p>
                            <p>Jonas Jaeggi, Founder of the World Golf Challenge</p>

                            <h4>WHAT IS IT?</h4>
                            <p>Competing only with members of your own golf club? Think bigger…See beyond!</p>
                            <p>From inexperienced amateurs to skilled players, everyone can now compare their score of the day on an international scale! Thanks to World Golf Challenge, it's time to soar across country club and national boundaries to challenge friends on the other side of the world, as well as complete strangers, all united by the same passion : Golf!</p>

                            <p>The sheer complexity of golf gave rise to the handicap system enabling players of all levels to measure their scores, which are adjusted according to course difficulty and player level. A feature highly specific to golf which was until now confined by geographical constraints (only between members of the same club) as well as by technological issues (digitalization and e-gaming have since made considerable progress).</p>

                            <p> World Golf Challenge consists of an internet platform (www.wgc.gg) on which the player creates an account, chooses a "free" or "buy-in" Challenge on a given day, plays their golf course and then enters their score (net Stableford – adjusted to her/his level) at the end of the day, accessing their ranking in connection with all the players around the world who have participated in the same Challenge. Winners earn great prizes (depending on the competition) and/or share the pot created for the day.</p>

                            <h4>AMATEURS</h4>
                            <p>The Challenges offered to date are divided into two categories:</p>
                            <ul>
                                <li>"Free", meaning Challenges that players enter when they go to play an 18-hole course with no official score</li>
                                and
                                <li>"Buy-in", paid $10 Challenges that add to the prize pool to be shared by the highest ranked players in the Challenge.</li>
                            </ul>
                            <p>Buy-ins are played in conjunction with official competitions (counting towards the golf handicap) in order to comply with the official rules of golf and potentially enable score checking.</p>

                            <h4>PROFESSIONALS</h4>
                            <p>A "Professional Players" section has also been created to offer third-level players the opportunity to add daily winnings to their official tournament earnings (as a player who misses the cut does not receive any tournament winnings despite the costs implied by being present at the tournament).</p>

                            <p>In the case of "Professionals", players compete on the same course.</p>

                            <h4>BENEFITS FOR SPONSORS AND GOLF CLUBS</h4>
                            <p>As far as sponsors are concerned, World Golf Challenge enables them to reach a large number of golfers with a single platform at a much more competitive price than a series of tournaments in golf clubs scattered around the world. Meanwhile, golf clubs benefit from the enthusiasm of participants who are motivated to play more competitions and can thus offer their sponsors a broader range of options (e.g. a golf club can create its own phygital challenge with its own sponsors and thus enhance its exposure since it can reach a wider spectrum of golfers)</p>

                            <h4>NEXT STEPS</h4>
                            <p>Numerous surprises and developments are in the making, such as the creation of "Private", "Charity" or "Sponsored" Challenges enabling a mix of "free" and "buy-in" sections.</p>
                            <p>Challenges for players under 18 will also be created, as they are not allowed to participate in buy-ins (the laws on cash prizes vary from country to country).</p>

                            <p>So, ready to play your first course with us and... the world?</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.userInfo,
        isLoggedIn: state.userReducer.loggedIn,
        fromProUrl: state.userReducer.fromProUrl,       
    };
}

function mapDispatchToProps(dispatch) {
    return {
      
    };
}

const about = connect(mapStateToProps, mapDispatchToProps)(About);
export { about as About };


