import { JsonHubProtocol, HttpTransportType, HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { authToken } from "../utils/authHeader";
import { config } from "../config/config";
import { Region } from "../utils/const";
import {
    lobbyInfo, lobbiesInfo, lobbyNotFound, availableChallenges, availableSpecialChallenges, challengeUpdate, challengeRemoveWithDelay, challengeAdd, joinedChallenge, leftChallenge, challengeEvent,
    challengeParticipantEvent, challengeResult, challengeError, challengeParticipantError,
    streamerChallengeEvent, streamerChallengeParticipantEvent, streamerChallengeResult, closeStreamerLobby, streamerChallengeError, streamerChallengeParticipantError
} from "../actions/challengeActions";
import { currentTournament } from "../actions/tournamentActions";
import { refreshUser, stripeDepositVerified } from "../actions/userActions";
import { showInfoPopup, showSuccessPopup, showErrorPopup, showErrorPopupWithAction } from "../actions/dialogActions";
import { showErrorPanel, hideInfoPanel, showInfoPanel, flashInfoPanel } from "../actions/infoPanelActions";

const challengeUrl = `${config.url.BASE_URL}/challenges`;
const streamerUrl = `${config.url.BASE_URL}/streamer`;


export function signalrMiddleware({ getState, dispatch }) {
    let challengeConnection = null;
    let challengeConnected = false;

    let streamerConnection = null;
    let streamerConnected = false;
    let currentStreamerNick = null;

    const protocol = new JsonHubProtocol();
    const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;
    const options = {
        transport,
        logMessageContent: true,
        logger: LogLevel.Trace,
        accessTokenFactory: () => authToken()
    };

    return function (next) {
        return function (action) {
            // do your stuff
            if (action.type === "SUBSCRIBE_CHALLENGES") {

                if (!challengeConnected) {
                    console.log("Subscribe to challenges!!");

                    initConnection();
                    start(false, 1, null);
                }
                else {
                    console.log("Already connected to challenges");
                }
            }
            else if (action.type === "SUBSCRIBE_TO_LOBBY") {
                if (!challengeConnected) {
                    console.log("Subscribe to challenges!!");

                    initConnection();
                    start(false, 1, action.id);
                }
                else {
                    subscribeLobby(action.id)
                }

                /*   const lobbyUrl = `${config.url.BASE_URL}/games`
   
                   if (!lobbyConnected) {
                       console.log("Subscribe to lobby");
                       // create the connection instance
                       lobbyConnection = new HubConnectionBuilder()
                           .withUrl(lobbyUrl, options)
                           .withHubProtocol(protocol)
                           .withAutomaticReconnect([0, 2000, 10000, 30000, 30000, 30000, 30000, 30000, 30000])
                           .build();
   
                       lobbyConnection.on("JoinedChallenge", onJoinedChallenge);
                       lobbyConnection.on("LeftChallenge", onLeftChallenge);
                       lobbyConnection.on("ChallengeEvent", onChallengeEvent);
                       lobbyConnection.on("ChallengeParticipantEvent", onChallengeParticipantEvent);
                       lobbyConnection.on("ChallengeResult", onChallengeResult);
                       lobbyConnection.on("ChallengeError", onChallengeError);
                       lobbyConnection.on("ChallengeParticipantError", onChallengeParticipantError);
   
                       lobbyConnection.onclose(() => onLobbyClosed())
                       lobbyConnection.start()
                           .then(() => onLobbyConnected(action.id))
                           .catch(err => onLobbyConnectionError(err));
                   }
                   else {
                       console.log("Already connected to lobby");
                   }*/
            }
            else if (action.type === "REGION_UPDATED") {
                console.log("Fetch new challenges for Region=" + action.region);

                if (challengeConnected) {
                    switchRegion(action.region)
                }
              

            }
            else if (action.type === "SUBSCRIBE_TO_STREAMER_LOBBY") {
                if (!streamerConnected) {
                    console.log("Subscribe to streamer!!");

                    initStreamerConnection();
                    startStreamer(false, 1, action.nick);
                }
                else {
                    subscribeStreamerLobby(action.nick)
                }
            }
            else if (action.type === "UNSUBSCRIBE_LOBBY") {
                console.log("Unsubscribe to lobby");
                unsubscribeLobby(action.id);
                // lobbyConnection.stop();
            }
            else if (action.type === "UNSUBSCRIBE_CHALLENGES") {
                console.log("Unsubscribe challenges and disconnect");
                challengeConnected = false;
                if (challengeConnection != null) {
                    challengeConnection.stop();
                }
            }

            return next(action);
        };
    };

    function initConnection() {
        // create the connection instance
        challengeConnection = new HubConnectionBuilder()
            .withUrl(challengeUrl, options)
            .withHubProtocol(protocol)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 210000) {
                        // If we've been reconnecting for less than 210 seconds so far,
                        // wait between 5 and 30 seconds before the next reconnect attempt.
                        return (5 + Math.floor(Math.random() * 30)) * 1000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .build();

        challengeConnection.on("ServiceStatus", onServiceStatus);

        challengeConnection.on("AvailableChallenges", onAvailableChallenges);
        challengeConnection.on("SpecialChallenges", onSpecialChallenges);
        challengeConnection.on("CurrentTournament", onCurrentTournament);

        challengeConnection.on("ChallengeUpdate", onChallengeUpdate);

        challengeConnection.on("JoinedChallenge", onJoinedChallenge);
        challengeConnection.on("LeftChallenge", onLeftChallenge);
        challengeConnection.on("ChallengeEvent", onChallengeEvent);
        challengeConnection.on("ChallengeParticipantEvent", onChallengeParticipantEvent);
        challengeConnection.on("ChallengeResult", onChallengeResult);
        challengeConnection.on("ChallengeError", onChallengeError);
        challengeConnection.on("ChallengeParticipantError", onChallengeParticipantError);

        challengeConnection.on("DepositEvent", onDepositEvent);

        challengeConnection.onreconnecting((err) => onChallengeReconnecting(err))
        challengeConnection.onreconnected((connectionId) => onChallengeReconnected(connectionId))
        challengeConnection.onclose((err) => onChallengeClosed(err))
    }

    function initStreamerConnection() {
        // create the connection instance
        streamerConnection = new HubConnectionBuilder()
            .withUrl(streamerUrl, options)
            .withHubProtocol(protocol)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 210000) {
                        // If we've been reconnecting for less than 210 seconds so far,
                        // wait between 5 and 30 seconds before the next reconnect attempt.
                        return (5 + Math.floor(Math.random() * 30)) * 1000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .build();


        streamerConnection.on("JoinedChallenge", onJoinedChallenge);
        streamerConnection.on("LeftChallenge", onLeftChallenge);
        streamerConnection.on("ChallengeEvent", onStreamerChallengeEvent);
        streamerConnection.on("ChallengeParticipantEvent", onStreamerChallengeParticipantEvent);
        streamerConnection.on("ChallengeResult", onStreamerChallengeResult);
        streamerConnection.on("ChallengeError", onStreamerChallengeError);
        streamerConnection.on("ChallengeParticipantError", onStreamerChallengeParticipantError);

        streamerConnection.on("StreamerJoinedChallenge", onStreamerJoinedChallenge);
        streamerConnection.on("StreamerLeftChallenge", onStreamerLeftChallenge);

        streamerConnection.onreconnecting((err) => onStreamerReconnecting(err))
        streamerConnection.onreconnected((connectionId) => onStreamerReconnected(connectionId))
        streamerConnection.onclose((err) => onStreamerClosed(err))
    }

    async function start(reconnected,attemptCount,id) {
        try {
            await challengeConnection.start();
            console.assert(challengeConnection.state === HubConnectionState.Connected);          

            getAvailableChallenges();

            if (id !== null) {            
                challengeConnection.invoke("SubscribeToCurrentLobbies", id)
                    .then((result) => onLobbiesInfo(result))
                    .catch(err => onLobbyError(err));
            }
            else {
                challengeConnection.invoke("SubscribeToCurrentLobbies", null)
                    .then((result) => onLobbiesInfo(result))
                    .catch(err => { console.log("Error when invoking SubscribeToCurrentLobbies") });
            }
            onChallengeConnected(reconnected);
        } catch (err) {
            console.assert(challengeConnection.state === HubConnectionState.Disconnected);
            if (attemptCount < 8) {
                onChallengeConnectionError(err)
                console.log("Attempt count=" + attemptCount);
                setTimeout(() => start(true, attemptCount + 1, id), 8000);
            }
            else {
                dispatch(showErrorPopupWithAction("Could not establish a connection, click to reload page", "Reload"));
            }
        }
    };

    async function startStreamer(reconnected, attemptCount, nick) {
        try {
            await streamerConnection.start();
            console.assert(streamerConnection.state === HubConnectionState.Connected);
            if (nick !== null) {
                streamerConnection.invoke("SubscribeToStreamerLobby", nick)
                    .then((result) => onStreamerLobbyInfo(result))
                    .catch(err => onStreamerLobbyError(err));
            }
            onStreamerConnected(reconnected);
        } catch (err) {
            console.assert(streamerConnection.state === HubConnectionState.Disconnected);
            if (attemptCount < 8) {
                onStreamerConnectionError(err)
                setTimeout(() => startStreamer(true, attemptCount + 1,nick), 8000);
            }
            else {
                dispatch(showErrorPopupWithAction("Could not establish a connection, click to reload page", "Reload"));
            }
        }
    };

    function onChallengeConnected(reconnected) {
        console.log("Challenge connected!!");
        challengeConnected = true;
        if (reconnected) {
            dispatch(flashInfoPanel("Connection established!"));
            dispatch(refreshUser());
        }
        //Hub will send challenges on clientConnected
    }

    function onStreamerConnected(reconnected) {
        console.log("Streamer connected!!");
        streamerConnected = true;
        if (reconnected) {
            dispatch(flashInfoPanel("Connection established!"));
        }
    }

    function onChallengeReconnecting(err) {
        console.assert(challengeConnection.state === HubConnectionState.Reconnecting);
        //Inform user
        console.log("Connection lost reconnecting...");
        dispatch(showErrorPanel("Connection lost reconnecting..."));
    }

    function onStreamerReconnecting(err) {
        console.assert(streamerConnection.state === HubConnectionState.Reconnecting);
        //Inform user
        console.log("Streamer connection lost reconnecting...");
        dispatch(showErrorPanel("Connection lost reconnecting..."));
    }

    function onChallengeReconnected(connectionId) {
        console.assert(challengeConnection.state === HubConnectionState.Connected);
        challengeConnected = true;
        //Reconnect to hub and get missed info
        console.log("Connection reestablished!");

        getAvailableChallenges();

        challengeConnection.invoke("SubscribeToCurrentLobbies",null)
            .then((result) => onLobbiesInfo(result))
            .catch(err => { console.log("Error when challenge reconnected and invoking SubscribeToCurrentLobbies") }); 

        dispatch(refreshUser());
        
        dispatch(flashInfoPanel("Connection reestablished!"));
    }

    function onStreamerReconnected(connectionId) {
        console.assert(streamerConnection.state === HubConnectionState.Connected);
        streamerConnected = true;
        //Reconnect to hub and get missed info
        console.log("Streamer connection reestablished!");

        if (currentStreamerNick !== null) {
            streamerConnection.invoke("SubscribeToStreamerLobby", currentStreamerNick)
                .then((result) => onStreamerLobbyInfo(result))
                .catch(err => onStreamerLobbyError(err));
        }
        dispatch(flashInfoPanel("Connection reestablished!"));
    }

    function onChallengeConnectionError(err) {
        challengeConnected = false;
        console.error('SignalR Challenge Connection Error, will try to reconnect. Error= ', err)
        dispatch(showErrorPanel("Could not connect to backend, retrying..."));

    }

    function onStreamerConnectionError(err) {
        streamerConnected = false;
        currentStreamerNick = null;
        console.error('SignalR Streamer Connection Error, will try to reconnect. Error= ', err)
        dispatch(showErrorPanel("Could not connect to backend, retrying..."));

    }

    function onChallengeClosed(err) {
        console.log("Challenge connection closed with err="+err);
        console.assert(challengeConnection.state === HubConnectionState.Disconnected);
        if (challengeConnected) {
            challengeConnected = false;
            dispatch(showErrorPopupWithAction("Lost connection, click to reload page","Reload"));
        }
        else {
            console.log("Challenge connection closed on purpose");
        }
    }

    function onStreamerClosed(err) {
        console.log("Streamer connection closed with err=" + err);
        console.assert(streamerConnection.state === HubConnectionState.Disconnected);
        if (streamerConnected) {
            streamerConnected = false;
            dispatch(showErrorPopupWithAction("Lost connection, click to reload page", "Reload"));
        }
        else {
            console.log("Streamer connection closed on purpose");
        }
    }


    function getAvailableChallenges() {
        var region = getState().userReducer.userInfo.region;
        if (region === undefined || region === null)
            region = Region.EUROPE;

        console.log("!!!!!!!!!!!!!!Region=" + region);
        challengeConnection.invoke("GetAvailableChallenges", region);
    }

    function switchRegion(region) {       
        challengeConnection.invoke("GetAvailableChallenges", region);
    }

    function subscribeLobby(id) {
        challengeConnection.invoke("SubscribeToLobby", id)
            .then((result) => onLobbyInfo(result))
            .catch(err => onLobbyError(err));
    }

    function subscribeStreamerLobby(nick) {
        streamerConnection.invoke("SubscribeToStreamerLobby", nick)
            .then((result) => onStreamerLobbyInfo(result))
            .catch(err => onStreamerLobbyError(err));
    }

    function unsubscribeStreamerLobby(challengeId) {
        streamerConnection.invoke("UnsubscribeToStreamerLobby", challengeId)
          
    }

    function unsubscribeLobby(id) {
        challengeConnection.invoke("UnsubscribeToLobby", id)
            .catch(err => console.error(err));
    }

    function onLobbyInfo(res) {
        console.log("Info recieved from LobbyInfo" + res);
        if (res.id !== 0) {
            const userId = getState().userReducer.user.id;
            dispatch(lobbyInfo(res, userId));
           
        }
        else {
            console.log("No challenge found or user not logged in");
        }
    }

    function onLobbiesInfo(res) {
        console.log("Info recieved from onLobbiesInfo" + res);
        if (res.length !== 0) {
            const userId = getState().userReducer.user.id;
            dispatch(lobbiesInfo(res, userId));
        }
        else {
            console.log("No challenges found or user not logged in");
        }
    }

    function onStreamerLobbyInfo(res) {
        console.log("Info recieved from StreamerLobbyInfo" + res);
        if (res.streamerId !== 0) {
            currentStreamerNick = res.streamerNickname;
            dispatch(lobbyInfo(res.challenge, res.streamerId));
        }
        else {
            console.log("Streamer not playing or not found");
            currentStreamerNick = null;
            dispatch(lobbyNotFound());
        }
    }

    function onLobbyError(err) {
        console.log("Error subscribing to lobby because: " + err);
        dispatch(lobbyNotFound());
    }

    function onStreamerLobbyError(err) {
        console.log("Error subscribing to streamer lobby because: " + err);
        currentStreamerNick = null;
        dispatch(lobbyNotFound());
    }

    function onAvailableChallenges(res) {
        console.log("Info recieved from AvailableChallenges" + res);
        dispatch(availableChallenges(res));
    }

    function onSpecialChallenges(res) {
        console.log("Info recieved from SpecialChallenges" + res);
        dispatch(availableSpecialChallenges(res));
    }

    function onCurrentTournament(res) {
        console.log("Info recieved from CurrentTournament" + res);
        dispatch(currentTournament(res));
    }

    function onChallengeUpdate(res) {
        console.log("Info recieved from onChallengeUpdate " + res.challengeId);
        const userId = getState().userReducer.user === undefined ? undefined : getState().userReducer.user.id;        
        dispatch(challengeUpdate(res, userId));
        if (res.challengeReady === true) { //Challenge is full and will start
            dispatch(challengeRemoveWithDelay(res.challengeId));
        }
        if (res.newChallengeCreated === true) {
            dispatch(challengeAdd(res.newChallenge));
        }
    }    

  /*  function onLobbyConnected(id) {
        console.log("Lobby connected!!");
        lobbyConnected = true;
        lobbyConnection.invoke("JoinLobby", id)
            .catch(err => console.error(err));
        lobbyConnection.invoke("SendLobbyInfo", id)
            .catch(err => console.error(err));
    }

    function onLobbyConnectionError(err) {
        console.error('SignalR Lobby Connection Error: ', err)
        dispatch(showErrorPopup("Lost connection, please reload page"));
    }       

    function onLobbyClosed() {
        console.log("Lobby connection closed");
        lobbyConnected = false;
    }*/

    function onJoinedChallenge(res) {
        console.log("Info recieved from onJoinedChallenge" + res);
        const userId = getState().userReducer.user === undefined ? undefined : getState().userReducer.user.id;        
        dispatch(joinedChallenge(res,userId));
    }

    function onLeftChallenge(res) {
        console.log("Info recieved from onLeftChallenge" + res);
        const userId = getState().userReducer.user === undefined ? undefined : getState().userReducer.user.id;        
        dispatch(leftChallenge(res,userId));
    }

    function onChallengeEvent(event) {
        dispatch(challengeEvent(event)); //Will be caught by saga to refresh user and full game refresh
    }

    function onStreamerChallengeEvent(event) {
        dispatch(streamerChallengeEvent(event)); //Will be caught by saga to do full game refresh
    }

    function onChallengeParticipantEvent(event) {
        dispatch(challengeParticipantEvent(event)); //Will only update store with info from this event
    }

    function onStreamerChallengeParticipantEvent(event) {
        dispatch(streamerChallengeParticipantEvent(event)); //Will only update store with info from this event
    }

    function onChallengeResult(result) {
        dispatch(challengeResult(result)) ////Will be caught by saga to refresh user and full game refresh
    }

    function onStreamerChallengeResult(result) {
        dispatch(streamerChallengeResult(result)) ////Will be caught by saga for full game refresh
    }

    function onChallengeError(error) { ////Will be caught by saga to refresh user and full game refresh
        dispatch(challengeError(error))
    }

    function onStreamerChallengeError(error) { ////Will be caught by saga to refresh user and full game refresh
        dispatch(streamerChallengeError(error))
    }

    function onChallengeParticipantError(error) {//Will only update store with info from this event
        dispatch(challengeParticipantError(error))
    }

    function onStreamerChallengeParticipantError(error) {//Will only update store with info from this event
        dispatch(streamerChallengeParticipantError(error))
    }

    function onStreamerJoinedChallenge(res) {
        console.log("Streamer joined challenge");
        console.log("Currently connected to " + currentStreamerNick);
        const streamer = getState().challengeReducer.streamer;    
        console.log("Currently following " + streamer);
        if (res.nickName === streamer) {
            //Streamer joined challenge
            subscribeStreamerLobby(streamer);
        }
    }

    function onStreamerLeftChallenge(res) {
        console.log("Streamer left challenge");
        console.log("Currently connected to " + currentStreamerNick);
        const streamer = getState().challengeReducer.streamer;
        console.log("Currently following " + streamer);
        if (res.nickName === streamer) {
            //Streamer left challenge
            unsubscribeStreamerLobby(res.challengeId);
            dispatch(closeStreamerLobby(res.challengeId));
        }
    }

    function onServiceStatus(status) {
        if (status.isOperational) {
            dispatch(hideInfoPanel());
        }
        else if (!status.isOperational && status.displayOnNotOperational)  {
            dispatch(showErrorPanel(status.disturbanceMessage));
        }
    }

    function onDepositEvent(event) {
        dispatch(stripeDepositVerified(event.amout, event.totalAmount));
    }
}

