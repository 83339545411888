import React from 'react'
import { Link } from 'react-router-dom';

const Privacy = () => {
    return (

        <div>
            <br />
            <div className="container-fluid">
                <h2 className="panel-title">Privacy policy</h2>
                <div className="panel faq">
                    <div className="container-fluid">
                    <p dir="ltr">KHEOX Consulting Sàrl (hereinafter “KHEOX Consulting”, “we”) is personally responsible for the personal data processed within the company. KHEOX Consulting determines the purpose of the treatment and the way in which treatment is to be done.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Contact information for the data controller and data protection agent is at the end of this privacy policy.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">WHAT IS A PERSONAL DUTY AND WHAT CONTAINS PROCESSING OF PERSONAL DATA?</p>
                    <p dir="ltr">Personal data are all kinds of information that can be linked directly or indirectly to a physical person in your life. Examples of personal data are name, paypal-account, e-mail address, phone number, pictures and IP-number. Processing of personal data means all types of personal data handling, such as collection, registration, storage, transfer and deletion.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">WHAT PERSONAL DATA DO WE TAKE?</p>
                    <p dir="ltr">KHEOX Consulting treats many different personal data and which they depend on in what property you came into contact with us.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">GAMERS</p>
                    <p dir="ltr">If you are registering on wgc.gg as a user we process the personal information we need to receive your payments and make payments. For example, name, address, e-mail address, phone number, bank account number, image, short presentation about you. In some cases we are in need to make a KYC and request additional information from you. The processing is necessary for us to exercise our legal obligations to pay your winnings to you as well as to report to the authorities, as well as to fulfill our mission of our platform as services.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">HOW AND FROM WHAT SOURCES ARE THE COMPOUNDS INCLUDED?</p>
                    <p dir="ltr">We collect information about you when you register on our website, mail or through agreements. We collect the information you feed yourself into the system or send to us. For example, be name, social security number, address, e-mail address and professional title. If you are registered user with us through our system, further information about you will be generated in our system, such as your salary specifications, your employment number and your control details. We will also collect information about you when you contact us regarding matters related to your account with us.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">In addition to the information you provide to us or as we collect by using our services or corresponding with us, we may also collect information from a third party. Information we collect from third parties is, for example, credit information from credit reporting companies and address data from public records.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">We also collect information about you using cookies on our websites.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">WHAT DO WE USE THE DATA TO?</p>
                    <p dir="ltr">In order for us to process your personal information, there is a need for support in current legislation, a so-called legal basis. In order for our processing of personal data to be legally required, one of the following grounds is met.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">The processing is necessary in order for us to fulfill the agreement with you.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">The processing is necessary in order for us to fulfill a legal obligation that owes us.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">The processing is necessary to meet the legitimate interests of KHEOX Consulting and that your interest in privacy protection does not weigh heavier (a so-called balance of interest).</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Below is a summary of the purposes for which we process your personal data and for what legal reason we do this.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">In order to offer our service to enable individuals to use our platform.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">PURPOSE</p>
                    <p dir="ltr">In order to offer our service to enable individuals to use our platform.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Categories of personal data.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Name, address, e-mail address, paypal-account, telephone number, bank account number, credit information, salary details, correspondence and photo.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Examples of treatment:</p>
                    <p dir="ltr">Payment of bank account remuneration after cooperation has been completed.<br />
                    Legal basis: Compliance and legal obligation. In some cases we would need to require more information from you before we payout winnings.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">WHY CAN WE COMPLETE TO DELIVER YOUR PERSONAL DATA?</p>
                    <p dir="ltr">In some cases, we may share your personal information with other legal entities.<br />
                    Companies within the same group as KHEOX Consulting.<br />
                    As we have certain Group-wide functions within our Group, we may share your personal data with other companies within the Group.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Other legal entities who are independent personally responsible</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">We share your personal information with other independent personal data administrators, ie companies or authorities that decide on how to process the information provided to them. Thus, when personal data is shared with other independent personal data administrators, their privacy policies and procedures apply.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">The independent personally responsible personally we may share your personal information with are:<br />
                    Government authorities, the Police and the Work Environment Authority, if we are required to do so by law, in order to fulfill our employer responsibilities or suspected crime.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Personal Data Assistants<br />
                    In order for us to offer our services and fulfill our obligations under the agreement with you, we will share some of your personal information with companies that help us with marketing, IT solutions and more. These companies are personal information assistants and we sign an agreement with them to ensure that they only process your information according to our instructions and for the purpose they were collected.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Third country suppliers</p>
                    <p dir="ltr">We always strive to retain the personal data we process in the EU / EEA. However, some of our suppliers operate outside this area, in a so-called third country. If we transfer your personal data to third countries, we take appropriate safeguards to ensure that the level of protection is the same as within the EU / EEA. Examples of such safeguards are approved code of conduct in the recipient country, standard contract clauses, binding company internal rules and privacy shield.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">HOW LONG WE DO WE SAVE YOUR PERSONAL DATA?</p>
                    <p dir="ltr">We save your personal information as long as it is necessary to fulfill the purpose for which the data was collected. The data we collect and generated when using our services is treated for different purposes, therefore they are also stored for a long time depending on what they are used for and how our legal obligations look. For example, be as long as we need to fulfill our mission towards you, in order to handle it, it is required to meet certain statutory storage times for, for example, accounting.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">HOW DO WE PROTECT YOUR PERSONAL DATA?</p>
                    <p dir="ltr">We take appropriate technical and organizational security measures to ensure that all information we treat is protected from unauthorized or unauthorized access, but also from loss, destruction or other damage. Only those administrators who have access to our systems have access to your personal data and their handling of the information is strictly regulated in internal control documents.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">YOUR RIGHTS REGISTERED</p>
                    <p dir="ltr">You as registered have a number of rights attached to our processing of your personal data under current legislation. Below we list your rights and what they mean. At the end of the document, our contact information is available for your rights.</p>
                    <p dir="ltr">We will process your request without unnecessary delay and usually within one month. Please note that we may need additional information from you to ensure your identity and make sure that the request comes from the right person.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Right of access (register extract) </p>
                    <p dir="ltr">You are entitled to request information about which of your personal data we process, a so-called registry extract. Requests for registries must be made in writing, signed by you and posted to the address at the end of this document. The registry extract will be sent to your people's mailing address.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Right to rectification</p>
                    <p dir="ltr">If the personal data associated with you is incorrect, you are entitled to request correction of these. You also have the right to supplement such personal information as you deem missing and which are relevant to the purpose of the treatment. You also have the option of updating your information on our website in logged-in mode.</p>
                    <p dir="ltr">If personal information is corrected upon your request, we will inform those we have provided the information for corrections, however, only if such notification will not prove impossible or cause disproportionate effort. We will also ask you to inform you about who we provided the information to.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Right to erasure</p>
                    <p dir="ltr">You are entitled to request that your personal information with us be deleted. There are some cases where personal data must be deleted:
                    <br />if the data is no longer needed for the purposes for which they were collected
                    <br />if the treatment is based solely on consent and the consent is revoked by you
                    <br />If the treatment is for direct marketing and you oppose such treatment
                    <br />if you oppose a treatment that takes place with interest weighting as a legal basis and there are no justifiable reasons that weigh heavier than your interest
                    <br />if personal data is processed in violation of current legislation
                    <br />if deletion is required to fulfill a legal obligation
                    <br />if your personal data is collected about a child under the age of 13 who you have the parent responsibility for and collection in connection with the provision of information society services.
                    </p>
                    <p dir="ltr">There may be legal obligations that prevent us from deleting certain personal data. These obligations may arise from, for example, accounting, tax or consumer law. It may also be that processing is necessary for us to determine, enforce or defend legal claims.</p>
                    <p dir="ltr">If personal information has been deleted at your request, we will inform those we have disclosed the data to the deletion, however, only if such notification will not prove impossible or cause disproportionate effort. We will also ask you to inform you about who we provided the information to.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Right to limit treatment</p>
                    <p dir="ltr">You may, in some cases, request that the personal data processing we make will be limited. This means that personal data will be marked to be used for some limited purposes only.</p>
                    <p dir="ltr">The right to a limitation applies, for example, if you requested a correction of your personal information. In these cases, you may also request that our processing of personal data be restricted during the investigation of the accuracy of personal data. You may also claim the right if you oppose a treatment that is being conducted with interest weighting as a legal basis and you want the treatment limited during the investigation of whose legitimate reasons weigh heaviest.</p>
                    <p dir="ltr">If personal data is limited to your request, we will inform those we have disclosed the information to a limitation, however, only if such notification will not prove impossible or cause disproportionate effort. We will also ask you to inform you about who we provided the information to.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Right to object</p>
                    <p dir="ltr">You are entitled to object to the processing of personal data that we make based on interest balance. In such an objection, we may continue processing only if we can show that there are compelling reasons for the processing of personal data, and these reasons weigh heavier than your interests. We may also continue to process the data to determine, exercise or defend legal claims.</p>
                    <p dir="ltr">If your personal data is processed for direct marketing, you are always entitled to object to the treatment at any time. Direct marketing refers to all types of outreach marketing measures, such as by mail, email and text message. If you oppose direct marketing, we will discontinue processing of your personal information for that purpose.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">Right to data portability</p>
                    <p dir="ltr">If you have provided personal information to us, you may, under certain conditions, have the right to obtain and use these personal data with another personally responsible person. In order for you to avail of this right to data portability, the request must relate to such information as you have provided to us and which we treat with the consent of you or for the performance of an agreement. Thus, the right to data portability does not apply to personal data treated with interest-bearing or legal obligation.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">COMPLAINT</p>
                    <p dir="ltr">If you believe your personal data is processed in violation of current legislation, you are entitled to file a complaint with KHEOX Consulting or to the Privacy Authority.</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">MODIFICATION OF INTEGRITY POLICY</p>
                    <p dir="ltr">KHEOX Consulting reserves the right to amend and update the privacy policy as necessary. The latest version of the Privacy Policy will always be published on our website. In the case of changes that are of vital importance to our processing of personal data, you will be informed by e-mail as a self-employed person in good time before the update enters into force.</p>
                    <p dir="ltr">&nbsp;</p>

                    <p dir="ltr">ABOUT COOKIES</p>
                    <p dir="ltr">A cookie is a small text file consisting of letters and numbers and stored on your browser or device when you visit our website www.wgc.gg. Cookies are used to improve your user experience and to analyze how to use our service. We also use cookies to target marketing to you.</p>
                    <p dir="ltr">&nbsp;</p>

                    <p dir="ltr">CONTACT DETAILS FOR PERSONAL WARRANTIES</p>
                    <p dir="ltr">Privacy Manager:</p>
                    <p dir="ltr">KHEOX Consulting Sàrl 
                    <br />Impasse de la Maigroge 2 
                    <br />2068 Hauterive/Switzerland</p>
                    <p dir="ltr">Email : hello@wgc.gg</p>
                    <p dir="ltr">&nbsp;</p>
                </div>
            </div>
        </div>
        </div>
        );
}

export default Privacy