import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { required, minLength10 } from "../validations";
import { renderFieldWithShake } from "../../utils/renderFields";

import { redeemCode } from "../../actions/userActions";

var panelStyle = { padding: '20px' }

class RedeemCode extends React.Component {

    handleFormSubmit(values) {
        if (this.props.valid) {                   

            this.props.redeem(
               values.code
            );
        }
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, valid, working } = this.props;

        return (

            <div>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel settings-form deposit" style={panelStyle}>
                                <img className="deposit-hero" src="images/battle-art.png" alt="Challenge.gg Redeem" />
                                <form id="reddem-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Field
                                                name="code"
                                                type="text"
                                                component={renderFieldWithShake}
                                                label="Enter your value coupon"
                                                placeholder=""
                                                validate={[required, minLength10]}
                                            />
                                        </div>
                                       
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className={'button warning' + (!valid || pristine || submitting ? ' disabledBtn' : '')} name="button" disabled={!valid || pristine || submitting}>
                                                {working ? 'Working...' : 'REDEEM COUPON'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { working, type, message } = state.userReducer;
    return {
        working, type, message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        redeem: (code) => dispatch(redeemCode(code)),
    };

}

RedeemCode = reduxForm({
    form: 'redeem'
})(RedeemCode);

RedeemCode = connect(mapStateToProps, mapDispatchToProps)(RedeemCode);

export default RedeemCode;