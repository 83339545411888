
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux'
import css from './menu.module.scss';
import burgerStyle from './burger.module.scss';
import Button from './button';
import { useState } from 'react';
import MobileMenu from './mobileMenu';
import { HcpType } from '../../../../utils/const';
import { showSingle,showUpTo18,showUpTo54 } from '../../../../utils/hcp';

const Header = () => {
    //navbar scroll when active state
    const [menuOpen, setMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };

    const extracurricular = useSelector(state => state.challengeReducer.specialChallenges);
    const user = useSelector(state =>  state.userReducer.userInfo);
    const isLoggedIn =  useSelector(state =>  state.userReducer.loggedIn);

    const lobby = getLobbyLink(isLoggedIn,user,extracurricular);


    

  
    return (
      <>
        <nav className={`${css.menu} ${menuOpen ? css.menu__open : ''}`}>
          <div className={css.mainMenu}>
            <div className={css.mainMenu__container}>
              <Link to="/extracurricular" className={css.logo} aria-label="Accéder à la page d'accueil">
                <img src='/images/extracurricular/logos/logo_excr.svg' alt="" height={50} className="logo" />
              </Link>
  
              <div className={css.menu__links}>
                <HashLink smooth to="/extracurricular#challenges">Challenges</HashLink>
                <Link to={lobby}>Lobby</Link>
                <Link to="/extracurricular/rankings">Rankings</Link>
                <HashLink smooth to="/extracurricular#howtoplay">How To Play</HashLink>
              </div>
              <button
                className={`${burgerStyle.burger} ${menuOpen ? burgerStyle.burger__closed : ''}`}
                onClick={toggleMenu}
                aria-label="Ouvrir la navigation mobile"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </nav>
        <MobileMenu open={menuOpen} toggleMenu={toggleMenu} />
      </>
    );
  };


 export const getLobbyLink = (isLoggedIn,user,extracurricular) => {
    if(extracurricular===undefined || extracurricular.length===0){
        return '/extracurricular';
    }

    const httpSingle = extracurricular.find(challenge => challenge.hcpType === HcpType.SINGLE);
    const httptUpTo18 = extracurricular.find(challenge => challenge.hcpType === HcpType.HCP_1_18);
    const httpUpTo54 = extracurricular.find(challenge => challenge.hcpType === HcpType.HCP_19_36);
    
    if(isLoggedIn){ 
        //Has user joined any of the challenges?
        const joined = extracurricular.find(challenge => challenge.isParticipating);
        if(joined){
            return `/extracurricular/lobby/${joined.id}`;
        }
        else{
          // if not show best match
          if (showSingle(user)) {
            return `/extracurricular/lobby/${httpSingle?.id}`;
          }
          else if (showUpTo18(user)) {
            return `/extracurricular/lobby/${httptUpTo18?.id}`;
          }
          else if (showUpTo54(user)) {
            return `/extracurricular/lobby/${httpUpTo54?.id}`;
          }
          else {
            return `/extracurricular`;
          }
        }
    }
    else{
      //if not logged in, show first since we do not know users hcp
      return `/extracurricular/lobby/${httpSingle?.id}`; 
    }
}
  
  export default Header;