import { takeEvery, call, put, delay, select, fork, takeLatest, all  } from "redux-saga/effects";
import { push } from 'connected-react-router';
import { config } from "../config/config";
import { Region } from "../utils/const";
import { authHeader } from "../utils/authHeader";
import { openAlert, openSuccessAlert, openErrorAlert, closeAlert, errorGradient, successGradient } from "../actions/dialogActions";
import { translateDepositSuccededResult, translateWithdrawRequestSuccededResult, translateRedeemCodeSuccededResult } from "../utils/translate";
import { toast } from "react-toastify";
import LogRocket from 'logrocket';
import { history } from "../store/configureStore";



export default function* rootSaga() {

    yield takeEvery("LOGIN_USER", loginWorker);
    yield takeEvery("LOGOUT_USER", logoutWorker);
    yield takeEvery("CLEAR_USER", clearWorker);
    yield takeEvery("GET_USER", getUserWorker);
    yield takeEvery("GET_MATCHES_PLAYED", getMatchesPlayedWorker);
    yield takeEvery("GO_TO", gotoWorker);
    yield takeEvery("CHALLENGE_SIGNUP", signupWorker);
    yield takeEvery("CHALLENGE_LEAVE", leaveWorker);
    yield takeEvery("CLOSE_LOBBY", closeLobbyWorker);
    yield takeEvery("GET_ACTIVE_CHALLENGE", getActiveChallengeWorker);
    yield takeEvery("GET_FINISHED_CHALLENGE", getFinishedChallengeWorker);
    yield takeEvery("CHALLENGE_REMOVE_WITH_DELAY", removeWithDelayChallengeWorker);
    yield takeEvery("CHALLENGE_EVENT", challengeEventWorker);
    yield takeEvery("STREAMER_CHALLENGE_EVENT", streamerChallengeEventWorker);
    yield takeEvery("CHALLENGE_RESULT", challengeResultWorker);
    yield takeEvery("STREAMER_CHALLENGE_RESULT", streamerChallengeResultWorker);
    yield takeEvery("REGISTER_USER", signup);
    yield takeEvery("REGISTER_USER_STEP_TWO", signupStep2);
    yield takeEvery("REGISTER_USER_STEP_THREE", epicVerificationWorker);
    yield takeEvery("EPIC_VERIFICATION_2FA", epicVerification2FAWorker);
    yield takeEvery("EPIC_VERIFICATION_CAPTCHA", epicVerificationCaptchaWorker);
    yield takeEvery("VERIFY_EMAIL", verifyEmailWorker);
    yield takeEvery("RESEND_EMAIL_VERIFICATION_CODE", resendEmailVerificationCodeWorker);
    yield takeEvery("FORGOT_PASSWORD", forgotPasswordWorker);
    yield takeEvery("RESET_PASSWORD", resetPasswordWorker);
    yield takeEvery("CHALLENGE_ERROR", challengeErrorWorker);
    yield takeEvery("STREAMER_CHALLENGE_ERROR", streamerChallengeErrorWorker);
    yield takeEvery("CHALLENGE_PARTICIPANT_EVENT", challengeParticipantEventWorker);
    yield takeEvery("STREAMER_CHALLENGE_PARTICIPANT_EVENT", streamerChallengeParticipantEventWorker)
    yield takeEvery("CHALLENGE_PARTICIPANT_ERROR", challengeParticipantErrorWorker);
    yield takeEvery("STREAMER_CHALLENGE_PARTICIPANT_ERROR", streamerChallengeParticipantErrorWorker);

    yield takeEvery("SHOW_POPUP", showPopupWorker)

    yield takeEvery("GET_LEADERBOARD", leaderboardWorker);
    yield takeEvery("GET_PERSONAL_POSITION", personalPositionWorker);
    yield takeEvery("GET_STATISTICS", statisticsWorker);
    yield takeEvery("GET_EXTRACURRICULAR", extracurricularWorker);

    yield takeEvery("GO_TO_URL", gotoUrlWorker);

    yield takeEvery("GET_TRANSACTIONS", getTransactionsWorker);
    yield takeEvery("UPDATE_USER", updateUserWorker);

    yield takeEvery("UPLOAD_PROFILE_PICTURE", uploadProfilePictureWorker);

    yield takeEvery("DEPOSIT_STRIPE", depositStripeWorker);
    yield takeEvery("DEPOSIT_PAYPAL", depositPayPalWorker);
    yield takeEvery("VERIFY_DEPOSIT", verifyDepositWorker);
    yield takeEvery("CANCEL_STRIPE_DEPOSIT", cancelStripeDepositWorker);
    yield takeEvery("CANCEL_PAYPAL_DEPOSIT", cancelPayPalDepositWorker);

    yield takeEvery("VERIFY_STRIPE_DEPOSIT", verifyStripeDepositWorker);
    yield takeEvery("STRIPE_DEPOSIT_VERIFIED", stripeDepositVerifiedWorker);
    yield takeEvery("WITHDRAW", withdrawWorker);

    yield takeEvery("REDEEM_CODE", redeemCodeWorker);

    yield takeEvery("GET_CARUSEL", getCaruselWorker);

    yield takeEvery("REQUEST_SUBSCRIPTION_TO_STREAMER_LOBBY", requestSubscriptionToStreamerWorker);
    yield takeEvery("CLOSE_STREAMER_LOBBY", closeStreamerLobby);

    
    yield takeEvery("TOURNAMENT_SIGNUP", tournamentSignupWorker);
    yield takeEvery("TOURNAMENT_LEAVE", tournamentLeaveWorker);

    yield takeEvery("REPORT_SCORE", reportScoreWorker);
    yield takeEvery("CREATE_PRIVATE_CHALLENGE", createPrivateChallengeWorker);
    yield takeEvery("GET_PRIVATE_CHALLENGE", getPrivateChallengeWorker)
    yield takeEvery("EDIT_PRIVATE_CHALLENGE", editPrivateChallengeWorker);
    yield takeEvery("DELETE_PRIVATE_CHALLENGE", deletePrivateChallengeWorker);

    yield takeEvery("UPDATE_REGION", updateRegionWorker);

}


function* updateRegionWorker(action) {
    const { region } = action;
    const isLoggedIn = yield select(state => state.userReducer.loggedIn);
    if (isLoggedIn) {
        const userInfo = yield call(updateRegion, region);
        yield put({ type: "USER_INFO", userInfo });

    }
    else {
        const userInfo = { region: region };
        yield put({ type: "USER_INFO", userInfo });
    }
    localStorage.setItem('wgc_region', region);

    //Tell SignalR to fetch Challenges for selected region
    yield put({ type: "REGION_UPDATED", region });
}

function* gotoUrlWorker(action){
    const { url, from } = action;

    //history.push('/'); //will set what is hit when using back button.

    if (from !== undefined) {
        yield put(push(url, { from: from, refresh: true }))
    }
    else {
        yield put(push(url, { refresh: true }))
    }
}

function* loginWorker(action) {
    console.log("login worker");

    try {
        yield put({ type: "IS_WORKING" });
        const { username, password } = action;
        const user = yield call(login, username, password);
        yield put({ type: "USER_LOGGED_IN", user });

        if ((yield select(state => state.router.location.state)) !== undefined) {
            var prevPage = yield select(state => state.router.location.state.from.pathname);
            if (prevPage === undefined)
                prevPage = yield select(state => state.router.location.state.from);

            yield call(getUserWorker, { forceRefresh: false }); 
            yield put(push(prevPage, { refresh: true }));
        }
        else {
            yield put(push('/', { refresh: true }));
        }
       

        LogRocket.identify(user.id, {
            email: user.username,
        });
    }
    catch (e) {
        console.log("Login failed," + e)
        yield put(openErrorAlert({ title: e.message}));
        yield put({ type: "LOGIN_FAILED", payload: e });
    }
}

function* logoutWorker(action) {
    yield call(clearWorker);
    yield put(push('/'));
   
}

function* clearWorker(action) {
    yield call(logout);
    yield put({ type: "UNSUBSCRIBE_CHALLENGES" });
    yield put({ type: "LOGOUT" });
}



function* signup(action) {
    console.log('signup');

    try {
        yield put({ type: "IS_WORKING" });
        const { firstName, lastName, playerType, handicap, countryIso, phoneNumber, username, password, confirmPassword} = action;
        const user = yield call(registerUser, firstName, lastName, playerType, handicap, countryIso, phoneNumber, username, password, confirmPassword);
        yield put({ type: "USER_LOGGED_IN", user });

        if ((yield select(state => state.router.location.state)) !== undefined) {
            var prevPage = yield select(state => state.router.location.state.from.pathname);
            if (prevPage === undefined)
                prevPage = yield select(state => state.router.location.state.from);
            yield call(getUserWorker, { forceRefresh: false }); 

            if (prevPage.startsWith('/lobby'))
                yield put({ type: "UNSUBSCRIBE_CHALLENGES" }); //Since we will be redirected to lobby and already conneced with no auth. So we need to force reconnect. If we would go to main page we would connect again
            yield put(push(prevPage, { refresh: true }))
        }
        else {
            yield put(push('/', { refresh: true }))
        }

        LogRocket.identify(user.id, {
            email: user.username,
        });

    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('registration failed; ', e);
        yield put(openErrorAlert({title: e.message }));
        yield put({ type: "REGISTRATION_FAILED", payload: e });
    }
}

function* signupStep2(action) {
    console.log('signup, step 2');

    try {
        const { firstName, lastName, address1, address2, zip, city, state, countryIso } = action;
        // remove comment to enable actual submit to server
        const userInfo = yield call(registerUserStepTwo, firstName, lastName, address1, address2, zip, city, state, countryIso);
        yield put({ type: "REGISTER_USER_STEP_TWO_SUCCESS", userInfo});

        yield put(push('/verification'));
    }
    catch (e) {
        console.log('registration failed: ', e);
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "REGISTRATION_FAILED", payload: e });
    }
}

function* epicVerificationWorker(action) {
    console.log('verification, step 3');
    try {
        yield put({ type: "IS_WORKING" });
        const { email, password } = action;
        const payload = yield call(epicVerification, email, password);
        yield put({ type: "VERIFICATION_SUCCESS", verificationReply: payload });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
    }
    catch (e) {
        console.log('Epic verification failed: ', e);
        yield put({ type: "FINISHED_WORKING" });
        if (typeof e.errorCode !== 'undefined' && e.errorCode === 431) {
            yield put({ type: "2FA_VERIFICATION", cookies: e.cookies });
            yield put(push('/verification_step2'));
        }
        else if (typeof e.errorCode !== 'undefined' && e.errorCode === 412) {
            yield put({ type: "CAPTCHA_VERIFICATION" });
            yield put(push('/verification_captcha'));
        }
        else {
            yield put(openErrorAlert({ title: e.message }));
            yield put({ type: "REGISTRATION_FAILED", payload: e });
        }
    }
}

function* epicVerification2FAWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { code } = action;
        const cookies = yield select(state => state.userReducer.cookies);
        const payload = yield call(epicVerification2FA, code, cookies);
        yield put({ type: "VERIFICATION_SUCCESS", verificationReply: payload });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        if (typeof e.errorCode !== 'undefined' && e.errorCode === 412) {
            yield put({ type: "CAPTCHA_VERIFICATION" });
            yield put(push('/verification_captcha'));
        }
        else {
            yield put(openErrorAlert({ title: e.message }));
            yield put({ type: "REGISTRATION_FAILED", payload: e });
        }
    }

}

function* epicVerificationCaptchaWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { code } = action;
        const payload = yield call(epicVerificationCaptcha, code);
        yield put({ type: "VERIFICATION_SUCCESS", verificationReply: payload });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "REGISTRATION_FAILED", payload: e });
       
    }

}

function* verifyEmailWorker(action) {
    try {
        const { id, code } = action;
        const payload = yield call(verifyEmail, id, code);
        yield put({ type: "EMAIL_VERIFICATION_SUCCESS" });
        //yield put(push('/'));
        yield put(openAlert({
            icon: 'success',
            title: 'Email Verified',
            background: successGradient,
            confirmButtonColor: 'rgb(80, 227, 194)',
            confirmButtonText: 'Ok',
            onClose: 'GoToHome',
        }));
    }
    catch (e) {
        yield put({ type: "EMAIL_VERIFICATION_FAILED", payload: e });
        yield put(openErrorAlert({ title: e.message }));       
    }
}

function* resendEmailVerificationCodeWorker(action) {
    try {       
        yield call(resendEmailVerificationCode)
        yield put({ type: "EMAIL_VERIFICATION_CODE_RESENT" });
        yield put(openSuccessAlert({ title: "Email verification code resent" }));
    }
    catch (e) {
        yield put({ type: "EMAIL_VERIFICATION_CODE_RESENT_FAILED", payload: e });
        yield put(openErrorAlert({ title: e.message }));
    }
}

function* forgotPasswordWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { email } = action;
        yield call(forgotPassword, email);
        yield put({ type: "FORGOT_PASSWORD_EMAIL_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        yield put(openSuccessAlert({ title: "An email has been sent to you, please follow the instructions to reset you password!" }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "FORGOT_PASSWORD_FAILED", payload: e });

    }
}

function* resetPasswordWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { id, code, password } = action;
        console.log("id="+id);
        yield call(resetPassword, id, code, password);
        yield put({ type: "RESET_PASSWORD_EMAIL_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        yield put(openSuccessAlert({ title: "Password has been reset!" }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "RESET_PASSWORD_FAILED", payload: e });

    }
}

function* getUserWorker(action) {
    console.log("getUser worker");

    try {
        const forceRefresh = action.forceRefresh;
        const isUserInfoFresh = yield select(state => state.userReducer.userInfoFresh);
        const isLoggedIn = yield select(state => state.userReducer.loggedIn);

        if (isLoggedIn) {
            if (forceRefresh || isUserInfoFresh === false) {
                const userInfo = yield call(getUser);
                yield put({ type: "USER_INFO", userInfo });
            }
            else {
                yield put({ type: "USER_INFO_ALREADY_LOADED" });
            }
        }
        else {
            var current_region = localStorage.getItem('wgc_region');
            if (current_region===null) {
                current_region = Region.EUROPE;
            }
            const userInfo = { region: current_region };
            yield put({ type: "USER_INFO", userInfo });

            yield put({ type: "USER_NOT_LOGGED_IN" });
        }
    }
    catch (e) {
        console.log("GetUser failed," + e)
        yield put({ type: "GET_USER_FAILED", payload: e });
    }
}

function* getMatchesPlayedWorker(action) {
    try {
       
        const matchesPlayed = yield call(getMatchesPlayed);
        yield put({ type: "MATCHES_PLAYED", matchesPlayed: matchesPlayed });
       
    }
    catch (e) {
        console.log("getMatchesPlayed failed," + e)
        yield put({ type: "GET_MATCHES_PLAYED_FAILED", payload: e });
    }
}


function* gotoWorker(action) {

        //   yield put(showJoinChallengeConfirmDialog(action.id));
        yield put(push('/lobby/' + action.id))

}

function* signupWorker(action) {

    try {
        const isLoggedIn = yield select(state => state.userReducer.loggedIn);
        if (isLoggedIn) {
            const signupReply= yield call(signUp, action.id);      

            if (signupReply.feePaid) {
                yield put({ type: "NEW_AMOUNT", newAmount: signupReply.totalAmount });
            }
            if (!action.special) {
                yield put(push('/lobby/' + action.id));
            }
            else {
                yield put(push('/extracurricular/lobby/' + action.id));
            }
            yield put(openSuccessAlert({ title: 'Challenge joined' }));

            if (signupReply.private) {
                const userId = yield select(state => state.userReducer.userInfo.id);
                if (signupReply.creatorId !== userId) {
                    console.log("Reload invitations");
                    const invitations = yield call(getInvitedChallenges);
                    yield put({ type: "INVITED_CHALLENGES", invitations: invitations });
                }
            }
        }
        else {
            //Should not get here since check is made before call is made
            yield put(openErrorAlert({title: "Please create an account to take part in challenges!"}));
        }
    }
    catch (e) {
        console.log("Signup failed," + e)             

        yield put({ type: "CHALLENGE_SIGNUP_FAILED", payload: e });
        yield put(openErrorAlert({title: e.message}));
    }
}

function* tournamentSignupWorker(action) {
    try {
        const isLoggedIn = yield select(state => state.userReducer.loggedIn);
        if (isLoggedIn) {
            const joinedInfo = yield call(tournamentSignUp, action.id);
            yield put(openSuccessAlert({ title: 'Tournament joined' }));
        }
        else {
            //Should not get here since check is made before call is made
            yield put(openErrorAlert({ title: "Please create an account to take part in challenges!" }));
        }
    }
    catch (e) {
        console.log("Tournament signup failed," + e)

        yield put({ type: "TOURNAMENT_SIGNUP_FAILED", payload: e });
        yield put(openErrorAlert({ title: e.message }));
    }
}

function* leaveWorker(action) {
    const { id } = action;
    try {
        const leaveReply = yield call(leave, id);

        if (leaveReply.feeRefunded) {
            yield put({ type: "NEW_AMOUNT", newAmount: leaveReply.totalAmount });
        }

        if (!action.special) {
            yield put({ type: "CLEAR_ACTIVE_LOBBY", id: id });
            yield put({ type: "UNSUBSCRIBE_LOBBY", id: id });
            yield put(push('/'))
        }
        else {
            yield put({ type: "CLEAR_ACTIVE_LOBBY", id: id });
            yield put({ type: "UNSUBSCRIBE_LOBBY", id: id });
            yield put(push('/extracurricular'))
            yield put(openSuccessAlert({ title: 'Challenge left' }));
        }

        if (leaveReply.private) {
            const userId = yield select(state => state.userReducer.userInfo.id);
            if (leaveReply.creatorId !== userId) {
                console.log("Reload invitations");
                const invitations = yield call(getInvitedChallenges);
                yield put({ type: "INVITED_CHALLENGES", invitations: invitations });
            }
        }
    }
    catch (e) {
        console.log("Leave failed," + e)
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "LEAVE_FAILED", payload: e });
    }
}

function* tournamentLeaveWorker(action) {
    const { id } = action;
    try {
        const leaveInfo = yield call(tournamentLeave, id);
        //TODO How about active lobby?
        yield put(openSuccessAlert({ title: 'Tournament left' }));
    }
    catch (e) {
        console.log("Tournament Leave failed," + e)
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "TOURNAMENT_LEAVE_FAILED", payload: e });
    }
}

function* reportScoreWorker(action) {
    try {
        yield call(reportScore, action.id, action.score);
    }
    catch (e) {
        console.log("Report score failed," + e)
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "REPORT_SCORE_FAILED", payload: e });
    }
}

function* createPrivateChallengeWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const newChallenges = yield call(createPrivateChallenge, action.challenge);

        yield all(newChallenges.map((challenge) => {
            return put({ type: "CHALLENGE_ADD", newChallenge: challenge });
            }
        ));
        yield put({ type: "FINISHED_WORKING" });  
        yield put(push('/private'))
        yield put(openSuccessAlert({ title: 'Challenge created, please do not forget to join!' }));
       
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log("Create private challenge failed," + e)
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "CREATE_PRIVATE_CHALLENGE_FAILED", payload: e });
    }
}

function* editPrivateChallengeWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const editedChallenges = yield call(editPrivateChallenge, action.challengeId, action.challenge);

        yield all(editedChallenges.challengesAdded.map((challenge) => {
            return put({ type: "CHALLENGE_ADD", newChallenge: challenge });
        }
        ));

        yield all(editedChallenges.challengesRemoved.map((id) => {
            return put({ type: "CHALLENGE_REMOVE", id: id });
        }
        ));

        yield all(editedChallenges.challengesUpdated.map((challenge) => {
            return put({ type: "PRIVATE_CHALLENGE_LOAD", privateChallenge: challenge });
        }
        ));
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/private'))
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log("Edit private challenge failed," + e)
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "EDIT_PRIVATE_CHALLENGE_FAILED", payload: e });
    }
}

function* deletePrivateChallengeWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const deletedChallenges = yield call(deletePrivateChallenge, action.challengeId);

        yield all(deletedChallenges.challengesRemoved.map((id) => {
            return put({ type: "CHALLENGE_REMOVE", id: id });
        }
        ));

        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/private'))
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log("Delete private challenge failed," + e)
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "DELETE_PRIVATE_CHALLENGE_FAILED", payload: e });
    }
}

function* getPrivateChallengeWorker(action) {
    try {
        const privateChallenge = yield call(getPrivateChallenge, action.challengeId)
        yield put({ type: "PRIVATE_CHALLENGE_LOAD", privateChallenge: privateChallenge });
    }
    catch (e) {
        console.log("Get private challenge failed," + e)
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "GET_PRIVATE_CHALLENGE_FAILED", payload: e });
    }
}

function* closeLobbyWorker(action) {
    const { id, url } = action;

    yield put({ type: "CLEAR_ACTIVE_LOBBY", id: id });
    yield put({ type: "UNSUBSCRIBE_LOBBY", id: id });
    if (url !== undefined) {
        yield put(push(url));
    }
    else {
        yield put(push('/'))
    }
}

function* closeStreamerLobby(action) {
    const { id } = action;
    yield put({ type: "CLEAR_ACTIVE_LOBBY", id: id });
}

function* updateChallenge(action) {
    try {

        const userId = action.userId;
        const challengeId = action.challengeId;

        const [challenge, userInfo] = yield all([call(getChallenge, challengeId), call(getUser)]);

        yield put({ type: "USER_INFO", userInfo });
        yield put({ type: "LOBBY_INFO", challenge: challenge, userId : userId });
    }
    catch (e) {
        console.log("updateChallenge failed," + e)
        yield put({ type: "UPDATE_CHALLENGE_FAILED", payload: e });
    }
}

function* updateStreamerChallenge(action) {
    try {

        const challengeId = action.challengeId;

        const challenge = yield call(getChallenge, challengeId);

        yield put({ type: "LOBBY_INFO", challenge: challenge});
    }
    catch (e) {
        console.log("updateStreamerChallenge failed," + e)
        yield put({ type: "UPDATE_STREAMER_CHALLENGE_FAILED", payload: e });
    }
}

/**
 * Not used
 * @param {any} action
 */
function* getActiveChallengeWorker(action) {
    try {
        const userId = yield select(state => state.userReducer.user.id);

        const challenge = yield call(getCurrentChallenge);
        yield put({ type: "CURRENT_CHALLENGE_INFO", challenge: challenge, userId: userId });
        yield put({ type: "SUBSCRIBE_TO_LOBBY", id: challenge.id });
    }
    catch (e) {
        console.log("getActiveChallenge failed," + e)
        yield put({ type: "CURRENT_CHALLENGE_INFO_FAILED", payload: e });
    }
}

function* getFinishedChallengeWorker(action) {
    try {
        const userId = yield select(state => state.userReducer.user.id);
        const challengeId = action.id;
        const challenge = yield call(getChallenge, challengeId);
        yield put({ type: "CHALLENGE_INFO", challenge: challenge, userId: userId });
    }
    catch (e) {
        console.log("Failed getting finished challenge," + e)
        yield put(openErrorAlert({ title: e.message }));
    }
}

function* removeWithDelayChallengeWorker(action) {
    yield delay(2000);
    yield put({ type: "CHALLENGE_REMOVE", id: action.id });
}

function* challengeEventWorker(action) {     
    const id = action.challengeEvent.challengeId;
    const status = action.challengeEvent.status;
    const userId = yield select(state => state.userReducer.user.id);

    if (status === 'RUNNING') {
        yield put({ type: "SHOW_CHALLENGE_EVENT", event: status });
    }

    if (status === 'RUNNING' || status === 'DONE' || status === 'CANCELLED') {

        yield call(updateChallenge, {challengeId: id, userId: userId }); //will trigger reload of user to get any changes in amount. Will also trigger an update of challenge info
    }
}

function* streamerChallengeEventWorker(action) {
    const id = action.challengeEvent.challengeId;
    const status = action.challengeEvent.status;

    if (status === 'RUNNING' || status === 'DONE' || status === 'CANCELLED') {

        yield call(updateStreamerChallenge, { challengeId: id }); //Will trigger an update of challenge info
    }
}

function* challengeResultWorker(action) {
    const id = action.challengeResult.challengeId;
    const userId = yield select(state => state.userReducer.user.id);
    yield put({ type: "SHOW_CHALLENGE_EVENT", event: "RESULTS", extraData: action.challengeResult });

    yield call(updateChallenge, { challengeId: id, userId: userId }); //will trigger reload of user to get any changes in amount. Will also trigger an update of challenge info
}

function* streamerChallengeResultWorker(action) {
    const id = action.challengeResult.challengeId;
    yield call(updateStreamerChallenge, { challengeId: id }); //Will trigger an update of challenge info
}

function* challengeErrorWorker(action) {
    //Does not affect user as of yet so no need to refresh user bu we do it anyway :-)
    const id = action.error.challengeId;
    const userId = yield select(state => state.userReducer.user.id);
    //Show error as toast
   // toast.info(action.error.message);

    yield put(openErrorAlert({ title: action.error.message }));

    yield call(updateChallenge, { challengeId: id, userId: userId }); //will trigger reload of user to get any changes in amount. Will also trigger an update of challenge info
}

function* streamerChallengeErrorWorker(action) {
    //Does not affect user as of yet so no need to refresh user bu we do it anyway :-)
    const id = action.error.challengeId;

    yield put(openErrorAlert({ title: action.error.message }));

    yield call(updateStreamerChallenge, { challengeId: id }); // Will trigger an update of challenge info
}

function* challengeParticipantEventWorker(action) {
    yield put({ type: "CHALLENGE_PARTICIPANT_EVENT_FORWARDED", challengeParticipantEvent: action.challengeParticipantEvent}); 
}

function* streamerChallengeParticipantEventWorker(action) {
    yield put({ type: "CHALLENGE_PARTICIPANT_EVENT_FORWARDED", challengeParticipantEvent: action.challengeParticipantEvent});
}

function* challengeParticipantErrorWorker(action) {
    const id = action.error.challengeId;

    const currentUserId = yield select(state => state.userReducer.user.id);
    if (action.error.participantIds.findIndex(x=>x === currentUserId) !== -1) {
        //Show error as a toast
        toast.error(action.error.message);
    }
    yield put({ type: "CHALLENGE_PARTICIPANT_ERROR_FORWARDED", error: action.error, currentUserId: currentUserId }); //currentUserId does nothing
}

function* streamerChallengeParticipantErrorWorker(action) {

    yield put({ type: "CHALLENGE_PARTICIPANT_ERROR_FORWARDED", error: action.error});
}

function* requestSubscriptionToStreamerWorker(action) {
    const nick = action.nick;
    try {
        //const streamer = yield call(isStreamer, nick);
        const streamer = { "nick": nick, "isStreamer": true }; //We skip the sstreamer check an allow all to stream

        if (streamer.isStreamer) {
            yield put({ type: "FOLLOWING_STREAMER", streamer: streamer });
            yield put({ type: "SUBSCRIBE_TO_STREAMER_LOBBY", nick: nick });
        }
        else {
            yield put(openErrorAlert({ title: "User is not a streamer" }));
        }
    }
    catch (e) {
        console.log("requestSubscriptionToStreamerWorker failed," + e)
        yield put({ type: "FOLLOWING_STREAMER_FAILED", payload: e });
    }
}

function* showPopupWorker(action) {
    const { messageType, message, gradient, onClose } = action;

    yield put(openAlert({
        icon: messageType,
        title: message,
        background: gradient,
        confirmButtonColor: 'rgb(80, 227, 194)',
        confirmButtonText: 'Ok',
        onClose: onClose
    }));
}

function* leaderboardWorker(action) {
    try {
        const generalLeaderboard = yield call(getGeneralLeaderboard);
        yield put({ type: "GENERAL_LEADERBOARD_INFO", generalLeaderboard: generalLeaderboard });
    }
    catch (e) {
        console.log("Leaderboard failed," + e)
        yield put({ type: "LEADERBOARD_INFO_FAILED", payload: e });
    }
}

function* extracurricularWorker(action) {
    try {
        const extracurricular = yield call(getExtracurricular);
        yield put({ type: "EXTRACURRICULAR_INFO", extracurricular: extracurricular });
    }
    catch (e) {
        console.log("extracurricular failed," + e)
        yield put({ type: "EXTRACURRICULAR_INFO_FAILED", payload: e });
    }
}

function* personalPositionWorker(action) {
    try {
        const personalPosition = yield call(getPersonalPosition);
        yield put({ type: "PERSONAL_POSITION", personalPosition: personalPosition });
    }
    catch (e) {
        console.log("PersonalPosition failed," + e)
        yield put({ type: "PERSONAL_POSITION_FAILED", payload: e });
    }
}

function* statisticsWorker(action) {
    try {
        const generalStatistics = yield call(getGeneralStats);
        yield put({ type: "GENERAL_STATISTICS_INFO", generalStatistics: generalStatistics });
    }
    catch (e) {
        console.log("Leaderboard failed," + e)
        yield put({ type: "STATISTICS_INFO_FAILED", payload: e });
    }
}

function* getTransactionsWorker(action) {
    try {

        const transactions = yield call(getTransactions);
        yield put({ type: "TRANSACTION_INFO", transactions: transactions });
    }
    catch (e) {
        console.log("monthlyLeaderboard failed," + e)
        yield put({ type: "TRANSACTION_INFO_FAILED", payload: e });
    }
}

function* getCaruselWorker(action) {
    try {

        const streams = yield call(getTwitchStreams);
        yield put({ type: "TWITCH_STREAMS", streams: streams });
    }
    catch (e) {
        console.log("getTwitchStreams failed," + e)
        yield put({ type: "TWITCH_STREAMS_FAILED", payload: e });
    }
}

function* updateUserWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { firstName, lastName, address1, address2, zip, city, state, countryIso, phoneNumber, playerStatus, hcp, nationalGolfId, region } = action;
        // remove comment to enable actual submit to server
        const userInfo = yield call(registerUserStepTwo, firstName, lastName, address1, address2, zip, city, state, countryIso, phoneNumber, playerStatus, hcp, nationalGolfId, region);
        yield put({ type: "REGISTER_USER_STEP_TWO_SUCCESS", userInfo });
        yield put({ type: "FINISHED_WORKING" });

        localStorage.setItem('wgc_region', region);

        //Tell SignalR to fetch Challenges for selected region
        yield put({ type: "REGION_UPDATED", region });

        yield put(openSuccessAlert({ title: 'Information updated!' }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('update settings failed: ', e);
        yield put(openErrorAlert({title: e.message}));
        yield put({ type: "UPDATE_USER_FAILED", payload: e });
    }
}

function* depositStripeWorker(action) {
    try {
        const { amount, transactionFee } = action;
        const depositReply = yield call(depositStripe, amount, transactionFee);
        //console.log("Deposit url=" + depositReply.acceptUrl);
        yield put(push('deposit_wait', { redirectUrl: depositReply.acceptUrl, provider: 'Stripe' }))
        //window.location = depositReply.acceptUrl;
    }
    catch (e) {
        console.log('Deposit request failed: ', e);
        yield put(openErrorAlert({title: e.message }));
        yield put({ type: "DEPOSIT_REQUEST_FAILED", payload: e });
    }
}

function* depositPayPalWorker(action) {
    try {
        const { amount, transactionFee } = action;
        const depositReply = yield call(depositPayPal, amount, transactionFee);
        //console.log("Deposit url=" + depositReply.acceptUrl);
        yield put(push('deposit_wait', { redirectUrl: depositReply.acceptUrl, provider: 'PayPal' }))
        //window.location = depositReply.acceptUrl;
    }
    catch (e) {
        console.log('Deposit request failed: ', e);
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "DEPOSIT_REQUEST_FAILED", payload: e });
    }
}

function* verifyDepositWorker(action) {
    try {
        const { orderId, payerId } = action;
        const verifyReply = yield call(verifyDeposit,orderId,payerId);
        yield put({ type: "NEW_AMOUNT", newAmount: verifyReply.totalAmount });
        //yield put(push('/deposit_done', { amount: verifyReply.amount, totalAmount: verifyReply.totalAmount }))
        yield put(push('/'));
        yield put(openSuccessAlert({ title: translateDepositSuccededResult(verifyReply.amount, verifyReply.totalAmount) }));
    }
    catch (e) {
        console.log('Deposit verification failed: ', e);
        yield put(push('/'));
        yield put(openErrorAlert({title: e.message}));
        yield put({ type: "DEPOSIT_VERIFICATION_FAILED", payload: e });
    }
}

function* verifyStripeDepositWorker(action) {
    try {
        const { refId } = action;

        var found = false;
        var retries = 0;
        var verifyReply=undefined;
        while (!found && retries < 5) {
            verifyReply = yield call(verifyStripeDeposit, refId);
            if (verifyReply.completed) {
                found = true;

            }
            else {
                retries = retries + 1;
                yield delay(3000);
            }
        }

        if (found) {
            yield put({ type: "NEW_AMOUNT", newAmount: verifyReply.totalAmount });
            yield put(push('/'));
            yield put(openSuccessAlert({ title: translateDepositSuccededResult(verifyReply.amount, verifyReply.totalAmount) }));
        }
        else {
            yield put(push('/'));
            yield put(openErrorAlert({ title: "Deposit verification failed" }));
        }
    }
    catch (e) {
        console.log('Deposit verification failed: ', e);
        yield put(push('/'));
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "DEPOSIT_VERIFICATION_FAILED", payload: e });
    }
}


function* stripeDepositVerifiedWorker(action) {
    try {
        const { amount, totalAmount } = action;
        yield put({ type: "NEW_AMOUNT", newAmount: totalAmount });
        yield put(push('/'));
        yield put(openSuccessAlert({ title: translateDepositSuccededResult(amount, totalAmount) }));
    }
    catch (e) {
        console.log('Deposit verification failed: ', e);
        yield put(push('/'));
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "DEPOSIT_VERIFICATION_FAILED", payload: e });
    }
}

function* cancelStripeDepositWorker(action) {
    try {
        const { orderId } = action;
        const cancelReply = yield call(cancelStripeDeposit, orderId);
        yield put(push('/'))
    }
    catch (e) {
        console.log('Cancel deposit failed: ', e);
        yield put(openErrorAlert({title: e.message}));
        yield put({ type: "DEPOSIT_CANCELLATION_FAILED", payload: e });
    }
}

function* cancelPayPalDepositWorker(action) {
    try {
        const { orderId } = action;
        const cancelReply = yield call(cancelPayPalDeposit, orderId);
        yield put(push('/'))
    }
    catch (e) {
        console.log('Cancel deposit failed: ', e);
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "DEPOSIT_CANCELLATION_FAILED", payload: e });
    }
}


function* withdrawWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { amount, email } = action;
        const withdrawReply = yield call(withdraw, amount, email);
        yield put({ type: "NEW_AMOUNT", newAmount: withdrawReply.totalAmount })
        yield put({ type: "WITHDRAW_REQUEST_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        yield put(openSuccessAlert({ title: translateWithdrawRequestSuccededResult(withdrawReply.amount, withdrawReply.totalAmount) }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('Withdraw request failed: ', e);
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "WITHDRAW_REQUEST_FAILED", payload: e });
    }
}

function* redeemCodeWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { code } = action;
        const redeemCodeReply = yield call(redeemCode, code);
        yield put({ type: "NEW_AMOUNT", newAmount: redeemCodeReply.totalAmount })
        yield put({ type: "REDEEM_CODE_REQUEST_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        yield put(openSuccessAlert({ title: translateRedeemCodeSuccededResult(redeemCodeReply.amount, redeemCodeReply.totalAmount) }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('Redeem code request failed: ', e);
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "REDEEM_CODE_REQUEST_FAILED", payload: e });
    }
}

function* uploadProfilePictureWorker(action) {
    const { file } = action;
    console.log("File is " + file.size);
    try {
        const pictureInfo = yield call(uploadProfilePicture, file);
        yield put({ type: "PICTURE_INFO", pictureInfo });
    }
    catch (e) {
        console.log('Upload profile picture failed: ', e);
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "UPDATE_PROFILE_PICTURE_FAILED", payload: e });
    }
}

function login(username, password) {
    // console.log("Username=" + username);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(`${config.url.BASE_URL}/api/account/authenticate`, requestOptions)
        .then(handleResponse)
        .then(console.log('Logging in: ', requestOptions))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function registerUser(firstName, lastName, playerType, handicap, countryIso, phoneNumber, username, password, confirmPassword) {

    const playerStatus = playerType;
    const hcp =  handicap;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ firstName, lastName, playerStatus, hcp, countryIso, phoneNumber, username, password, confirmPassword })
    };
    return fetch(`${config.url.BASE_URL}/api/account/register`, requestOptions)
        .then(handleResponse)
        .then(console.log('registration sent: ', requestOptions))
        .then(user => {
            // register also log in user, store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function updateRegion(region) {

    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ region })
    };
    return fetch(`${config.url.BASE_URL}/api/users/region`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details in local storage
            localStorage.setItem('userInfo', JSON.stringify(user));
            return user;
        });
}

function registerUserStepTwo(firstName, lastName, address1, address2, zip, city, state, countryIso, phoneNumber, playerStatus, hcp, nationalGolfId, region) {


    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ firstName, lastName, address1, address2, zip, city, state, countryIso, phoneNumber, playerStatus, hcp, nationalGolfId, region })
    };
    return fetch(`${config.url.BASE_URL}/api/account/updatecontactinfo`, requestOptions)
    .then(handleResponse)
    .then(console.log('registration sent: ', requestOptions))
    .then(user => {
            // store user details in local storage
            localStorage.setItem('userInfo', JSON.stringify(user));
            return user;
    });
}

function depositPayPal(amount, transactionFee) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ amount, transactionFee })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/deposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit request sent: ', requestOptions));
}

function depositStripe(amount, transactionFee) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ amount, transactionFee })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/depositstripe`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit request sent: ', requestOptions));
}

function verifyDeposit(orderId,payerId) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ orderId, payerId })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/verifydeposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit verification request sent: ', requestOptions))
        .then(verificationReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['amount'] = verificationReply.totalAmount;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return verificationReply;
        });
}

function verifyStripeDeposit(clientReferenceId) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ clientReferenceId })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/verifystripedeposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit verification request sent: ', requestOptions))
        .then(verificationReply => {
            if (verificationReply.completed) {
                // store new amount in local storage
                var existing = localStorage.getItem('userInfo');
                existing = existing ? JSON.parse(existing) : {};
                existing['amount'] = verificationReply.totalAmount;
                // Save back to localStorage
                localStorage.setItem('userInfo', JSON.stringify(existing));
            }
            return verificationReply;
        });
}

function cancelStripeDeposit(refId) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ refId })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/cancelstripedeposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit cancellation request sent: ', requestOptions));
}

function cancelPayPalDeposit(orderId) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ orderId })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/canceldeposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit cancellation request sent: ', requestOptions));
}

function withdraw(amount, email) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ amount, email })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/withdraw`, requestOptions)
        .then(handleResponse)
        .then(console.log('Withdraw request sent: ', requestOptions))
        .then(withdrawReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['amount'] = withdrawReply.totalAmount;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return withdrawReply;
        });
}

function redeemCode(code) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ code })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/redeemcode`, requestOptions)
        .then(handleResponse)
        .then(console.log('redeem code request sent: ', requestOptions))
        .then(redeemCodeReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['amount'] = redeemCodeReply.totalAmount;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return redeemCodeReply;
        });
}

function epicVerification(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ email, password })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/verifyepicaccount`, requestOptions)
    .then(handleResponse)
    .then(console.log('verify epic account request sent: ', requestOptions))
    .then(verificationReply => {
        // store new amount in local storage
        var existing = localStorage.getItem('userInfo');
        existing = existing ? JSON.parse(existing) : {};
        existing['epicVerified'] = verificationReply.verified;
        existing['epicDisplayName'] = verificationReply.displayName;
        existing['epicAccountId'] = verificationReply.accountId;
        // Save back to localStorage
        localStorage.setItem('userInfo', JSON.stringify(existing));
        return verificationReply;
    });
}

function epicVerification2FA(code, cookies) {

    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ code: code, cookies: cookies  })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/verifyepicaccount2fa`, requestOptions)
        .then(handleResponse)
        .then(console.log('verify epic account 2FA request sent: ', requestOptions))
        .then(verificationReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['epicVerified'] = verificationReply.verified;
            existing['epicDisplayName'] = verificationReply.displayName;
            existing['epicAccountId'] = verificationReply.accountId;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return verificationReply;
        });
}

function epicVerificationCaptcha(code) {

    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ code: code})
    };
    return fetch(`${config.url.BASE_URL}/api/Account/verifyepicaccountcaptcha`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('verify epic account captcha request sent: ', requestOptions))
        .then(verificationReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['epicVerified'] = verificationReply.verified;
            existing['epicDisplayName'] = verificationReply.displayName;
            existing['epicAccountId'] = verificationReply.accountId;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return verificationReply;
        });
}

function verifyEmail(id, code) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        
    };
    return fetch(`${config.url.BASE_URL}/api/account/verifyemail?id=${id}&code=${code}`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('verify email sent: ', requestOptions))
        .then(verificationReply => {
             // store new amount in local storage
             var existing = localStorage.getItem('userInfo');
             existing = existing ? JSON.parse(existing) : {};
             existing['usernameVerified'] = true;
             // Save back to localStorage
             localStorage.setItem('userInfo', JSON.stringify(existing));
             return verificationReply;
        });
}

function resendEmailVerificationCode() {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/Account/resendcode`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('resent email verification code ', requestOptions))
        ;
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ email: email })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/forgotpassword`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('Forgot password sent: ', requestOptions))
       ;
}

function resetPassword(id,code,password) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ id: id, code: code, password: password })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/resetpassword`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('Reset password sent: ', requestOptions))
        ;
}


function errorCallout(error) {
    alert(error.message);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('userInfo');
}

function displayError(errorCode) {
    console.log('There was an error: ', errorCode)
}

function getUser() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/users/me`, requestOptions)
        .then(handleResponse)
        .then(userInfo => {
            localStorage.setItem('userInfo', JSON.stringify(userInfo));

            return userInfo;
        });
}

function getMatchesPlayed() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/matchesplayed`, requestOptions).then(handleResponse);
}


function signUp(id) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/signup`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(signupReply => {

            if (signupReply.feePaid) {
                // store new amount in local storage
                var existing = localStorage.getItem('userInfo');
                existing = existing ? JSON.parse(existing) : {};
                existing['amount'] = signupReply.totalAmount;
                // Save back to localStorage
                localStorage.setItem('userInfo', JSON.stringify(existing));
            }
            return signupReply;
        });
}

function tournamentSignUp(id) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/tournaments/${id}/signup`, requestOptions).then(handleResponse);
}

function leave(id) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/leave`, requestOptions)
        .catch(handleError)
        .then(handleResponse).then(leaveReply => {
            if (leaveReply.feeRefunded) {
                // store new amount in local storage
                var existing = localStorage.getItem('userInfo');
                existing = existing ? JSON.parse(existing) : {};
                existing['amount'] = leaveReply.totalAmount;
                // Save back to localStorage
                localStorage.setItem('userInfo', JSON.stringify(existing));
            }
            return leaveReply;
        });
}

function tournamentLeave(id) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/tournaments/${id}/leave`, requestOptions).then(handleResponse);
}


function reportScore(id, score) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ score: score })
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/result`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('Report score sent: ', requestOptions));
}

function getChallenge(id) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}`, requestOptions).then(handleResponse);
}

function getInvitedChallenges() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/invited`, requestOptions).then(handleResponse);
}

function getPrivateChallenge(id) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/private`, requestOptions).then(handleResponse);
}

/**
 * Not used
 * */
function getCurrentChallenge() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/current`, requestOptions).then(handleResponse);
}

function getMonthlyLeaderboard() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/leaderboards/monthly`, requestOptions).then(handleResponse);
}

function getWeeklyLeaderboard() {

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/leaderboards/weekly`, requestOptions).then(handleResponse);
}

function getGeneralLeaderboard() {

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/leaderboards/general`, requestOptions).then(handleResponse);
}

function getExtracurricular() {

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/extracurricular`, requestOptions).then(handleResponse);
}

function getPersonalPosition() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/leaderboards/personal`, requestOptions).then(handleResponse);
}

function getGeneralStats() {

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/statistics/general`, requestOptions).then(handleResponse);
}

function getTransactions() {

    const ascending = false;

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/transactions?ascending=${ascending}`, requestOptions).then(handleResponse);
}

function getTwitchStreams() {

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
    };
    return fetch(`${config.url.BASE_URL}/api/carusel/twitch/streams`, requestOptions).then(handleResponse);
}

function uploadProfilePicture(file) {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: Object.assign(authHeader()),
        body: formData
    };
    return fetch(`${config.url.BASE_URL}/api/uploads/me/profilepicture`, requestOptions)
        .then(handleResponse)
        .then(userInfo => {
            localStorage.setItem('userInfo', JSON.stringify(userInfo));

            return userInfo;
        });;
}

function isStreamer(nick) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ nick: nick })
    };
    return fetch(`${config.url.BASE_URL}/api/users/isstreamer`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        ;
}

function createPrivateChallenge(challenge) {

    const formData = new FormData();
    formData.append('banner', challenge.bannerImage);
    formData.append('logo', challenge.logoImage);
    formData.append('leaderboardPrize', challenge.leaderbordPrizeImage);

    formData.append('json', JSON.stringify(challenge));

    const requestOptions = {
        method: 'POST',
        headers: Object.assign(authHeader()),
        body: formData
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/private`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('Create private challenge: ', requestOptions));
}

function editPrivateChallenge(challengeId, challenge) {

    const formData = new FormData();
    formData.append('banner', challenge.bannerImage);
    formData.append('logo', challenge.logoImage);
    formData.append('leaderboardPrize', challenge.leaderbordPrizeImage);

    formData.append('json', JSON.stringify(challenge));

    const requestOptions = {
        method: 'PUT',
        headers: Object.assign(authHeader()),
        body: formData
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${challengeId}/private`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(console.log('Edit private challenge: ', requestOptions));
}

function deletePrivateChallenge(challengeId) {
    const requestOptions = {
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${challengeId}/private`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        ;
}


function handleResponse(response) {

    //console.log("Response=" + response);
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
                //location.reload isn't used when verifying credentials during login, it's for after the user is logged in if their authentication token becomes invalid for any reason 
                //which causes a 401 response to be returned by the api.If a 401 is returned logout() is called which removes the user from local storage, 
                //then location.reload(true) is called which refreshes the app to redirect the user back to the login page.
            }
            else if (response.status === 431) {
                //Will be returned or verification when user has enabled 2FA
                const data = text && JSON.parse(text);
                const error2FA = { errorCode: 431, cookies: data.cookies };
                return Promise.reject(error2FA);
            }
            else if (response.status === 412) {
                //Will be returned or verification when captcha is required
                const errorCaptcha = { errorCode: 412 };
                return Promise.reject(errorCaptcha);
            }
            else if (response.status === 503) {
                //Service unavailable, will be returned in case of maintenance
                const error = { errorCode: 503, message: 'Service is undergoing maintenance' };
                return Promise.reject(error);
            }

            const data = text && JSON.parse(text);
            if (typeof data !== 'undefined' && typeof data.message !== 'undefined') {
                return Promise.reject(data);
            }
            else if (typeof data !== 'undefined' && typeof data.title !== 'undefined') {
                const error = { errorCode: -1, message: typeof data.detail !== 'undefined' ? data.detail: data.title};
                return Promise.reject(error);
            }
            else {
                const error = { errorCode: -1, message: response.statusText };
                return Promise.reject(error);
            }
        }
        const data = text && JSON.parse(text);
        //console.log("data=" + JSON.stringify(data));
        //console.log("statusText=" + response.statusText);
        return data;
    });
}

function handleError(error) {
    if (error.message === 'Failed to fetch') {
        const msg = { errorCode: -1, message: 'Unable to connect to backend, please check you internet connection' };
        throw msg;
    }

    throw error;

}
